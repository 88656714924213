var UnitCounter = (function () {

    'use strict';

    // Prototype object aka magic constructor - this will get exposed
    UnitCounter = function (elm, val, nxt, end) {

        this.elm = get(elm);
        this.val = nbr(val);
        this.nxt = nbr(nxt);

        this.end = end || false;

        this.is_updating = false;

        if (this.elm) {

            init.call(this);
        }
    };

    // Public function to set a new value
    UnitCounter.prototype.setVal = function (val) {

        this.nxt = nbr(val);

        this.elm.forEach(updateValInit.bind(this));

        return this.nxt;
    };

    // Private Init function - Casts the magic on every selected elements if any
    function init() {

        if (this.elm instanceof HTMLCollection) {

            this.elm = Array.prototype.slice.call(this.elm);
        } else {

            this.elm = [this.elm];
        }

        return this.elm.forEach(updateValInit.bind(this));
    }

    // Private function to inititate the change of value of a given object
    function updateValInit(tgt) {

        if (this.onBeforeUpdate !== undefined) {

            this.onBeforeUpdate.call(this);
        }

        if (!this.is_updating && this.val !== this.nxt) {

            this.is_updating = true;

            window.requestAnimationFrame(updateVal.bind(this, tgt));
        } else {

            this.elm.forEach(render.bind(this, tgt));
        }
    }

    // Main animation loop
    function updateVal(tgt) {

        var dir, pos;

        if (this.is_updating) {

            window.requestAnimationFrame(updateVal.bind(this, tgt));
        }

        if (this.val !== this.nxt) {

            dir = (this.val < this.nxt) ? 1 : -1;

            pos = this.val + ((this.nxt - this.val) * 0.08);
            pos = (dir > 0) ? Math.ceil(pos) : Math.floor(pos);

            this.val = pos;

            render.call(this, tgt);

            if (pos === this.nxt) {

                this.is_updating = false;

                if (this.onAfterUpdate !== undefined) {

                    this.onAfterUpdate.call(this);
                }
            }
        }
    }

    // Renders new value to target element
    function render(tgt) {

        if (this.end) {

            tgt.textContent = this.end(this.val);
        } else {

            tgt.textContent = formatNbr(this.val);
        }
    }

    // Helper private function to get an element or elements collection
    function get(elm) {

        var output = false;

        if (elm.constructor === String) {

            if (!elm.startsWith('.')) {

                if (elm.startsWith('#')) {

                    elm = elm.substr(1);
                }

                elm = document.getElementById(elm);
            } else {

                elm = document.getElementsByClassName(elm);
            }
        }

        if (elm instanceof HTMLElement || elm instanceof HTMLCollection) {

            output = elm;
        }

        return output;
    }

    // Helper private function to make sure a parameter is a number
    function nbr(val) {

        return isNaN(val = parseFloat(val)) ? 0 : val;
    }

    // legacy helper private function to format a number
    // Default format is as follows: no decimals (round), space separrated thousands
    function formatNbr(n, c, d, t) {

        var s, i, j;

        c = isNaN(c = Math.abs(c)) ? 0 : c;
        d = d == undefined ? "," : d;
        t = t == undefined ? " " : t;

        s = n < 0 ? "-" : "";
        i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "";
        j = (j = i.length) > 3 ? j % 3 : 0;

        return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
    }

    // Expose prototype
    return UnitCounter;
}());
