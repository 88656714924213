var tab_k = new Array();
var divP;
var input;
var formulaire;
var selected_k;
var requeteur_k;
var k_choisi="";
var path_relative_root_search = '';
var is_magasin = '';

function onWinEvent() {

	if (is_magasin == true) {

		initialize();
	}

	initAutoComplete();
}

window.addEventListener('load', onWinEvent);
window.addEventListener('resize', setCompleteDivSize);

function initAutoComplete(){

	formulaire=document.getElementById("form_recherche");//Id du formulaire
	if ( document.getElementById("search_home") ) {
		input=document.getElementById("search_home");//Id du champ input text
		input.onkeyup=EventListe;

		initStyle();
		input.onkeydown=onResizeHandler;
		divP=document.createElement("DIV");
		divP.id="completeDiv";
		var borderLeftRight=2;
		var borderTopBottom=2;
		var borderColor = "c0cc1c";
		divP.style.borderRight="#"+borderColor+" "+borderLeftRight+"px solid";
		divP.style.borderLeft="#"+borderColor+" "+borderLeftRight+"px solid";
		divP.style.borderTop="#"+borderColor+" "+borderTopBottom+"px solid";
		divP.style.borderBottom="#"+borderColor+" "+borderTopBottom+"px solid";
		divP.style.zIndex="1000";
		divP.style.paddingRight="0px";
		divP.style.paddingLeft="0px";
		divP.style.paddingTop="0px";
		divP.style.paddingBottom="0px";
		setCompleteDivSize();
		divP.style.visibility="hidden";
		divP.style.position="absolute";
		divP.style.backgroundColor="white";
		document.body.appendChild(divP);
		setStylePourElement(divP,"AutoCompleteDivListeStyle");
	}
	document.onclick=eventClick;
}
function getXMLHTTP(){
  var xhr=null;
  if(window.XMLHttpRequest) // Firefox et autres
  xhr = new XMLHttpRequest();
  else if(window.ActiveXObject){ // Internet Explorer
    try {
      xhr = new ActiveXObject("Msxml2.XMLHTTP");
    } catch (e) {
      try {
        xhr = new ActiveXObject("Microsoft.XMLHTTP");
      } catch (e1) {
        xhr = null;
      }
    }
  }
  else { // XMLHttpRequest non supporte par le navigateur
    alert("Votre navigateur ne supporte pas les objets XMLHTTPRequest...");
  }
  return xhr;
}
var _xmlHttp;
function callSuggestions(valeur){
k_choisi = valeur;

if(ltrim(valeur)!=""&&ltrim(valeur).length>=3){
		if(_xmlHttp){
			if (_xmlHttp.readyState!=0) {
				_xmlHttp.abort();
			}
		}
		_xmlHttp=getXMLHTTP();

	  load_liste_result();
	  if(_xmlHttp){
		 var path_relative_root = document.getElementById('path').value;
		_xmlHttp.open("GET",path_relative_root + "ajax_autocomplete.php?debut=" + valeur);
		_xmlHttp.onreadystatechange=function() {

			if(_xmlHttp.readyState==4&&_xmlHttp.responseText) {
				MAJ_liste_result_requeteur_k(_xmlHttp.responseText);
			}
	  };
	  _xmlHttp.send(null);
	}else{
		close_liste_result();
	}
	}else{
		close_liste_result();
	}
}

function strBetween(txt,bloc){
    var lg=bloc.length + 2;
    var rep=txt.substring(txt.indexOf("<"+bloc+">")+lg,txt.indexOf("</"+bloc+">"));
    return rep;
}
function MAJ_liste_result_requeteur_k(doc){

	var tab = new Array();
	var txt = "";

	options=strBetween(doc,"options");
	if(options!=""){
		var tabOptions=options.split("<option>");
		var trans_result = translate('resultat');
		for(var i=0;i<tabOptions.length-1;i++){
			tab[i] = new Array();
			var leS= "";
			tab[i][0] = strBetween(tabOptions[i+1],"libel");
			tab[i][1]= strBetween(tabOptions[i+1],"counter");

			if(tab[i][1]>1) leS="s"; else leS="";
			txt = txt + "<div id='k_" + i + "'  onmouseover='selection_liste(" + i + ")' onclick='selection_result_requeteur(\"" + tab[i][0] + "\")' class='AutoCompleteDiv'><span style='float:left;'>" + tab[i][0] + "</span><span style='float:right;color:#000;font-size:10px;'>" + tab[i][1] + " " + trans_result + leS + "</span></div>";
		}
		tab_k = new Array();
		tab_k = tab;
		open_liste_result(txt);
	}
	else close_liste_result();

}

function selection_liste(index){

	if(index >= 0 && index < tab_k.length){
		if(selected_k != null){
			document.getElementById('k_' + selected_k).style.backgroundColor="#ffffff";
		}
		document.getElementById('k_' + index).style.backgroundColor="#B0C4DE";
		k_choisi=tab_k[index][0];
	}else{
		if(selected_k != null){
			document.getElementById('k_' + index).style.backgroundColor="#ffffff";
		}
	}
	selected_k = index;

}

function selection_result_requeteur(value){
	annule_requete_k();
	value = value.replace(/\<strong\>/, '', value);
	value = value.replace(/\<\/strong\>/, '', value);
	input.value=value;
	close_liste_result();
	formulaire.submit();
}
function close_liste_result(){
	tab_k=new Array();
	divP.innerHTML="";
	divP.style.visibility="hidden";
	selected_k = null;
	annule_requete_k();
}
function load_liste_result(){
	var path_relative_root_search = document.getElementById('path').value;
	divP.innerHTML='<center><img src="'+path_relative_root_search+'img/sablier.gif" /></center>';
	divP.style.visibility="visible";
}
function open_liste_result(txt){
	selected_k = null;
	//txt+= "<div style='float:right;padding-right:3px;'><a href='javascript:close_liste_result()' style='font-size:10px'>Fermer</a></div>";
	divP.innerHTML=txt;
	divP.style.visibility="visible";
	selection_liste(0);
}
function EventListe(e){

	var ev = (window.event) ? event : e;
	var code= ev.keyCode;
		switch(code){
			case 38://UP
			case 40://DOWN
				if(divP.style.visibility == "visible"){
					annuler_event(ev);
					var sIndex = selected_k;
					if(code == 38){
						if(sIndex > 0)
							selection_liste(sIndex - 1);
					}else{
						// BUG OPERA
						if(sIndex < tab_k.length -1)
							selection_liste(sIndex + 1);
					}
				}
			break;
			case 27://ESC
				if(divP.style.visibility == "visible"){
					annuler_event(ev);
					close_liste_result();
					k_choisi=input.value;
				}
			break;

			case 37://LEFT
			case 39://RIGHT
			case 16://SHIFT
			case 17://CTRL
			case 18://ALT
			break;
			default:
				if (input.value.length >= 3) callSuggestions(input.value);
				break;
		}

}

function isEmptyKeywordSearch() {

	var	input = $('#search_home'),
		placeholder = $(input).data('placeholder');

	value = (input.val()).trim();

	if (k_choisi != "") {

		annule_requete_k();
		selection_result_requeteur(k_choisi);

		return false;
	}

	if (value === '' || value === placeholder) {

		alert( ajax_file( path_relative_root + 'ajax_sprintf.php?arg1=search_empty' ) );
		return false;
	}

}

function eventClick(e){

	if ( divP != null )  {
		if(divP.style.visibility=="visible"){

			//cherche l'element
			var targ;
			if (!e) var e = window.event;
			if (e.target) targ = e.target;
			else if (e.srcElement) targ = e.srcElement;
			if (targ.nodeType == 3) // defeat Safari bug
			targ = targ.parentNode;
			while(targ && targ!=divP){
				targ=targ.offsetParent;
			}
			if(!targ){
				close_liste_result();
			}
		}
	}
}
function annuler_event(e){
    if(!e) e=event;
    e.cancelBubble = true;
    if (e.stopPropagation) e.stopPropagation();
    if (e.preventDefault) e.preventDefault();
    e.returnValue = false;
}



function initStyle(){
  var AutoCompleteDivListeStyle="font-size: 13px; font-family: arial,sans-serif; word-wrap:break-word; ";
  var AutoCompleteDivStyle="display: block; padding: 0 2px; height: 20px; line-height: 20px; overflow: hidden; background-color: white;cursor:pointer";

  insereCSS(".AutoCompleteDivListeStyle",AutoCompleteDivListeStyle);
  insereCSS(".AutoCompleteDiv",AutoCompleteDivStyle);
}



function insereCSS(nom, regle) {
  if (document.styleSheets) {
    var I = (function () {
    	var sheet;
    	for (sheet in document.styleSheets) {
    		sheet = document.styleSheets[sheet];
	    	if (sheet.href !== null && sheet.href.indexOf(window.location.hostname) !== -1) {
	    		return sheet;
	    	}
	    }
	}());
    if (I.addRule) {
		// methode IE
		I.addRule(nom, regle);
    } else if (I.insertRule) {
		// methode DOM
		I.insertRule(nom + ' { ' + regle + ' }', I.cssRules.length);
    }
  }
}
// calcule le decalage a gauche
function calculateOffsetLeft(r){
  return calculateOffset(r,"offsetLeft");
}

// calcule le decalage vertical
function calculateOffsetTop(r){
  return calculateOffset(r,"offsetTop")+2;
}

function calculateOffset(r,attr){
  var kb=0;
  while(r){
    kb+=r[attr];
    r=r.offsetParent;
  }
  return kb;
}

// calcule la largeur du champ
function calculateWidth(){
  //return formulaire.offsetWidth-2;

  //return 330;
  return input.offsetWidth;
}
function setCompleteDivSize(){
  if(divP){
    divP.style.left=calculateOffsetLeft(input)+"px";
    divP.style.top=calculateOffsetTop(input)+input.offsetHeight-1+"px";
    divP.style.width=calculateWidth() +"px";
  }
}
function setStylePourElement(c,name){
  c.className=name;
}
function annule_requete_k(){
	if(_xmlHttp){
			if (_xmlHttp.readyState!=0) {
				_xmlHttp.abort();
			}
		}
}
var onResizeHandler=function(event){
  // recalcule la taille des suggestions
  setCompleteDivSize();
};
function in_array(tab, val){
	var i=0;
	while(tab[i]!=val){
		i++;
	}
	if(tab[i]==val)
		return true;
	else
		return false;
}
function ltrim(aString) {
	var regExpBeginning = /^\s+/;
    return aString.replace(regExpBeginning, "");
}
