// <!-- <![CDATA[

// Project: Dynamic Date Selector (DtTvB) - 2006-03-16
// Script featured on JavaScript Kit- http://www.javascriptkit.com
// Code begin...
// Set the initial date.
var ds_i_date = new Date();
ds_c_month = ds_i_date.getMonth() + 1;
ds_c_year = ds_i_date.getFullYear();

var not_before = new Array();
// Get Element By Id
function ds_getel(id) {
	return document.getElementById(id);
}

// Get the left and the top of the element.
function ds_getleft(el) {
	var tmp = el.offsetLeft;
	el = el.offsetParent
	while(el) {
		tmp += el.offsetLeft;
		el = el.offsetParent;
	}
	return tmp;
}
function ds_gettop(el) {
	var tmp = el.offsetTop;
	el = el.offsetParent
	while(el) {
		tmp += el.offsetTop;
		el = el.offsetParent;
	}
	return tmp;
}

// Output Element
var ds_oe = ds_getel('ds_calclass');
// Container
var ds_ce = ds_getel('ds_conclass');

var is_chosen_month = false;

// Output Buffering
var ds_ob = ''; 
function ds_ob_clean() {
	ds_ob = '';
}
function ds_ob_flush() {
	ds_oe.innerHTML = ds_ob;
	ds_ob_clean();
}
function ds_echo(t) {
	ds_ob += t;
}

var ds_element; // Text Element...

var ds_monthnames = [
/*ajax_file('ajax_sprintf.php?arg1=month_1'), ajax_file('ajax_sprintf.php?arg1=month_2'), ajax_file('ajax_sprintf.php?arg1=month_3'), ajax_file('ajax_sprintf.php?arg1=month_4'), ajax_file('ajax_sprintf.php?arg1=month_5'), ajax_file('ajax_sprintf.php?arg1=month_6'),
ajax_file('ajax_sprintf.php?arg1=month_7'), ajax_file('ajax_sprintf.php?arg1=month_8'), ajax_file('ajax_sprintf.php?arg1=month_9'), ajax_file('ajax_sprintf.php?arg1=month_10'), ajax_file('ajax_sprintf.php?arg1=month_11'), ajax_file('ajax_sprintf.php?arg1=month_12')*/
'JANVIER', 'FEVRIER', 'MARS', 'AVRIL', 'MAI', 'JUIN', 'JUILLET', 'AOUT', 'SEPTEMBRE', 'OCTOBRE', 'NOVEMBRE', 'DECEMBRE'
]; // You can translate it for your language.

var ds_daynames = [
'Dim', 'Lun', 'Mar', 'Me', 'Jeu', 'Ven', 'Sam'
]; // You can translate it for your language.


// Calendar template
function ds_template_main_above(t) {
	return '<div style="z-index:9500">'
		 + '<table cellpadding="3" cellspacing="0" class="ds_tbl">'
	     + '<tr>'
		 + '<td height="20" width="268" colspan="7" align="right" class="ds_subhead2" style="cursor: pointer;padding-right:8px;text-align:right;" onclick="ds_hi();">' + ajax_file('ajax_sprintf.php?arg1=js_calendar_close') + '</td>'
		 + '</tr>'
		 + '<tr height="20">'
		 + '<td class="ds_head" style="cursor: pointer" onclick="ds_pm();"><b>&lt;</b></td>'
		 + '<td colspan="5" class="ds_head"><b>' + t + '</b></td>'
		 + '<td class="ds_head" style="cursor: pointer" onclick="ds_nm();"><b>&gt;</b></td>'
		 + '</tr>'
		 + '<tr height="20">';
}

function ds_template_day_row(t) {
	return '<td class="ds_subhead">' + t + '.</td>';
	// Define width in CSS, XHTML 1.0 Strict doesn't have width property for it.
}

function ds_template_new_week() {
	return '</tr><tr height="20">';
}

function ds_template_blank_cell(colspan) {
	return '<td colspan="' + colspan + '"></td>'
}

function ds_template_day(d, m, y, n) {
	var dateJour = new Date();
	var t_jour = dateJour.getDate();
	var t_mois = dateJour.getMonth();
	var t_annee = dateJour.getFullYear();
	
	var timestamp_limit = Date.UTC( not_before[2], not_before[1], not_before[0] );
	var timestamp_given = Date.UTC( y, m, d );
	
	if(d == t_jour && m == t_mois && y == t_annee){
		
		if (n == 0 || n == 6 || timestamp_given < timestamp_limit || ((d == 2 || d == 3) && m ==6)) {
			return '<td class="ds_cell_inactive2">' + d + '</td>';
		} else {
			return '<td class="ds_cell2" onclick="ds_onclick(' + d + ',' + m + ',' + y + ')">' + d + '</td>';
		}
	}
	else {
		if (n == 0 || n == 6 || timestamp_given < timestamp_limit || ((d == 2 || d == 3) && m ==6)) {
			return '<td class="ds_cell_inactive">' + d + '</td>';
		} else {
			return '<td class="ds_cell" onclick="ds_onclick(' + d + ',' + m + ',' + y + ')">' + d + '</td>';
		}
	}
	// Define width the day row.
}

function ds_template_main_below() {
	return '</tr>'
	     + '</table>'
		 + '</div>';
}

// This one draws calendar...
function ds_draw_calendar(m, y) {
	
	// First clean the output buffer.
	ds_ob_clean();
	// Here we go, do the header
	ds_echo (ds_template_main_above(ds_monthnames[m - 1] + ' ' + y));
	for (i = 0; i < 7; i ++) {
		ds_echo (ds_template_day_row(ds_daynames[i]));
	}
	// Make a date object.
	var ds_dc_date = new Date();
	ds_dc_date.setMonth(m - 1);
	ds_dc_date.setFullYear(y);
	ds_dc_date.setDate(1);
	if (m == 1 || m == 3 || m == 5 || m == 7 || m == 8 || m == 10 || m == 12) {
		days = 31;
	} else if (m == 4 || m == 6 || m == 9 || m == 11) {
		days = 30;
	} else {
		days = (y % 4 == 0) ? 29 : 28;
	}
	var first_day = ds_dc_date.getDay();
	
	var first_loop = 1;
	// Start the first week
	ds_echo (ds_template_new_week());
	// If sunday is not the first day of the month, make a blank cell...
	if (first_day != 0) {
		ds_echo (ds_template_blank_cell(first_day));
	}
	var j = first_day;
	for (i = 0; i < days; i ++) {
		// Today is sunday, make a new week.
		// If this sunday is the first day of the month,
		// we've made a new row for you already.
		if (j == 0 && !first_loop) {
			j = 0;
			// New week!!
			ds_echo (ds_template_new_week());
		}
		// Make a row of that day!
		ds_echo (ds_template_day(i + 1, m, y, j));
		// This is not first loop anymore...
		first_loop = 0;
		// What is the next day?
		j ++;
		j %= 7;
	}
	// Do the footer
	ds_echo (ds_template_main_below());
	// And let's display..
	ds_ob_flush();
	// Scroll it into view.
	//ds_ce.scrollIntoView();
}

//Let's memorize the chosen arrival day, month and year, as well as the type of the field (departure, arrival)
var arrival_day = 0;
var arrival_month = 0;
var arrival_year = 0;
var called_ds_type = '';

var i_days_first = 0;
var ds_c_month_first = 0;

// A function to show the calendar.
// When user click on the date, it will set the content of t.
// type is "a" for arrival or "d" for departure
function ds_sh(t, type) {
	// Set the element to set...
	ds_element = t;
	// Make a new date, and set the current month and year.
	
	called_ds_type = type;
	
	if (arrival_month == 0 || arrival_year == 0) {
		var ds_sh_date = new Date();
		ds_c_month = ds_i_date.getMonth() + 1;
		ds_c_year = ds_i_date.getFullYear();
	} else {
		ds_c_month = arrival_month;
		ds_c_year = arrival_year;
	}
	
	var m = ds_i_date.getMonth() + 1;
	var y = ds_i_date.getFullYear();
	
	if (m == 1 || m == 3 || m == 5 || m == 7 || m == 8 || m == 10 || m == 12) {
		days = 31;
	} else if (m == 4 || m == 6 || m == 9 || m == 11) {
		days = 30;
	} else {
		days = (y % 4 == 0) ? 29 : 28;
	}
	
	var feb = 28;
	if (y % 4 == 0) {
		
		feb = 29;
		
	}
	
	var array_days = new Array(0, 31, feb, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 );
	
	
	var current_day_sh = ds_i_date.getDay();
	var raw_diff_day_sh = (days-current_day_sh);
	
	var day_limit = 3;
	var day_cnt = 0;
	var temp_m = m;
	
	var temp_date = ds_i_date;
	var i = temp_date.getDay();
	var i_days = temp_date.getDate();
	
	var is_okay = false;
	
	if (i_days_first != 0) {
		
		var i_days_temp = i_days_first;
		var ds_c_month_temp = ds_c_month_first;
		
		while (is_okay == false) {
			
			if (i_days_temp > array_days[ds_c_month_temp]) {
				
				ds_c_month_temp = (ds_c_month_temp+1);
				if (ds_c_month_temp == 13) {
					ds_c_month_temp == 1;
					
				}
				i_days_temp = 1;
							
			}
			
			if (day_cnt == (day_limit+1)) {
				
				is_okay = true;
								
				is_chosen_month = true;
				
			}
			
			if ((i != 0 || i != 6) && (i ==1 || i ==2 || i ==3 || i ==4 || i ==5)) {
				
				day_cnt++;
				
			}
			
			i++;
			
			if (i == 7) {
				
				i = 0;
				
			}
			
			i_days_temp++;
			
			if (i_days_temp > array_days[ds_c_month_temp] && is_okay) {
				
				ds_c_month_temp = (ds_c_month_temp+1);
				
				if (ds_c_month_temp == 13) {
					
					ds_c_month_temp == 1;
					
				}
				
			}
				
			
		}
		
	} else if (not_before.length == 0) {
		
		while (is_okay == false) {
			
			temp_date.setDate(i_days);
			if (i_days > array_days[temp_m]) {
				
				temp_m = (temp_m+1);
				if (temp_m == 13) {
					temp_m == 1;
					temp_date.setFullYear(temp_date.getFullYear+1);
				}
				temp_date.setMonth(temp_m - 1);
				i_days = 1;
							
			}
			
			if (day_cnt == (day_limit+2)) {
				
				is_okay = true;
				
				ds_c_month = temp_m;
				ds_c_month_first = ds_c_month;
								
				is_chosen_month = true;
				
			}
			
			if ((i != 0 || i != 6) && (i ==1 || i ==2 || i ==3 || i ==4 || i ==5)) {
				
				day_cnt++;
				
			}
			
			i++;
			
			if (i == 7) {
				
				i = 0;
				
			}
			
			i_days++;
			
			if (i_days > array_days[temp_m] && is_okay) {
				
				ds_c_month = (ds_c_month+1);
				
				if (ds_c_month == 13) {
					
					ds_c_month == 1;
					
				}
				
				i_days_first = i_days;
				ds_c_month_first = ds_c_month;
				
			}
			
		}
		not_before[0] = temp_date.getDate();
		not_before[1] = (temp_date.getMonth() + 1);
		not_before[2] = temp_date.getFullYear();
	} 
	
	var ok_month = 0;
	
	/*if (type == 'd') {
		if (arrival_day >= 28) {
			ds_c_month = arrival_month + 1;
			if (arrival_month == 12) {
				ds_c_month = 1;
				ds_c_year += 1;
			}
		}
	} */
	
	//ds_c_month = ok_month;
	
	// Draw the calendar
	ds_draw_calendar(ds_c_month_first, ds_c_year);
	// To change the position properly, we must show it first.
	ds_ce.style.display = '';
	// Move the calendar container!
	the_left = ds_getleft(t)-327;
	the_top = (ds_gettop(t)-273) + t.offsetHeight;
	ds_ce.style.left = the_left + 'px';
	ds_ce.style.top = the_top + 'px';
	// Scroll it into view.
	//ds_ce.scrollIntoView();
}

// Hide the calendar.
function ds_hi() {
	ds_ce.style.display = 'none';
}

// Moves to the next month...
function ds_nm() {
	// Increase the current month.
	ds_c_month ++;
	// We have passed December, let's go to the next year.
	// Increase the current year, and set the current month to January.
	if (ds_c_month > 12) {
		ds_c_month = 1; 
		ds_c_year++;
	}
	// Redraw the calendar.
	ds_draw_calendar(ds_c_month, ds_c_year);
}

// Moves to the previous month...
function ds_pm() {
	ds_c_month = ds_c_month - 1; // Can't use dash-dash here, it will make the page invalid.
	// We have passed January, let's go back to the previous year.
	// Decrease the current year, and set the current month to December.
	if (ds_c_month < 1) {
		ds_c_month = 12; 
		ds_c_year = ds_c_year - 1; // Can't use dash-dash here, it will make the page invalid.
	}
	// Redraw the calendar.
	ds_draw_calendar(ds_c_month, ds_c_year);
}

// Moves to the next year...
function ds_ny() {
	// Increase the current year.
	ds_c_year++;
	// Redraw the calendar.
	ds_draw_calendar(ds_c_month, ds_c_year);
}

// Moves to the previous year...
function ds_py() {
	// Decrease the current year.
	ds_c_year = ds_c_year - 1; // Can't use dash-dash here, it will make the page invalid.
	// Redraw the calendar.
	ds_draw_calendar(ds_c_month, ds_c_year);
}

// Format the date to output.
function ds_format_date(d, m, y) {
	// 2 digits month.
	m2 = '00' + m;
	m2 = m2.substr(m2.length - 2);
	// 2 digits day.
	d2 = '00' + d;
	d2 = d2.substr(d2.length - 2);
	// YYYY-MM-DD
//	return y + '-' + m2 + '-' + d2;
	return d2 + '/' + m2 + '/' + y;
}

// When the user clicks the day.
function ds_onclick(d, m, y) {
	var timestamp_limit = Date.UTC( not_before[2], not_before[1], not_before[0] );
	var chosen_timestamp =  Date.UTC( y, m, d );
	
	if (chosen_timestamp < timestamp_limit) {
		
		alert(ajax_file('ajax_sprintf.php?arg1=js_calendar_1&arg2=' + not_before[0], not_before[1], not_before[2]) );
		
	} else {
		
		if (called_ds_type == 'a') {
			
			
			var content_field_deb = document.getElementById('date_deb').value;
			
			if (content_field_deb != '') {
					
				var array_date = content_field_deb.split('/');
				
				if (array_date.length == 3) {
					var deb_timestamp = Date.UTC( array_date[2], array_date[1], array_date[0] );
					
					if ( deb_timestamp > chosen_timestamp ) {
						show_lightbox('lightbox_livraison');
						document.getElementById('erreur2').style.display='block';
						document.getElementById('erreur1').style.display=document.getElementById('erreur3').style.display=document.getElementById('erreur4').style.display=document.getElementById('erreur5').style.display=document.getElementById('erreur6').style.display=document.getElementById('erreur7').style.display=document.getElementById('erreur8').style.display=document.getElementById('erreur9').style.display='none';
						return;
					} else {
						arrival_day = d;
						arrival_month = m;
						arrival_year = y;
					}
				} else {
					arrival_day = d;
					arrival_month = m;
					arrival_year = y;
				}
			} else {
				arrival_day = d;
				arrival_month = m;
				arrival_year = y;
			}
		} else {
			var content_field_fin = document.getElementById('date_fin').value;
			
			if (content_field_fin != '') {
					
				var array_date = content_field_fin.split('/');
				
				if (array_date.length == 3) {
					var fin_timestamp = Date.UTC( array_date[2], array_date[1], array_date[0] );
					
					if ( fin_timestamp < chosen_timestamp ) {
						show_lightbox('lightbox_livraison');
						document.getElementById('erreur2').style.display='block';
						document.getElementById('erreur1').style.display=document.getElementById('erreur3').style.display=document.getElementById('erreur4').style.display=document.getElementById('erreur5').style.display=document.getElementById('erreur6').style.display=document.getElementById('erreur7').style.display=document.getElementById('erreur8').style.display=document.getElementById('erreur9').style.display='none';
						return;
					}
					
				}
				
			}
		}
		
		// Hide the calendar.
		ds_hi();
		// Set the value of it, if we can.
		if (typeof(ds_element.value) != 'undefined') {
			
			ds_element.value = ds_format_date(d, m, y);
		// Maybe we want to set the HTML in it.
		} else if (typeof(ds_element.innerHTML) != 'undefined') {
			ds_element.innerHTML = ds_format_date(d, m, y);
		// I don't know how should we display it, just alert it to user.
		} else {
			
		}
		
	}
}

// And here is the end.

// ]]> -->