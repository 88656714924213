$(function () {
    var header   = $('.headerWrapper'),
        limit    = 1;
    $(window).on('scroll', function (){
        var scrollTop = $(document).scrollTop();
        if (header.hasClass('sticky')) {
            if (scrollTop <= limit) {
                header.removeClass('sticky');
            }
        } else {
            if (scrollTop >= limit) {
                header.addClass('sticky');
            }
        }
    });

    /* SLIDER HOME PAGE */
    var swiperHome = new Swiper('.mainSliderHomeImg', {
        nextButton          : '.swiper-button-next',
        prevButton          : '.swiper-button-prev',
        preloadImages       : false,
        lazyLoading         : true,
        autoHeight          : true,
        loop                : true
    });

    var swiperHomeTxt = new Swiper('.mainSliderHomeText', {
        effect              : 'fade',
        noSwiping           : true,
        loop                : true,
        nextButton          : '.arrowMainSlider',
        prevButton          : '.arrowMainSlider-prev'
    });

    var swiperHomeTxtBis = new Swiper('.mainSliderHomeTextBis', {
        effect              : 'cube',
        loop                : true,
        noSwiping           : true,
        cube: {
            shadow          : false,
            slideShadows    : false
        }
    });

    swiperHome.params.control           = swiperHomeTxt;
    swiperHomeTxt.params.control        = swiperHomeTxtBis;
    swiperHomeTxtBis.params.control     = swiperHomeTxt;

    /* Home reservation date picker */
    $.datepicker.regional['fr'] = {
        closeText: 'Fermer',
        prevText: '',
        nextText: '',
        currentText: 'Current',
        monthNames: ['Janvier','Février','Mars','Avril','Mai','Juin',
        'Juillet','Août','Septembre','Octobre','Novembre','Décembre'],
        monthNamesShort: ['Jan','Fév','Mar','Avr','Mai','Jun',
        'Jul','Aoû','Sep','Oct','Nov','Déc'],
        dayNames: ['Dimanche','Lundi','Mardi','Mercredi','Jeudi','Vendredi','Samedi'],
        dayNamesShort: ['Dim','Lun','Mar','Mer','Jeu','Ven','Sam'],
        dayNamesMin: ['dim.','lun.','mar.','mer.','jeu.','ven','sam.'],
        weekHeader: 'Sm',
        dateFormat: 'dd/mm/yy',
        firstDay: 1,
        isRTL: false,
        showMonthAfterYear: false,
        yearSuffix: ''
    };
    $.datepicker.setDefaults($.datepicker.regional['fr']);

    $.timepicker.regional['fr'] = {
        timeOnlyTitle: 'Choisir une heure',
        timeText: 'Heure',
        hourText: 'Horaire',
        minuteText: 'h',
        currentText: 'Maintenant',
        closeText: 'Valider',
        timeFormat: 'HH:mm',
        timeSuffix: '',
        hourMin : 10,
        hourMax : 17,
        stepMinute : 30,
        maxTime : '17:00'
    };

    function disableCustomDate(date) {

        var year = date.getFullYear(),
            month = date.getMonth()+1, // month start at 0
            day = date.getDate(),
            weekDay = date.getDay();

        if (day < 10) day = '0' + day;
        if (month < 10) month = '0' + month;

        var current = year + '-' + month + '-' + day;

        if ($.inArray(current, arrayDisabledBookingDates) != -1) {
            return [false];
        }

        // Disable monday
        if (weekDay == 1) {
            return [false];
        }

        return [true];
    }

    $('#homeReservationDate').datetimepicker({
        numberOfMonths: 2,
        buttonImageOnly: true,
        defaultDate: 1,
        minDate: 1,
        oneLine: false,
        controlType: 'select',
        showTime: false,
        timeFormat: '',
        closeText: 'Valider',
        beforeShow: function() {
            setTimeout(function() { 
                $('#ui-datepicker-div').prepend("<div class='closeReservation closeDatePicker'><span></span></div>");

                $('.closeDatePicker').on('click', function () {
                    $('#homeReservationDate').datetimepicker("hide");
                });
            }, 100);
        },
        beforeShowDay: disableCustomDate
    });
});