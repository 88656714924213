(function () {

    'use strict';

    // Called after the image has been added to DOM
    // Removes placeholder
    function unwrapImage() {

        this.parentElement.insertBefore(this.childNodes[0], this);
        this.parentElement.removeChild(this);
    }

    // Called whenever an image has loaded.
    // Adds loaded class to the wrapper so the image fades in
    function onImgLoaded() {

        this.removeAttribute('style');
        this.className = (this.className + ' loaded').trim();

        window.setTimeout(unwrapImage.bind(this), 350);
    }

    // Called upon image loading.
    // Adds image to its wrapper after it has loaded
    function onImgLoad(wrapper) {

        if (wrapper.getAttribute('data-alt') !== null && wrapper.getAttribute('data-alt') !== '') {

            this.setAttribute('alt', wrapper.getAttribute('data-alt'));
        }

        if (wrapper.getAttribute('data-width') !== null && wrapper.getAttribute('data-width') !== '') {

            this.setAttribute('width', wrapper.getAttribute('data-width'));
        }

        if (wrapper.getAttribute('data-height') !== null && wrapper.getAttribute('data-height') !== '') {

            this.setAttribute('height', wrapper.getAttribute('data-height'));
        }

        if (wrapper.getAttribute('data-usemap') !== null && wrapper.getAttribute('data-usemap') !== '') {

            this.setAttribute('usemap', wrapper.getAttribute('data-usemap'));
        }

        if (wrapper.appendChild(this)) {

            window.setTimeout(onImgLoaded.bind(wrapper), 50);
        }
    }

    // Called upon image loading error.
    // Changes image source to "no visu"
    function onImgErr(wrapper) {

        // this.src = path_relative_root + 'img/no_visu_small.jpg';
        this.className += ' error';
    }

    var debug = 0;

    // Computes element's offset from top of the page.
    function absoluteOffset(elm) {

        var output = elm.offsetTop;

        while (elm.offsetParent !== null && elm.offsetParent !== document.body) {

            elm = elm.offsetParent;

            output += elm.offsetTop;
        }

        return output;
    }

    // Called for each non-loaded image upon window scroll
    // Initiates image loading if needed
    function checkImgPosition() {

        var img_off = absoluteOffset(this),
            scroll = window.scrollY || document.documentElement.scrollTop,
            image;

        scroll += window.innerHeight;

        if (scroll >= img_off) {

            this.setAttribute('data-loaded', '1');

            image = new Image();

            image.addEventListener('load', onImgLoad.bind(image, this));
            image.addEventListener('error', onImgErr.bind(image, this));

            if (this.id && this.id !== '') {

                image.id = this.id;
            }

            image.className = this.className.replace('ill_img', '').trim();

            if (image.className === '') {

                image.removeAttribute('className');
            }

            if (!this.getAttribute('data-src') || this.getAttribute('data-src') === '') {

                return false;
            } else {

                image.src = this.getAttribute('data-src');
            }
        }
    }

    // Called upon window scroll. Processes images
    function processImgs() {

        var idx = 0, image, img_state;

        for (idx = 0; idx < this.images.length; idx += 1) {

            image = this.images[idx];
            img_state = image.getAttribute('data-loaded');

            if (img_state === undefined || !img_state) {

                checkImgPosition.call(image);
            }
        }
    }

    function computeImgHeight(image) {

        var img_w = parseFloat(image.getAttribute('data-width'));
        var img_h = parseFloat(image.getAttribute('data-height'));

        return image.offsetWidth * (img_h / img_w);
    }

    // Initiates event listeners.
    // Sets dimensions and processes images on load
    function init() {

        var idx = 0, image;

        if (this.is_running) {

            window.removeEventListener('scroll', processImgs.bind(this));
            window.removeEventListener('resize', processImgs.bind(this));
            window.removeEventListener('orientationchange', processImgs.bind(this));
        }

        window.addEventListener('scroll', processImgs.bind(this));
        window.addEventListener('resize', processImgs.bind(this));
        window.addEventListener('orientationchange', processImgs.bind(this));

        this.is_running = true;

        for (idx = 0; idx < this.images.length; idx += 1) {

            image = this.images[idx];

            if (image.getAttribute('data-width') !== '' && image.getAttribute('data-height') !== '') {

                var img_autoheight = computeImgHeight(image);

                if (img_autoheight !== image.offsetHeight) {

                    image.style.height = img_autoheight + 'px';
                }
            }
        }

        processImgs.call(this);
    }

    // Object Prototype
    function ImageLazyLoader() {

        this.is_running = false;
        this.images = window.document.getElementsByClassName('ill_img');

        if (this.images.length > 0) {

            init.call(this);
        }
    }

    ImageLazyLoader.prototype.init = init;

    // Creates ILL Instance
    function main() {

        window.lazyload = new ImageLazyLoader();

        window.removeEventListener('load', main);
    }

    window.addEventListener('load', main);
}());
