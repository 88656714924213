function verif_form_newsletter(formId){
	var bFlag = true;
	var elementIDs = new Array(
							   'newsletterNom',
							   'newsletterPrenom',
							   'newsletterMail'
							   );
	setInitialClassName(elementIDs);
	bFlag = verif_alpha('newsletterNom', 'nom');
	if ( bFlag == true ) { bFlag = verif_alpha('newsletterPrenom', 'js_first_name'); } else { return false; }
	if ( bFlag == true ) { bFlag = verif_mail('newsletterMail', 'js_email'); } else { return false; }
	if ( bFlag != true ) { return false; }
}
function verif_form_cdg() {
	if (document.formPanier.paiementcgv.checked == true) {
		return true;
	}else{
		alert(ajax_file('ajax_sprintf.php?arg1=js_error_cgv'));
		return false;
	}
}
function verif_form_client() {
	var bFlag = true;
	if ( document.getElementById('mes_err') ){
		document.getElementById('mes_err').style.display ='none';
	}
	var elementIDs = new Array(
							   'clientCivilite','clientNom','clientPrenom', 'clientMail','clientMailv',
							   'clientPwd','clientPwdv','clientDatenais'
							   ,'clientTel','clientMobile', 'clientFax',
							   'etage','ascenseur','digicode',
							   'adresseFact','codeP','ville'
							   );
	setInitialClassName(elementIDs);
	var elementDateIDs = new Array(
								   'clientDatenais'
								   );
	setInitialDateClassName(elementDateIDs);
	bFlag = verif_alpha('clientNom', 'js_last_name');
	if ( bFlag == true ) { bFlag = verif_alpha('clientPrenom', 'js_first_name'); } else { return false; }
	if (bFlag == true) { bFlag = verif_format_date_select('clientDatenais', "js_date_naissance"); } else { return false; }
	if (bFlag == true) { bFlag = verif_format_majorite('clientDatenais', ""); } else { return false; }
	if (bFlag == true) {
		if (document.getElementById('clientTel').value == '') {
			var elementID='clientTel';
			var objElement = document.getElementById(elementID);
			var objLabel = document.getElementById(elementID + 'Label');
			var erreurFieldset = document.getElementById('mes_err_fieldset');
			var erreurText = document.getElementById('erreur_msg');
			erreurFieldset.style.display = 'block';
			erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=thanks_precise_tel');
			objElement.className = 'inputErr';
			objLabel.className = 'labelErr';
			objElement.focus();
			bFlag = false
		} else {
			bFlag = true;
		}
	} else {
		return false;
	}
	if ( bFlag == true ) { bFlag = verif_mail('clientMail', 'js_mail'); } else { return false; }
	if ( bFlag == true ) { bFlag = verif_mail('clientMailv', 'js_mail_verif'); } else { return false; }
	if ( bFlag == true ) {
		if (document.getElementById('clientMail').value != document.getElementById('clientMailv').value){
			var elementID='clientMailv';
			var objElement = document.getElementById(elementID);
			var objLabel = document.getElementById(elementID + 'Label');
			var erreurFieldset = document.getElementById('mes_err_fieldset');
			var erreurText = document.getElementById('erreur_msg');
			erreurFieldset.style.display = 'block';
			erreurText.innerHTML = ajax_file( 'ajax_sprintf.php?arg1=js_wrong_mail_verif' );
			objElement.className = 'inputErr';
			objLabel.className = 'labelErr';
			objElement.focus();
			return false;
		}
		else{
			bFlag = true;
		}
	}
	else {
		return false;
	}
	if ( bFlag == true ) {
		var elementID = 'clientCivilite';
		var objElement = document.getElementById(elementID);
		var objLabel = document.getElementById(elementID + 'Label');
		var erreurFieldset = document.getElementById('mes_err_fieldset');
		var erreurText = document.getElementById('erreur_msg');
		if (objElement) {
			var objLabel = document.getElementById(elementID + 'Label');
			if (document.clientForm.clientCivilite[0].checked==false && document.clientForm.clientCivilite[1].checked==false && document.clientForm.clientCivilite[2].checked==false) {
				erreurFieldset.style.display = 'block';
				erreurText.innerHTML = ajax_file( 'ajax_sprintf.php?arg1=js_civilite' );
				objElement.className = 'inputErr';
				objLabel.className = 'labelErr';
				return false;
			}
			else{
				objElement.className = 'inputForm';
				objLabel.className = 'labelForm';
				bFlag = true;
			}
		}
		else{
			erreurFieldset.style.display = 'block';
			erreurText.innerHTML = ajax_file( 'ajax_sprintf.php?arg1=js_civilite' );
			return false;
		}
	}else{ return false; }
	if ( bFlag == true ) { bFlag = verif_alphanum('clientPwd', 'js_password'); } else { return false; }
	if ( bFlag == true ) { bFlag = verif_alphanum('clientPwdv', 'js_password_verif'); } else { return false; }
	if ( bFlag == true ) {
		if (document.getElementById('clientPwdv').value != document.getElementById('clientPwd').value){
			var elementID='clientPwdv';
			var objElement = document.getElementById(elementID);
			var objLabel = document.getElementById(elementID + 'Label');
			var erreurFieldset = document.getElementById('mes_err_fieldset');
			var erreurText = document.getElementById('erreur_msg');
			erreurFieldset.style.display = 'block';
			erreurText.innerHTML = ajax_file( 'ajax_sprintf.php?arg1=js_wrong_password_verif' );
			objElement.className = 'inputErr';
			objLabel.className = 'labelErr';
			objElement.focus();
			return false;
		}
		else{
			bFlag = true;
		}
	}
	else {
		return false;
	}
	if ( bFlag == true ) {

	} else { return false; }
	if ( bFlag == true ) {

	} else { return false; }

	if ( bFlag == true ) {
	if (document.getElementById('clientDatenaisJ').value != ""
		|| document.getElementById('clientDatenaisM').value != ""
		|| document.getElementById('clientDatenaisA').value != "") { bFlag = verif_format_date_select('clientDatenais', "js_date_naissance"); } else { bFlag = true; }
	} else { return false; }
	if ( bFlag != true ) { return false; }
	return true;
}
function verif_form_clientad() {
	var bFlag = true;
	if ( document.getElementById('mes_err') ){
		document.getElementById('mes_err').style.display ='none';
	}
	var elementIDs = new Array(
							   'adresseNom','adressePrenom',
							   'societe','etage','ascenseur','digicode',
							   'adresseLiv','codeP','ville'
							   );
	setInitialClassName(elementIDs);
	bFlag = verif_alphanum('adresseNom', 'js_last_name');
	if ( bFlag == true ) { bFlag = verif_alphanum('adressePrenom', 'js_first_name'); } else { return false; }
	if ( bFlag == true ) {
		if (document.getElementById('societe').value != "") { bFlag = verif_alphanum('societe', 'js_company'); } else { bFlag = true; }
	} else { return false; }
	if ( bFlag == true ) {
		if (document.getElementById('etage').value != "") { bFlag = verif_etage('etage', 'js_etage', 3); } else { bFlag = true; }
	} else { return false; }
	if ( bFlag == true ) { bFlag = verif_alphanum('adresseLiv', 'js_adresse_livraison'); } else { return false; }

	if ( bFlag == true ) { bFlag = verif_num('codeP', 'js_zipcode', 5); } else { return false; }
	if ( bFlag == true ) { bFlag = verif_alpha('ville', 'js_city'); } else { return false; }
	if ( bFlag != true ) { return false; }
}
function verif_form_clientad_sanstypevoie() {
	var bFlag = true;
	if ( document.getElementById('mes_err') ){
		document.getElementById('mes_err').style.display ='none';
	}
	var elementIDs = new Array(
							   'adresseCivilite', 'adresseNom','adressePrenom','telephone',
							   'etage','ascenseur','digicode',
							   'adresseLiv','codeP','ville'
							   );
	setInitialClassName(elementIDs);
	bFlag = verif_alphanum('adresseNom', 'js_last_name');
	if ( bFlag == true ) { bFlag = verif_alphanum('adressePrenom', 'js_first_name'); } else { return false; }
	if ( bFlag == true ) {
		if (document.getElementById('societe').value != "") { bFlag = verif_alphanum('societe', 'js_company'); } else { bFlag = true; }
	} else { return false; }
	if ( bFlag == true ) {
		var elementID = 'adresseCivilite';
		var objElement = document.getElementById(elementID);
		var objLabel = document.getElementById(elementID + 'Label');
		var erreurFieldset = document.getElementById('mes_err_fieldset');
		var erreurText = document.getElementById('erreur_msg');
		if (objElement) {
			var objLabel = document.getElementById(elementID + 'Label');
			if (document.clientForm.adresseCivilite[0].checked==false && document.clientForm.adresseCivilite[1].checked==false && document.clientForm.adresseCivilite[2].checked==false) {
				erreurFieldset.style.display = 'block';
				erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_choose_civilite');
				objElement.className = 'inputErr';
				objLabel.className = 'labelErr';
				return false;
			}
			else{
				objElement.className = 'inputForm';
				objLabel.className = 'labelForm';
				bFlag = true;
			}
		}
		else{
			erreurFieldset.style.display = 'block';
			erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_choose_civilite');
			return false;
		}
	}else{ return false; }
	if ( bFlag == true ) { bFlag = verif_alphanum('adresseLiv', 'js_adresse_livraison'); } else { return false; }
	if ( bFlag == true ) { bFlag = verif_alphanum('codeP', 'js_zipcode'); } else { return false; }
	if ( bFlag == true ) { bFlag = verif_alpha('ville', 'js_city'); } else { return false; }
	if ( bFlag == true ) {
		if (document.getElementById('etage').value != "") { bFlag = verif_etage('etage', 'js_etage', 3); } else { bFlag = true; }
	} else { return false; }
	if ( bFlag == true ) { bFlag = verif_zip('codeP', 'pays', 'js_zipcode', 5); } else{ return false; }
	if ( bFlag != true ) { return false; }
}
function verif_form_alertestock(){
	var bFlag = true;
	var erreurFieldset = document.getElementById('mes_err_fieldset');
	var erreurText = document.getElementById('erreur_msg');
	var elementIDs = new Array('nbPoint');
	setInitialClassName(elementIDs);
	 if (bFlag == true && (document.getElementById('alerteStatut1').checked == true) && ((document.getElementById('dateDebut').value > document.getElementById('today').value) ||
		(document.getElementById('dateFin').value < document.getElementById('today').value))){
			erreurFieldset.style.display = 'block';
			erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_alerte_stock');
			return false;
	 }
	else if (bFlag == true && (document.getElementById('alerteStatut3').checked == true) ){
			bFlag = verif_num('nbPoint', 'js_point_produit', 5);
	}
	else{
		return false;
	}
	if ( bFlag != true ) { return false; }
}
function verif_form_clientvp() {
	var bFlag = true;
	if ( document.getElementById('mes_err') ){
		document.getElementById('mes_err').style.display ='none';
	}
	var elementIDs = new Array(
							   'clientCivilite','clientNom','clientPrenom', 'clientMail','clientMailv',
							   'clientPwd','clientPwdv'
							   );
	setInitialClassName(elementIDs);
	bFlag = verif_alpha('clientPrenom', 'js_first_name');
	if ( bFlag == true ) { bFlag = verif_alpha('clientNom', 'js_last_name'); } else { return false; }
	if ( bFlag == true ) { bFlag = verif_mail('clientMail', 'js_mail'); } else { return false; }
	if ( bFlag == true ) { bFlag = verif_mail('clientMailv', 'js_verif_mail'); } else { return false; }
	if ( bFlag == true ) {
		if (document.getElementById('clientMail').value != document.getElementById('clientMailv').value){
			var elementID='clientMailv';
			var objElement = document.getElementById(elementID);
			var objLabel = document.getElementById(elementID + 'Label');
			var erreurFieldset = document.getElementById('mes_err_fieldset');
			var erreurText = document.getElementById('erreur_msg');
			erreurFieldset.style.display = 'block';
			erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_wrong_mail_verif');
			objElement.className = 'inputErr';
			objLabel.className = 'labelErr';
			objElement.focus();
			return false;
		}
		else{ bFlag = true; }
	}
	else { return false; }
	if ( bFlag == true ) {
		var elementID = 'clientCivilite';
		var objElement = document.getElementById(elementID);
		var objLabel = document.getElementById(elementID + 'Label');
		var erreurFieldset = document.getElementById('mes_err_fieldset');
		var erreurText = document.getElementById('erreur_msg');
		if (objElement) {
			var objLabel = document.getElementById(elementID + 'Label');
			if (document.clientVPForm.clientCivilite[0].checked==false && document.clientVPForm.clientCivilite[1].checked==false && document.clientVPForm.clientCivilite[2].checked==false) {
				erreurFieldset.style.display = 'block';
				erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_choose_civilite');
				objElement.className = 'inputErr';
				objLabel.className = 'labelErr';
				return false;
			}
			else{
				objElement.className = 'inputForm';
				objLabel.className = 'labelForm';
				bFlag = true;
			}
		}
		else{
			erreurFieldset.style.display = 'block';
			erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_choose_civilite');
			return false;
		}
	}else{ return false; }
	if ( bFlag == true ) {
		if (document.getElementById('clientMail').value != document.getElementById('clientMailv').value){ return false; }else{ bFlag = true; }
	} else { return false; }
	if ( bFlag == true ) { bFlag = verif_alphanum('clientPwd', 'js_password'); } else { return false; }
	if ( bFlag == true ) { bFlag = verif_alphanum('clientPwdv', 'js_password_verif'); } else { return false; }
	if ( bFlag == true ) {
		if (document.getElementById('clientPwdv').value != document.getElementById('clientPwd').value){
			var elementID='clientPwdv';
			var objElement = document.getElementById(elementID);
			var objLabel = document.getElementById(elementID + 'Label');
			var erreurFieldset = document.getElementById('mes_err_fieldset');
			var erreurText = document.getElementById('erreur_msg');
			erreurFieldset.style.display = 'block';
			erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_wrong_password_verif');
			objElement.className = 'inputErr';
			objLabel.className = 'labelErr';
			objElement.focus();
			return false;
		}
		else{ bFlag = true; }
	}
	else { return false; }
	if ( bFlag != true ) { return false; }
}
var timer;
function checkForm(type,elem,fieldname,from, blockMenuTopConnexionInfobulle ) {
	if(blockMenuTopConnexionInfobulle == undefined) {blockMenuTopConnexionInfobulle = 0;};
	if( from != 'click' ){
		elementID = elem.id;
	}else{
		elementID = elem;
	}
	var already_mail = false;
	if( elem == 'clientCivilite' ) elementID = elem;
	switch(type){
		case"prix":
			var flag = verif_price(elementID,fieldname);
			break;
		case 'alpha':
			var flag = verif_alpha(elementID,fieldname,false);
			break;
		case 'alpha_optional':
			var flag = true;
			if( document.getElementById(elementID).value != '' ) flag = verif_alpha(elementID,fieldname,false);
			break;
		case 'alphanum':
			var flag = verif_alphanum(elementID,fieldname,false);
			break;
		case 'alphanumadresse':
			var flag = verif_alphanumadresse(elementID,fieldname,false);
			break;
		case 'alphanum_optional':
			var flag = true;
			if( document.getElementById(elementID).value != '' ) {
				if(fieldname.indexOf('|')!=-1){
					length = fieldname.substring(fieldname.indexOf('|')+1);
					fieldname = fieldname.substring(0,fieldname.indexOf('|'));
					flag = verif_alphanum(elementID,fieldname,true,length);
				}
				else flag = verif_alphanum(elementID,fieldname,false);
			}
			break;
		case 'same':
			elemCmpID = elementID.substring(0,elementID.length-1);
			var flag = verif_alphanum(elementID,fieldname,false);
			if( flag ) flag = verifSame( elemCmpID, elementID, fieldname );
			break;
		case 'same_optional':
			var flag = true;
			elemCmpID = elementID.substring(0,elementID.length-1);
			if( document.getElementById(elementID).value != '' || document.getElementById(elemCmpID).value != ''  ) {
				var flag = verif_alphanum(elementID,fieldname,false);
				if( flag ) flag = verifSame( elemCmpID, elementID, fieldname );
			}
			break;
		case 'mail':
			var flag = verif_mail(elementID,fieldname);
			break;
		case 'mail_log':
			var flag = verif_mail(elementID,fieldname);
			if( flag ) {
				var action = $("#" + elementID).closest('form').attr('action');
				flag = ajax_file(path_relative_root + 'ajax_email_exist.php?mail='+document.getElementById(elementID).value+ '&action='+action);
				var flag_ret = flag;
				if( flag == '1' || flag == '2' ) flag = false;
				else flag = true;
				if( !flag ) {
					var objElement = document.getElementById(elementID);
					var objLabel = document.getElementById(elementID + 'Label');
					var erreurText = document.getElementById('erreur_msg');
					if ( flag_ret == '1')
						erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file(path_relative_root +'ajax_sprintf.php?arg1='+fieldname) + ' :</p>' + ajax_file(path_relative_root +'ajax_sprintf.php?arg1=js_already_mail');
					else
						erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file(path_relative_root +'ajax_sprintf.php?arg1='+fieldname) + ' :</p>' + ajax_file(path_relative_root +'ajax_sprintf.php?arg1=login_erreur_17');
					objElement.className = 'inputErr';
					if(objLabel){
						objLabel.className = 'labelErr';
					}
					already_mail = true;
				}
			}
			break;
		case 'mail_log_info':
			var flag = verif_mail(elementID,fieldname);
			if( flag && ( document.getElementById(elementID).value != document.getElementById('old_clientMail').value ) ) {
				flag = ajax_file('ajax_email_exist.php?mail='+document.getElementById(elementID).value);
				if( flag == '1' ) flag = false;
				else flag = true;
				if( !flag ) {
					var objElement = document.getElementById(elementID);
					var objLabel = document.getElementById(elementID + 'Label');
					var erreurText = document.getElementById('erreur_msg');
					erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1='+fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_already_mail_short');
					objElement.className = 'inputErr';
					objLabel.className = 'labelErr';
					already_mail = true;
				}
			}
			break;
		case 'mail_verif':
			elemCmpID = elementID.substring(0,elementID.length-1);
			var flag = verif_mail(elementID,fieldname);
			if( flag ) flag = verifSame( elemCmpID, elementID, fieldname );
			break;
		case 'mail_optional':
			var flag = true;
			if( document.getElementById(elementID).value != '' ) flag = verif_mail(elementID,fieldname);
			break;
		case 'radio':
			var flag = verif_radio(elementID,fieldname);
			break;
		case 'select_date':
			var flag = verif_selected_date(elementID,fieldname);
			break;
		case 'phone':
			var flag = true;
			if( flag ) flag = verif_telephone(elementID,fieldname);
			break;
		case 'mobile':
			var flag = true;
			if( document.getElementById(elementID).value != '' ) {
				flag = verif_num( elementID,fieldname,10 )
				if( flag ) flag = verif_portable(elementID,fieldname);
			}
			break;
		case 'cp':
			var flag = true;
			if(document.getElementById('pays').value=='B' || document.getElementById('pays').value=='L' || document.getElementById('pays').value=='S' || document.getElementById('pays').value=='PT')
				nb = 4;
			else
				nb = 5;
			if(document.getElementById('pays').value != 'AD'){
				flag = verif_num( elementID,fieldname,nb );
			}
			if( flag ) flag = verif_zip( elementID, 'pays', fieldname,nb );
			break;
		case 'no_empty':
			var flag = verif_empty(elementID,fieldname);
			break;
		case 'borne_code' :
			var flag = verif_num(elementID,fieldname,6);
			break;
		case 'is_valid' : // Peremt de specifier que le champs sera toujours valid
			var flag = true;
			break;
	}

	var style = '';
	if( from == 'click' || elem == 'clientCivilite' ) {
		if( type == 'select_date' ) {
			elem = document.getElementById(elem+'J');
			if( elementID == 'newsletterDatenais' )
				style = 'style="margin-left:45px;"';
			else if(  elementID == 'clientDatenais') {
				style = 'style="margin-top:5px;margin-left:95px;"';
			} else if (elementID == 'garantieDateAchat') {
				style = 'style="margin: 5px 0 0 -10px"';
			}
			elementID += 'J';
		}
		else if( type == 'radio' ) {
			elem = document.getElementById(elem+'M');
			elementID += 'M';
			style = 'style="margin-left:307px;margin-top:4px;"';
		}
		else elem = document.getElementById(elem);
	}
	if( elementID == 'adresseTitre' && $.browser.webkit )
		style = 'style="margin:-22px 0 0 217px;"';

	if( !flag ) {
		if (blockMenuTopConnexionInfobulle != 1) {
			if ($('#' + elementID).closest('.formItem_container').children('img').length == 0) {
				$('#' + elementID).closest('.formItem_container').append('<img ' + style + ' src="'+path_relative_root+'img/picto_panneau.png" alt="" class="img_error" />');
			}
			elem_img = $("#"+elementID).closest('.formItem_container').children('img')[0];
		} else {
			document.getElementById(elem.id+'Err').style.display = 'table-row';
			if( $("#bloc_erreur").css('display') == 'table-row' || $("#bloc_erreur").css('display') == 'block'|| $("#bloc_erreur").css('display') == 'table' ) {
				$("#bloc_erreur").css('display','none')
				clearTimeout(timer);
			}
		}
	}
	if( !flag && from != 'click' ) {
		if (blockMenuTopConnexionInfobulle != 1) {
			pos = getPos(elem_img);
			if( from == 'spe' && !already_mail ) document.getElementById('erreur_msg').innerHTML = document.getElementById(elementID + 'Err').innerHTML;
			$("#bloc_erreur").fadeIn(400);
			document.getElementById('bloc_erreur').style.position = 'absolute';
			document.getElementById('bloc_erreur').style.top = pos.y - (document.getElementById('bloc_erreur').offsetHeight-5) + 'px';
			pos_left_margin = 0;
			if( document.getElementById('content') )
				pos_left_margin = getPos(document.getElementById('content'));
			document.getElementById('bloc_erreur').style.left = (pos.x-pos_left_margin.x-14) + 'px';
			document.getElementById('bloc_erreur').style.zIndex = '1';
			window.clearTimeout(timer);
			timer = setTimeout("closeInfobulle('bloc_erreur')",2000);
		}
	}
	else if( from != 'click' ) {
		window.clearTimeout(timer);
		timer = setTimeout("closeInfobulle('bloc_erreur')",2000);
	}
	if( !flag ) {
		var val = document.getElementById('erreur_msg').innerHTML;
			if( elem_img && (elementID == 'clientPwd' || elementID == 'clientMail' || elementID == 'clientMailAuth' || elementID == 'clientMailAuth' || elementID == 'clientPwdAuth' || elementID == 'mailLost') && !already_mail && blockMenuTopConnexionInfobulle != 1 ) {
				elem_img.setAttribute('onmouseover',function(){ openInfobulle('bloc_erreur','spe',elem); })
				elem_img.onmouseover=function(){ openInfobulle('bloc_erreur','spe',elem);}
				elem_img.setAttribute('onmouseout',function(){ closeInfobulle('bloc_erreur'); });
				elem_img.onmouseout=function(){ closeInfobulle('bloc_erreur');}
			}
			else if (elem_img && blockMenuTopConnexionInfobulle != 1) {
                elem_img.setAttribute('onmouseover',function(){ OverPopup(this,val,'visible'); });
                elem_img.onmouseover=function(){ OverPopup(this,val,'visible');}
                elem_img.setAttribute('onmouseout',function(){ OverPopup(this,val,'none'); });
                elem_img.onmouseout=function(){ OverPopup(this,val,'none');}
			}
	}
	else {
		if (blockMenuTopConnexionInfobulle == 1) {
			document.getElementById(elem.id+'Err').style.display = 'none';
		}
		if( $("#"+elementID).parent().children("img").length>0 ) {
			$("#"+elementID).parent().children("img").remove(".img_error");
		}
		if( $("#"+elementID).hasClass('inputErr')) {
			$("#"+elementID).removeClass('inputErr');
			//Au cas ou elle existerait deja, pour etre sur de ne l'ajouter qu'une seule fois
			$("#"+elementID).removeClass('inputForm');
			$("#"+elementID).addClass('inputForm');
		}
		if( $("#"+elementID+'Label').hasClass('labelErr')) {
			$("#"+elementID+'Label').removeClass('labelErr');
			//Au cas ou elle existerait deja, pour etre sur de ne l'ajouter qu'une seule fois
			$("#"+elementID+'Label').removeClass('labelForm');
			$("#"+elementID+'Label').addClass('labelForm');
		}
		if( $("#bloc_erreur").css('display') == 'table-row' || $("#bloc_erreur").css('display') == 'block' ) {
			$("#bloc_erreur").css('display','none');
			clearTimeout(timer);
		}
		if( from != 'spe' && elem != 'clientCivilite' ) {
			elem.setAttribute('onfocus',function(){})
			elem.onfocus=function(){}
		}
	}
	return flag;
}
function checkAllForm(tabError, blockMenuTopConnexionInfobulle, path_relative_root,id_form, nosubmit)
{
	if (blockMenuTopConnexionInfobulle == undefined) {blockMenuTopConnexionInfobulle = 0;};
	var bool_error = true;
	if	(
				$("#bloc_erreur").css('display') == 'table-row'
			||	$("#bloc_erreur").css('display') == 'block'
			||	$("#bloc_erreur").css('display') == 'table'
		)
	{
		$("#bloc_erreur").css('display','none');
		clearTimeout(timer);
	}
	if ( tabError == 'message' )
	{
		var msg =  tinyMCE.getInstanceById('message').getBody().innerHTML;
		if (!msg || typeof msg.length == 'undefined' || msg.length < 13)
		{
			bool_error = false;
		}
		else
		{
			bool_error = true;
		}
	}
	else {

		for (var field in tabError) {

			field = tabError[field];

			if (!checkForm(field[0], field[1], field[2], 'click', blockMenuTopConnexionInfobulle) && bool_error) {

				bool_error = false;
			}
		}
	}

	if (!bool_error)
	{
		hideLoader();
	}
	else if ( id_form != '' && typeof(id_form) != 'undefined' && !nosubmit )
	{
		$('#'+id_form).submit();
	}
	return bool_error;
}

function ajax_checkAllForm(tabError, blockMenuTopConnexionInfobulle, path_relative_root) {

	var nav = navigator.appVersion;

	console.group('ajax_checkAllForm');

	if (blockMenuTopConnexionInfobulle === undefined) {

		blockMenuTopConnexionInfobulle = 0;
	}

	if (checkAllForm(tabError, blockMenuTopConnexionInfobulle)) {

		if (tabError[0][1] && tabError[0][1] == 'clientMailAuthTop') {

			$('#AuthTopErr').hide();

			resultat = ajax_file(path_relative_root + 'ajax_login_allowed.php?mail=' + document.getElementById(tabError[0][1]).value + '&mdp=' + document.getElementById(tabError[1][1]).value + '&is_top=true');

			resultat = resultat.split('|');

			var nr_wishlist = resultat[9];

            if (nr_wishlist > 0) {

                $("#basket1").hide();
                $("#basket2").show();
                $("#btn_top_wishlist").show();
                $("#btn_top_wishlist span").html("(x" + nr_wishlist + ")");
            }

			var url = window.location.pathname;
			var index_deb = url.lastIndexOf('/');
			var index_fin = url.lastIndexOf('.');
			var provenance = url.substring(index_deb+1,index_fin);

			if (provenance == 'connexion') {

				window.location.href = path_relative_root + create_link('client_index');
			} else if (provenance == 'identification') {

				window.location.href = path_relative_root + 'livraison-kiala.php';
			} else {

				if (resultat[0] == 1) {

					document.getElementById('topMenuConnexionTop').innerHTML = '<img style="margin-left:89px;margin-top:61px;float:left;" src="'+path_relative_root+'img/ajaxLoader.gif" alt="Chargement en cours..." width="25" height="25" /><p style="float:left;margin-left:10px;margin-top:64px;"><strong>Connexion...</strong></p>';

					var str_return = '<table cellspacing="0" cellpadding="0" border="0"><tbody class="TopMenuConnexionOn"><tr><td style="color:#00367b;"><p style="border-bottom:2px dotted #d9d9d9; width:280px;"><strong>MON COMPTE</strong></p></td></tr>';
					var nb_en_cours = resultat[1];
					var nb_expediee = resultat[2];
					var nb_retournee = resultat[3];
					var nb_annulee = resultat[4];

					if (nb_en_cours > 0 || nb_expediee > 0 || nb_retournee > 0 || nb_annulee > 0) {

						str_return += '<tr style="height:5px;"></tr>'
						str_return += '<tr><td class="rose">MES COMMANDES</td></tr>';

						if (nb_en_cours > 0)
							str_return += '<tr><td><span class="tiret_top">-&nbsp;</span><a class="lien_top" title="En cours" href="' + path_relative_root + create_link('client_index') + '?onglet=1">En cours (' + nb_en_cours + ')</a></td></tr>';

						if (nb_expediee > 0)
							str_return += '<tr><td><span class="tiret_top">-&nbsp;</span><a class="lien_top" title="Exp�di�es" href="' + path_relative_root + create_link('client_index') + '?onglet=2">Exp�di�es (' + nb_expediee + ')</a></td></tr>';

						if (nb_retournee > 0)
							str_return += '<tr><td><span class="tiret_top">-&nbsp;</span><a class="lien_top" title="Retourn�es" href="' + path_relative_root + create_link('client_index') + '?onglet=3">Retourn�es (' + nb_retournee + ')</a></td></tr>';

						if (nb_annulee > 0)
							str_return += '<tr><td><span class="tiret_top">-&nbsp;</span><a class="lien_top" title="Annul�es" href="' + path_relative_root + create_link('client_index') + '?onglet=4">Annul�es (' + nb_annulee + ')</a></td></tr>';
					}
					str_return += '<tr style="height:5px;"></tr>';
					str_return += '<tr><td class="rose">MES INFORMATIONS</td></tr>';
					str_return += '<tr><td><span class="tiret_top">-&nbsp;</span><a class="lien_top" title="Informations Personnelles" href="' + path_relative_root + create_link('client_myinfos') + '">Informations Personnelles</a></td></tr>';
					str_return += '<tr><td><span class="tiret_top">-&nbsp;</span><a class="lien_top" title="Mon Carnet d\'adresses" href="' + path_relative_root + create_link('client_addresses') + '">Mon Carnet d\'adresses</a></td></tr>';

					var nb_bills = resultat[5];
					var nb_refunds = resultat[6];

					if (nb_bills > 0 || nb_refunds > 0) {

						str_return += '<tr style="height:5px;"></tr>';
						str_return += '<tr><td class="rose">ADMINISTRATIFS</td></tr>';

						if (nb_bills > 0)
							str_return += '<tr><td><span class="tiret_top">-&nbsp;</span><a class="lien_top" title="Mes Factures" href="' + path_relative_root + create_link('order_billlist') + '">Mes Factures</a></td></tr>';

						if (nb_refunds > 0)
							str_return += '<tr><td><span class="tiret_top">-&nbsp;</span><a class="lien_top" title="Mes Avoirs" href="' + path_relative_root + create_link('order_avoirlist') + '">Mes Avoirs</a></td></tr>';
					}

					str_return += '<tr style="height:5px;"></tr>';
					str_return += '<tr><td class="rose">NEWSLETTERS</td></tr>';
					str_return += '<tr><td><span class="tiret_top">-&nbsp;</span><a class="lien_top" title="Pr�f�rences Newsletters" href="' + path_relative_root + create_link('newsletter_index') + '">Preferences Newsletters</a></td></tr>';
					str_return += '<tr style="height:5px;"></tr>';
					str_return += '<tr><td class="rose">PARRAINAGE</td></tr>';
					str_return += '<tr><td><span class="tiret_top">-&nbsp;</span><a class="lien_top" title="Espace Parrainage" href="' + path_relative_root + 'parrainage.php">Espace Parrainage</a></td></tr>';
					str_return += '<tr style="height:5px;"></tr>';
					str_return += '<tr class="topMenuConnexionBottom">';
					str_return += '<td style="text-align:right; padding-right:17px; text-decoration:none;" class="topMenuConnexionBottomInside pink" colspan="2">';
					str_return += '<a title="D�connexion" onclick="ajaxDeconnect(\'' + path_relative_root + '\');" href="#">';
					str_return += 'D�connexion <img src="' + path_relative_root + 'img/picto_logout.jpg" alt="D�connexion"/></a></td></tr></tbody></table>';

					document.getElementById('topMenuConnexionTop').innerHTML = str_return;
					document.getElementById('entete_connexion').innerHTML = 'Bonjour ' + resultat[7].charAt(0).toUpperCase() + resultat[7].substring(1).toLowerCase() + ' ' + resultat[8].toUpperCase() + ' ';
					document.getElementById('lien_mon_compte').href = path_relative_root + create_link('client_index');

					return false;
				} else {

					document.getElementById('AuthTopErr').style.display = 'table-row';
					hideLoader();
					return false;
				}
			}
		} else if (tabError[0][1] && tabError[0][1] == 'mailLostTop') {
			document.getElementById('lostpwdTopErr').style.display = 'none';
			resultat = ajax_file(path_relative_root+ 'ajax_email_exist.php?mail='+document.getElementById(tabError[0][1]).value);
			if (resultat == 1) {
				resultat = ajax_file(path_relative_root+ 'ajax_send_new_password_link.php?mail='+document.getElementById(tabError[0][1]).value);
				show_lightbox('popup_confirm_reinitialisation_mdp');
				document.getElementById('lostpwdTopErr').style.display = 'none';
				document.getElementById('lostpwdTopSuccesTitle').style.visibility = 'hidden';
				hideLoader();
				reloadBlocLoginTop();
				return false;
			} else {
				if ( nav.indexOf('MSIE 7') >= 0 )
					document.getElementById('lostpwdTopErr').style.display = 'block';
				else
					document.getElementById('lostpwdTopErr').style.display = 'table-row';
				hideLoader();
				return false;
			}
		} else if (tabError[0][1] && tabError[0][1] == 'clientMailAuth') {
			resultat = ajax_file(path_relative_root +'ajax_login_allowed.php?mail='+document.getElementById(tabError[0][1]).value+'&mdp='+document.getElementById(tabError[1][1]).value+'&is_top=false');
			if (resultat == 1) {
				hideLoader();
				$('#auth').submit();
				return true;
			} else {
				if ( nav.indexOf('MSIE 7') >= 0 )
					document.getElementById('AuthErrErr').style.display = 'block';
				else
					document.getElementById('AuthErrErr').style.display = 'table-row';
				hideLoader();
				return false;
			}
		} else if (tabError[0][1] && tabError[0][1] == 'mailLost') {
			resultat = ajax_file(path_relative_root + 'ajax_email_exist.php?mail='+document.getElementById(tabError[0][1]).value);
			if (resultat == 1) {
				resultat = ajax_file(path_relative_root+ 'ajax_send_new_password_link.php?mail='+document.getElementById(tabError[0][1]).value);
				show_lightbox('popup_confirm_reinitialisation_mdp');
				document.getElementById('lostpwdErr').style.display = 'none';
				document.getElementById('lostpwdSuccesTitle').style.visibility = 'hidden';
				hideLoader();
				return false;
			} else {
				if ( nav.indexOf('MSIE 7') >= 0 )
					document.getElementById('lostpwdErr').style.display = 'block';
				else
					document.getElementById('lostpwdErr').style.display = 'table-row';
				hideLoader();
				return false;
			}
		}
		else {
			hideLoader();
			return false;
		}
	}
	else
	{
		hideLoader();
		return false;
	}
}
