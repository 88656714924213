var save_id = 0;
var cpt_save = 0;
var from_range = false;  //for wishlist

function addEvent(elem,event,name_function) {

    if (document.all) elem.attachEvent("on"+event,name_function);
    else elem.addEventListener(event,name_function,false);

}

function delEvent(elem,event,name_function) {

    if (document.all) elem.detachEvent("on"+event,name_function);
    else elem.removeEventListener(event,name_function,false);

}

// empeche les evenements de remonter dans l'arbre

function doNothing(e) {

if (document.all) {
  if (!e) var e = window.event;
  e.cancelBubble = true;
 }
 else {
    e.stopPropagation();
 }
 return false;
}
// popup guide des tailles
function showsizeguide() {
    var elname = 'sizeguidebox';

    var actualposition = document.documentElement.scrollTop;
    var modbox = document.getElementById(elname);
    var shade = document.getElementById('shade');

    /*var option=['btn_femmes','btn_hommes','btn_filles','btn_garcons','btn_bebes'];
    for(var i=0; i<option.length; i++) {
            obj=document.getElementById(option[i]);
            obj.style.display="none";
    }*/



    modbox.style.position = "absolute";
    shade.style.display="block";

    addEvent(shade,'click',closesizeguide);
    /*addEvent(modbox,'click',closesizeguide);*/

    addEvent(document.getElementById('menu_sizeguide'), 'click', doNothing);

    modbox.style.display="block";

    window.scrollTo(0,actualposition);
}

function closesizeguide() {
    var elname = 'sizeguidebox';
    var shade = document.getElementById('shade');
    var modbox = document.getElementById(elname);
    modbox.style.display="";

    delEvent(shade,'click',closesizeguide);
    delEvent(modbox,'click',closesizeguide);
    delEvent(document.getElementById('menu_sizeguide'), 'click', doNothing);
    closemodal();
}

// switch guide des tailles
function switch1(div) {

    if (document.getElementById('sizeguide_femme')) {
        var option=['sizeguide_femme','sizeguide_homme','sizeguide_lingerie','sizeguide_fille','sizeguide_garcon','sizeguide_bebe'];
        for(var i=0; i<option.length; i++) {
            obj=document.getElementById(option[i]);
            obj.style.display=(option[i]==div)? "block" : "none";
        }
    }

    cpt_save++;

}

function showmodal(produit_id) {
    var actualposition = document.documentElement.scrollTop;
    var modbox = document.getElementById('modbox');
    var cmodbox = document.getElementById('cmodbox');
    var panmodbox = document.getElementById('panmodbox');
    var shade = document.getElementById('shade');
    document.body.style.overflow='hidden';

    cmodbox.innerHTML='';
    panmodbox.innerHTML = '';

    modbox.style.marginTop = (actualposition-260)+"px";
    shade.style.display="block";
    modbox.style.display="block";
    document.getElementsByTagName("html")[0].style.overflow = "hidden";

    window.scrollTo(0,actualposition);
}

var addToCartTmpl;

function buildItemAddedModbox(id_produit, is_not_produit) {

    var form = document.getElementById('prod_info_' + id_produit);
    var closing_method = (is_not_produit == 'true') ? 'window.location.reload()' : 'closeMultiShad(\'modbox\')';

    if (document.getElementById('nbr_visu_0')) {

        var itm_vis = $('#img_large').attr('src').replace('/mini/', '/large/');
    }

    var itm_name = $('#itm_name').get(0).textContent.trim();

    if ($('#itm_feat', form).length > 0) {

        var itm_feat = $('#itm_feat', form).get(0).textContent.trim();
    }

    var itm_price = $(".item_price .pricetag").html();

    if ($(".pricetag:first + .pricetag", form).length) {

        var itm_fullprice = $(".pricetag:first + .pricetag", form).html();
    }

    if (form) {

        var itm_length = parseFloat(form.elements.namedItem('qteProd').value);
        var itm_color = form.elements.namedItem('couleurProd').value;
        var itm_size = form.elements.namedItem('tailleProd').value;

        if (itm_length > 1) {
            itm_length += ' ' + translate('items');
        } else {
            itm_length += ' ' + translate('item');
        }

        if (itm_length == 1) {
            itm_length += ' ' + translate('added_to_cart_sn');
        }
        if ($("input[name=itm_color]", form).length) {
            itm_color = $("input[name=itm_color]", form).filter("[value=" + itm_color + "]").next("label").attr("title").trim();
        }
        itm_size = $("input[name=taille]:checked").next("label").html();
    }

    $('#shade').after($('#modbox'));

    document.getElementById('modbox').innerHTML = Mustache.render(addToCartTmpl, {
        closing_method: closing_method,
        itm_vis: itm_vis,
        itm_name: itm_name,
        itm_feat: itm_feat,
        itm_price: itm_price,
        itm_fullprice: itm_fullprice,
        itm_length: itm_length,
        itm_color: itm_color,
        itm_size: itm_size
    });
}

function buildLookAddedModbox(is_not_produit) {

    var form = document.getElementById('slide_look_detail');
    var closing_method = (is_not_produit == 'true') ? 'window.location.reload()' : 'close_lightbox(\'modbox\')';
    var look_total = formatPrice(parseFloat(document.getElementById('look_total').value));
    var look_items = [];

    $('fieldset', form).each(function () {

        var itm_quantity = parseFloat($('.qte_select_wrapper select', this).get(0).value);

        if (itm_quantity > 0) {

            var new_look_itm = {};

            new_look_itm.itm_visu = $('.visu_look', this).attr('src').replace('/medium/', '/mini/');
            new_look_itm.itm_name = this.getValue('itm_name');
            new_look_itm.itm_quantity = itm_quantity;
            new_look_itm.itm_total = formatPrice(this.getValue('itm_price', parseFloat) * itm_quantity);

            look_items.push(new_look_itm);
        }
    });

    $('#shade').after($('#modbox'));

    document.getElementById('modbox').innerHTML = Mustache.render(addToCartTmpl, {
        closing_method : closing_method,
        look_total : look_total,
        look_items : look_items
    });
}

function newshowmodal(id_produit, is_not_produit) {

    if (!addToCartTmpl) {
        addToCartTmpl = document.getElementById('modbox').innerHTML.trim();
        Mustache.parse(addToCartTmpl);
    }
    if (type != 'err') {
        buildItemAddedModbox(id_produit, is_not_produit);
        openMultiShad('modbox');
    }
}


function showsendfriendmodal(product_id, path_web, type) {

    var elname = (type === 'look_basket') ? 'modbox' : 'sendfriendbox';
    var actualposition = document.documentElement.scrollTop ;
    var modbox = document.getElementById(elname);
    var shade = document.getElementById('shade');

    if (type !== 'look_basket') {

        var psnok = document.getElementById('product_sendfriend_nok');
        var psfok = document.getElementById('product_sendfriend_ok');

        if (psnok) {

            psnok.innerHTML = '';
            psnok.style.display = 'none';
        }

        if (psfok) {
            psfok.innerHTML = '';
            psfok.style.display = 'none';
        }

        $(shade).after($(modbox));
        prodGetProductDisplay(product_id, path_web, type);

        $('#shade').fadeTo(200, 0.5).queue(function () {
            $('#sendfriendbox').slideDown(600);
            $(this).dequeue();
        });

        addEvent(shade, 'click', closesendfriendmodal);
        $('html, body').animate({ scrollTop: 0 }, $(document).scrollTop() * 0.8);
    } else {
        show_lightbox(elname);
        reloadLookparts();
        $('.loader', '#bloc_add_basket').hide();
    }

    window.setTimeout( function() {
        $("#sendfriendbox #content_sendfriendbox").lionbars();
     }, 1000);

}



function closesendfriendmodal() {
    var modbox = document.getElementById('sendfriendbox');
    var shade = document.getElementById('shade');

    $('#sendfriendbox').slideUp("slow").queue(function () {
        $('#shade').fadeTo("slow", 0);
        $(this).dequeue();
        shade.style.display = 'none';
        modbox.style.display = 'none';
    });
    document.getElementById('sendfriend_alert_mail').style.display="none";
    document.getElementById('sendfriend_prodinfos').style.display="block";
    document.getElementById('sendfriend_form_to').style.display="block";
    document.getElementById('sendfriend_form_froms').style.display="block";
    document.getElementById('sendfriend_form_buttons').style.display="block";
    document.getElementById('sendfriend_form_needed').style.display="block";
    document.body.style.overflow='';
    document.getElementsByTagName("html")[0].style.overflow = "";

    delEvent(shade,'click',closesendfriendmodal);

    if ( navigator.userAgent.indexOf('MSIE') != -1 && navigator.userAgent.indexOf('6.') != -1 ) {

        var svn=document.getElementsByTagName("SELECT");
        for (a=0;a<svn.length;a++) {
            svn[a].style.visibility="visible";
        }

    }
}

function closesendfriendmodalfromconfirm() {
    var modbox = document.getElementById('sendfriendbox');
    var shade = document.getElementById('shade');
    modbox.style.marginLeft='';
    shade.style.display="none";
    modbox.style.display="none";
    document.body.style.overflow='';
    document.getElementsByTagName("html")[0].style.overflow = "";
    document.getElementById('sendfriend_prodinfos').style.display="block";
    document.getElementById('sendfriend_form_to').style.display="block";
    document.getElementById('sendfriend_form_froms').style.display="block";
    document.getElementById('sendfriend_form_buttons').style.display="block";
    document.getElementById('sendfriend_form_needed').style.display="block";
    document.getElementById('sendfriend_alert_mail').innerHTML="";
    document.getElementById('sendfriend_alert_mail').style.display="none";

    delEvent(shade,'click',closesendfriendmodal);

    if ( navigator.userAgent.indexOf('MSIE') != -1 && navigator.userAgent.indexOf('6.') != -1 ) {

        var svn=document.getElementsByTagName("SELECT");
        for (a=0;a<svn.length;a++) {
            svn[a].style.visibility="visible";
        }

    }

}

function closemodal() {

    var modbox = document.getElementById('modbox');
    var cgvbox = document.getElementById('article_fourth_box_cgv');
    var errbox = document.getElementById('errbox');
    var cmodbox = document.getElementById('cmodbox');
    var shade = document.getElementById('shade');

    if (cmodbox) {

        cmodbox.innerHTML = '';
    }

    if (modbox) {

        modbox.style.marginLeft = '';
        modbox.style.display = 'none';
    }

    if (cgvbox) {
        cgvbox.style.display = 'none';
    }

    if (shade) {

        shade.style.display = 'none';
    }

    if (errbox) {

        errbox.style.display = 'none';
    }

    document.body.style.overflow = '';
    document.getElementsByTagName("html")[0].style.overflow = '';

    if ( navigator.userAgent.indexOf('MSIE') != -1 && navigator.userAgent.indexOf('6.') != -1 ) {

        var svn=document.getElementsByTagName("SELECT");
        for (a=0;a<svn.length;a++) {
            svn[a].style.visibility="visible";
        }

    }
}

function addToBasketClick(btn)
{
    try {
        var options = JSON.parse(btn.attr('data-options'));
    } catch (e) {
        return false;
    }

    if (typeof ga != 'undefined') {
        ga('send', 'event', options.category, 'Ajout Panier', options.product_ref);
    }

    var from = '';
    var loader;
    addToBasket(options.product_id, options.suff, options.basket_id, options.prod_title_object, options.idTaille, options.lastTaille, options.idCouleur, options.lastCouleur, options.toEval, options.page_name, options.path_web, options.enable_omniture, options.is_not_produit, from, loader, options.configurator_product, options.configurator_option, options.is_reservation);
}


function waitingAddToBasket(evt, form_elm, from) {

    var id = '';
    var produit_id = form_elm.getValue('produit_id');
    var suff = form_elm.getValue('suff');
    var panier_id = form_elm.getValue('panier_id');
    var titreObjet = form_elm.getValue('titreObjet');
    var idTaille = form_elm.getValue('tailleProd');
    var lastTaille = form_elm.getValue('lastTaille');
    var idCouleur = form_elm.getValue('couleurProd');
    var lastCouleur = form_elm.getValue('lastCouleur');
    var toEval = form_elm.getValue('toEval');
    var page_name = form_elm.getValue('page_name');
    var path_web = form_elm.getValue('path_web');
    var enable_omniture = form_elm.getValue('enable_omniture');
    var is_not_produit = form_elm.getValue('is_not_produit');
    var is_lightbox_specifique = form_elm.getValue('is_lightbox_specifique');
    var loader;

    evt.preventDefault();

    if (from === undefined) {
        from = '';
    }

    if (is_not_produit == true) {
        id += '_' + produit_id;
    }

    if (this.nextElementSibling && this.nextElementSibling.classList.contains('loader')) {
        loader = $(this.nextElementSibling);

        loader.parent().addClass('loading');
    }

    window.setTimeout( function() {
        addToBasket(produit_id, suff, panier_id, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, toEval, page_name, path_web, enable_omniture, is_not_produit, from, loader, false, false);
    }, 1000);

}

function addToBasket(produit_id, suff, panier_id, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, toEval, page_name, path_web, enable_omniture, is_not_produit, from, loader, configurator_product, configurator_option, is_reservation) {

    var id = '';

    prix_id = 'prixU';

    if (from == 'basket' || is_not_produit == true) {
        id += '_' + produit_id;
        prix_id = 'prixU' + id;

        if (is_not_produit == true) {

            var code_color = $('#couleurUnique' + id).text();
            var nom_color = $('#couleurUnique' + id).text();
        }
    }

    var array_panier = newUpdatePanier(produit_id, suff, panier_id, document.getElementById(prix_id).value, path_web, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, configurator_product, configurator_option, is_reservation);

    if (configurator_option === true) {
        return true;
    }


    if (array_panier != false && is_reservation !== true) {
        if (from == 'basket') {
            location.reload();
            return false;
        }

        var type = '';

        if (array_panier[1] == 'false' || array_panier.length == 0) {
            type = 'err';
        }

        omnitureEvent = '';

        if (array_panier[1] == 1) {
            omnitureEvent = 'scOpen,scAdd'; // le premier produit ajouté au panier
        } else {
            omnitureEvent = 'scAdd'; // il y a déjà un produit au panier
        }

        if (loader) {
            loader.parent().removeClass('loading');
        } else {
            $("#bloc_btn_active" + id).attr('class', '');
            $("#bloc_btn_loader" + id).attr('class', 'f_right cache');
        }

        if (array_panier[1] > 0 && enable_omniture == true) {
            void(s.t());
            s.pageName  = "'" + page_name + "'";
            s.products  = ";" + produit_id;
            s.events    = omnitureEvent;
            void(s.t());
        }

        // Mise à jour du panier top
        var response_basket = ajax_file(path_web + 'ajax_show_basket.php');
        $("#cart_top").html(response_basket);

        $("#shad").hide();
        $("#lightbox_achat_express").removeClass('opened');

        newshowmodal(produit_id, is_not_produit);
    } else {
        if (loader) {
            loader.parent().removeClass('loading');
        } else {
            $("#bloc_btn_active" + id).attr('class', '');
            $("#bloc_btn_loader" + id).attr('class', 'f_right cache');
        }

        var response_basket = ajax_file(path_web + 'ajax_show_basket.php');
        $("#cart_top").html(response_basket);

        return true;
    }

    eval(toEval);

    if (array_panier != false) {

        if (navigator.userAgent.indexOf('MSIE') != -1 && navigator.userAgent.indexOf('6.') != -1) {

            var svn = document.getElementsByTagName("SELECT");

            for (a = 0; a < svn.length; a++) {

                svn[a].style.visibility = "hidden";
            }
        }
    }

    return false;
}
function displayOnglet( pDiv ) {

    if ( pDiv == "ongletSavoirPlus" ) {
        if ( document.getElementById('ongletCaracteristiques') )
            document.getElementById('ongletCaracteristiques').style.display ='none';
    }

    if ( pDiv == "ongletCaracteristiques" ) {
        if ( document.getElementById('ongletSavoirPlus') )
            document.getElementById('ongletSavoirPlus').style.display ='none';
    }

    if ( pDiv == "ongletGammes" ) {
        if ( document.getElementById('ongletCaracteristiques') )
            document.getElementById('ongletCaracteristiques').style.display ='none';
        if ( document.getElementById('ongletSavoirPlus') )
            document.getElementById('ongletSavoirPlus').style.display ='none';
        if ( document.getElementById('ongletAssociations') )
            document.getElementById('ongletAssociations').style.display ='none';
    }

    if ( pDiv == "ongletAssociations" ) {
        if ( document.getElementById('ongletCaracteristiques') )
            document.getElementById('ongletCaracteristiques').style.display ='none';
        if ( document.getElementById('ongletSavoirPlus') )
            document.getElementById('ongletSavoirPlus').style.display ='none';
        if ( document.getElementById('ongletGammes') )
            document.getElementById('ongletGammes').style.display ='none';
    }

    if ( document.getElementById(pDiv) )
        document.getElementById(pDiv).style.display ='block';
}

function displayZoom ( picture, product_ref, path_web, picture_medium, picture_mini, photo_path, photo_list ) {

    var so = new SWFObject(path_web + "zoom.swf", "zoom", "405", "610", "8", "#FFFFFF");
    so.addParam("wmode", "transparent");
    so.addParam("allowScriptAccess", "always");
    so.addVariable("produit_id", product_ref);
    so.addVariable("url", path_web);

    so.addVariable("photo_path_medium", picture_medium);
    so.addVariable("photo_path_mini", picture_mini);

    so.addVariable("photo_path", photo_path);

    so.addVariable("photo_list", photo_list);
    so.addVariable("img_defaut", picture);
    so.write("flashVisuel");

}

function zoom( picture, product_ref, path_web, picture_medium, picture_mini, photo_path, photo_list ) {

    displayZoom( picture, product_ref, path_web, picture_medium, picture_mini, photo_path, photo_list );

}

function updateZoomedImg(src) {

    $('.main_vis img', this).attr('src', src);
}

function zoomImg() {

    var container = $(this).closest('.itm_views');
    var thumbnail = $('.content_img_visu', container).clone();
    var main_visu = $(this).clone().removeAttr('id');
    var zoom_wrap = $('#zoom_box_wrapper');
    var shade = document.getElementById('shade');

    thumbnail.each(function () {

        var src = $('img', this).attr('src').replace('/medium/', '/large/');
        src = $('img', this).attr('src').replace('/mini/', '/large/');

        $(this).removeAttr('id');
        $('img', this).removeAttr('onclick');

        this.addEventListener('click', updateZoomedImg.bind(zoom_wrap, src));
    });

    $('.main_vis', zoom_wrap).html(main_visu);
    $('.more_vis', zoom_wrap).html(thumbnail);

    $('#shade').after($('#zoomBox')).add($('#zoomBox')).show();

    addEvent(shade,'click',closeZoom);
}

function changeVisuZoom( field, path ) {

    var elem = ( document.getElementById('nyroModalImg') )? 'nyroModalImg':'img_tmp';

    var w_tmp = $('#'+elem).width();
    var h_tmp = $('#'+elem).height();

    document.getElementById(field).innerHTML = "<div id='loader_tati' style='height:41px;z-index:1;position:relative;top:400px;text-align:center;font-size:16px;color:#CBE423;'><img src='"+path_relative_root+"img/ajaxLoader.gif' alt=\"Chargement en cours...\" width=\"25\" height=\"25\" /><p><strong>Chargement...</strong></p></div>";
    document.getElementById(field).innerHTML += "<div style='position:relative;z-index:2;top:-41px;'><img id='img_tmp' src="+path+"></div>";

    $('#img_tmp').on('load', function() {
        $('#loader_tati').remove();
        $(this).parent().css('top','0');
        $(this).width(w_tmp);
        $(this).height(h_tmp);
        $('img', '#bloc_photo_zoom').css({marginBottom : "-3px"});
    });
}

function closeZoom() {

    var elname = 'zoomBox';
    var modbox = document.getElementById(elname);
    var shade = document.getElementById('shade');
    var shade_zoom = document.getElementById('shade');
    modbox.style.display="none";
    shade_zoom.style.display="";

    delEvent(shade,'click',closeZoom);
    delEvent(shade_zoom,'click',closeZoom);
    delEvent(modbox,'click',closeZoom);

    closemodal();

}

function updateSrc(path_web,dest) {
    var tmp_string_large = document.getElementById('img_large').src;
    tmp_string = tmp_string_large.replace(/medium/,"large");

    document.getElementById('img_large').setAttribute('onclick','zoomImg(\''+tmp_string+'\',\''+path_web+'\')');
    document.getElementById('loupe').href = 'javascript:zoomImg(\''+tmp_string+'\',\''+path_web+'\');';
    $(".zoomLens").css({backgroundImage : 'url("'+tmp_string_large+'")' });

    if (dest=='vue') {

        var i = 0;
        var trouve = false;
        while (i<tab_js.length && !trouve) {

            trouve = (tab_js[i][0].substring(tab_js[i][0].lastIndexOf('/')+1)==tmp_string.substring(tmp_string.lastIndexOf('/')+1));
            i++;

        }

        cpt_img = (i-1);

    }

}

function defilImg(sens,path_web) {

    if (sens == 'gauche') {
        if (cpt_img!=0) cpt_img--;
        else cpt_img = tab_js.length-1;
    }
    else {
        if (cpt_img<tab_js.length-1) cpt_img++;
        else cpt_img = 0;
    }

    changeVisu('img_large',tab_js[cpt_img][0],tab_js[cpt_img][1]);
    updateSrc(path_web);

}

function productSendFriendForm(path_web, request_uri) {

    document.getElementById("product_sendfriend_ok").style.display = 'block';
    document.getElementById("product_sendfriend_nok").style.display = 'block';

    if ( document.getElementById('sendFriendToMails') && document.getElementById('sendFriendToMessage') && document.getElementById('sendFriendYourName') && document.getElementById('sendFriendYourMail') && document.getElementById('sendFriendCopy') ) {
        document.getElementById('sendFriendToMails').style.border="";
        document.getElementById('sendFriendToMessage').style.border="";
        document.getElementById('sendFriendYourName').style.border="";
        document.getElementById('sendFriendYourMail').style.border="";

        var token = document.getElementById('token_security_sendfriend').value;
        var captcha = document.getElementById('g-recaptcha-response').value;

        $.ajax({
            url: path_web + 'ajax_send_friend_form.php',
            type: 'POST',
            data: 'g-recaptcha-response=' + captcha + '&token_security=' + token + '&url=' + request_uri + '&to_mails=' + document.getElementById('sendFriendToMails').value + '&to_message=' + document.getElementById('sendFriendToMessage').value.replace(/\n/g,'<br>') + '&your_name=' + document.getElementById('sendFriendYourName').value + '&your_mail=' + document.getElementById('sendFriendYourMail').value + '&copy=' + document.getElementById('sendFriendCopy').checked
        }).done(function(response) {

            var content_id = 'product_sendfriend_nok',
                content = '';

            if (response.split(',')[0] == 'ok') {

                var s_adresse = response.substr(3);
                var sing = (response.split(',').length > 2) ? 'sendfriend_ok_plur' : 'sendfriend_ok_sing';

                content = '<div style="font-size:14px;font-weight:bold;font-family:Arial; color:#222;text-align:center;margin-bottom:10px;margin-top:15px;font-family: arial;">'+ajax_file(path_web+'ajax_sprintf.php?arg1='+sing)+'</div>';
                content += '<p style=\"margin-top: 0px;margin-bottom: 30px;padding:0;text-align:center;\">'+s_adresse+'</p>';

                content_id = "sendfriend_alert_mail";

                document.getElementById('product_sendfriend_nok').innerHTML = "";
                document.getElementById('sendfriend_form_to').style.display = "none";
                document.getElementById('sendfriend_form_froms').style.display = "none";
                document.getElementById('sendfriend_form_buttons').style.display = "none";
                document.forms["sendfriendform"].reset();

                $(".form_submit.loading", "#sendfriendform").removeClass("loading");
            } else {
                $('#sendFriendToMails').removeClass('inputErr');
                $('#sendFriendToMessage').removeClass('inputErr');
                $('#sendFriendYourName').removeClass('inputErr');
                $('#sendFriendYourMail').removeClass('inputErr');

                if ($('#sendFriendToMessage').val() == '')
                    $('#sendFriendToMessage').addClass('inputErr');
                if ($('#sendFriendYourName').val() == '')
                    $('#sendFriendYourName').addClass('inputErr');
                if ($('#sendFriendYourMail').val() == '')
                    $('#sendFriendYourMail').addClass('inputErr');


                content = "<img src=\""+path_relative_root+"img/picto_panneau.png\" style=\"margin-right:10px;float:left;\" /><p style=\"margin: 0px;padding: 0px; line-height: 24px;\">" + ajax_file(path_web+'ajax_sprintf.php?arg1=error_fields') + "</p>";

                if (response == '1' ) {

                    content = "<img src=\""+path_relative_root+"img/picto_panneau.png\" style=\"margin-right:10px;float:left;\" /><p style=\"margin: 0px;padding: 0px; line-height: 24px;\">" + ajax_file(path_web+'ajax_sprintf.php?arg1=sendfriend_error_no_mail_friends') + "</p>";
                    $('#sendFriendToMails').addClass('inputErr');
                    $('td.labelForm').addClass('labelErr');

                } else if (response == '2' ) {

                    content = "<img src=\""+path_relative_root+"img/picto_panneau.png\" style=\"margin-right:10px;float:left;\" /><p style=\"margin: 0px;padding: 0px; line-height: 24px;\">" + ajax_file(path_web+'ajax_sprintf.php?arg1=sendfriend_error_mail_friends_invalid') + "</p>";
                    $('#sendFriendToMails').addClass('inputErr');
                    $('td.labelForm').addClass('labelErr');

                } else if (response == '6' ) {

                    content = "<img src=\""+path_relative_root+"img/picto_panneau.png\" style=\"margin-right:10px;float:left;\" /><p style=\"margin: 0px;padding: 0px; line-height: 24px;\">" + ajax_file(path_web+'ajax_sprintf.php?arg1=sendfriend_error_no_my_mail') + "</p>";
                    $('#sendFriendYourMail').addClass('inputErr');
                    $('td.labelForm').addClass('labelErr');

                } else if (response == '7' ) {

                    content = "<img src=\""+path_relative_root+"img/picto_panneau.png\" style=\"margin-right:10px;float:left;\" /><p style=\"margin: 0px;padding: 0px; line-height: 24px;\">" + ajax_file(path_web+'ajax_sprintf.php?arg1=sendfriend_error_my_mail_invalid') + "</p>";
                    $('#sendFriendToMails').addClass('inputErr');
                    $('td.labelForm').addClass('labelErr');

                } else if (response == 'token_error' ) {

                    content = "<p class=\"txt_err_sendfriend\">" + ajax_file(path_web+'ajax_sprintf.php?arg1=error_token_security') + "</p>";

                } else if (response == '3' || response == '4' || response == '5') {
                    $('#sendFriendToMessage').addClass('inputErr');
                    $('#sendFriendYourName').addClass('inputErr');
                } else {
                    var s_adresse = response.substr(3);
                    var sing = (response.split(',').length > 2) ? 'sendfriend_no_ok_plur' : 'sendfriend_no_ok_sing';

                    content = '<div class="msg_poperror">' + Translator.translate(sing) + '</div>';
                    content_id = "sendfriend_alert_mail";
                    content +=
                        '<div class="form_submit">' +
                            '<a class="button primary" href="#" onclick="closesendfriendmodalfromconfirm()">' +
                                '<span>' + Translator.translate("ok") + '</span>' +
                            '</a>' +
                        '<div>';

                    document.getElementById('product_sendfriend_nok').innerHTML = "";
                    document.getElementById('sendfriend_form_to').style.display = "none";
                    document.getElementById('sendfriend_form_froms').style.display = "none";
                    document.getElementById('sendfriend_form_buttons').style.display = "none";

                    document.getElementById('sendFriendToMails').value = "";
                    document.getElementById('sendFriendToMessage').value = "";
                    document.getElementById('sendFriendYourName').value = "";
                    document.getElementById('sendFriendYourMail').value = "";

                    $(".form_submit.loading", "#sendfriendform").removeClass("loading");
                }
            }

            document.getElementById(content_id).innerHTML = content;
            document.getElementById(content_id).style.display = 'block';
            $('#sendfriend_form_buttons').removeClass('loading');

        });
    }

    return false;
}

function prodGetProductDisplay( id, path_web, type ) {

    if ( typeof(type) == 'undefined' ) {

        var response = ajax_file( path_web + 'ajax_get_infos_send_friend.php?product_id=' +id );
        document.getElementById('sendfriend_prodinfos').innerHTML = response;

    }
    else {

        if ( type == 'look' ) {

            $('#titre_popup').html( ajax_file( path_web + 'ajax_sprintf.php?arg1=sendfriend_title_look ' ) );
            $('#friend_form').show();
            $('#btn_popup_panier').hide();

            $('#bloc_btn_look').show();
            $('#bloc_btn_loader_look').hide();

        }else if (type == 'look_basket') {

            $('#titre_popup').html('Vous venez d\'ajouter le look suivant');
            $('#friend_form').hide();
            $('#btn_popup_panier').show();

            $('#bloc_btn_look').show();
            $('#bloc_btn_loader_look').hide();

        }
        else {

            $('#titre_popup').html('Vous venez d\'ajouter le produit suivant');
            $('#friend_form').hide();
            $('#btn_popup_panier').show();

        }

    }

    if ( navigator.userAgent.indexOf('MSIE') != -1 && navigator.userAgent.indexOf('6.') != -1 ) {

        var svn=document.getElementsByTagName("SELECT");
        for (a=0;a<svn.length;a++) {
            svn[a].style.visibility="hidden";
        }

    }

}

function show_lightbox(lightbox_contact) {

    $('#shade').fadeTo("slow", 0.8).queue(function () {

        $('#' + lightbox_contact).slideDown("slow", function () {

            $('#visu_popup_ajout_panier', this).recalImg();
        });

        $(this).dequeue();
    }).click(function () {

        close_lightbox(lightbox_contact);
    });
}

function close_lightbox(lightbox_contact) {

    $('#' + lightbox_contact).slideUp("slow").queue(function () {

        $('#shade').fadeTo("slow", 0, function () {

            $(this).css('display', 'none');
        });

        $(this).dequeue();
    });
}

function changeColorProduct ( id_color, from, prod_id ) {

    var txt_add = '';
    if ( from == 'produit' ) {
        txt_add = '_prod';
        id_select = id_color + '_prod';
        var tab = tab_color_border;
    }
    else if ( from == 'assoc' ) {
        txt_add = '_assoc_' + prod_id;
        window['id_select_assoc_' + prod_id] = id_color + '_assoc_' + prod_id;
        var tab = eval('tab_color_border_assoc_' + prod_id);
        prod_id = '_' + prod_id;
    }
    else if ( from == 'gamme' ) {
        txt_add = '_gamme_' + prod_id;
        window['id_select_gamme_' + prod_id] = id_color + '_gamme_' + prod_id;
        var tab = eval('tab_color_border_gamme_' + prod_id);
        prod_id = '_' + prod_id;
    }

    document.getElementById('couleurProd'+prod_id).value = id_color;

    var cnt_tab = tab.length;

    for(var i=0; i<cnt_tab; i++) {

        if (tab[i] == id_color) {
            if ( document.getElementById(tab[i]+txt_add) )
                document.getElementById(tab[i]+txt_add).style.border = '1px solid #FD6D0C';
        }else {
            if ( document.getElementById(tab[i]+txt_add) )
                document.getElementById(tab[i]+txt_add).style.border = '1px solid #999';
        }
    }

}

function showpopupfacebook() {

    var elname = 'popup_comment_fb';

    var actualposition = document.documentElement.scrollTop;
    var modbox = document.getElementById(elname);
    var shade = document.getElementById('shade');

    //modbox.style.position = "absolute";
        $('#shade').fadeTo("slow", 0.5).queue(function () {
            $('#popup_comment_fb').slideDown("slow");
            $(this).dequeue();
        });

    addEvent(shade,'click',closepopupfacebook);

    if ( $('.fb_comments_count').html() == '0' )
        $("#title_fb").html("SOYEZ LE PREMIER &Agrave; DONNER VOTRE AVIS")
    else
        $("#title_fb").html("VOS AVIS ("+$('.fb_comments_count').html()+")")

    window.scrollTo(0,actualposition);

}

function closepopupfacebook() {

    var elname = 'popup_comment_fb';
    var shade = document.getElementById('shade');
    var modbox = document.getElementById(elname);
    modbox.style.display="none";
    shade.style.display="none";

    delEvent(shade,'click',closepopupfacebook);

    closemodal();

}

function fbs_click( site, params ) {

    if ( params == '' ) {
        params = location.href;
    }

    switch ( site ) {
        case 'fb':
            window.open('http://www.facebook.com/share.php?u='+encodeURIComponent(params)+'&t=');
             ga('send', 'facebook', 'Like', 'http://www.facebook.com/share.php?u='+encodeURIComponent(params)+'&t=');
        break;
        case 'tw':
            window.open('https://twitter.com/share?url='+params);
            _gaq.push(['_trackSocial', 'twitter', 'Tweet', params]);
        break;
        case 'pin':
            window.open('http://pinterest.com/pin/create/button/?'+params);

            var url = params.split('&');
            url = url[0].split('=');
            url = decodeURIComponent( url[1] );
            ga('send', 'pinterest', 'Pin', url);
        break;
    }
    return false;
}

function show_video( ) {
    var video = $(".wrapper_video");
    var video_content = $(".video_content", video);
    var btn_video_produit = $('.btn_video_produit');
    $(window).scrollTop(0);
    $('#shade').fadeIn('slow', function() {
        video.css({display : 'block', opacity : 0}).animate({opacity : 1});
        if ( Modernizr.video ) {
            video_content[0].play();
        }
        $('#shade').click(stopVideoSpa);
    });

}

function stopVideoSpa() {
    var video = $(".wrapper_video");
    var btn_video_produit = $('.btn_video_produit');
    var video_content = $(".video_content", video);
    var videoFlv = $("#video_spa_swf");
    $('#shade').fadeOut('slow');

    // video.css({display : 'block', opacity : 1}).animate({opacity : 0},function() {
        video.css({display : 'none', opacity : ''});
    // });
}

function lightboxAlertStock( id, type_alert ) {
    var produit_id = $( '#produit_id' ).val();

    if ( !$("#lightbox_stock_alert").is(":visible") )
        var $form = $( '#product_selects' );
    else
        var $form = $( '#form_colisage' );


    var $input = $( '#' + id, $form ); // ID Champs Clique
    var $from = id.split( '_' )[0];
    var $hidden = $( '#' + type + 'Prod', $form );

    var $select = $( '#ligne_' + type );
    var $list_inputs = $( '.select_input', $select );
    var $list_labels = $( '.select_label', $select );

    var data = $form.serialize();
    data += '&produit_id='+produit_id;
    data += '&from=nothing';
    data += '&type_alert=' + type_alert;

    $.ajax({
        type: 'post',
        url: path_relative_root + 'ajax_lightbox_alert.php',
        data: data,
        success: function( response ) {
            try{
                response = $.parseJSON( response );
            }
            catch( e ) {
                console.error( 'parseJSON' );
                return;
            }

            if ( response.success == 'ok' ) {
                var datas = response.datas;
                var $html = $( datas.html );
                $('#lightbox_stock_alert').html($html);
            }
            else{
                $( '#btn_error' ).fadeIn();
                console.error( response.error.msg );
            }
        },
        complete: function() {
            $('#shade').hide();
            $( '#bloc_btn_loader' ).fadeOut( 300 );
            if ( !$("#lightbox_stock_alert").is(":visible") )
                openMultiShad('lightbox_stock_alert');
            $('.selectStyled').each(function() {
                $(this).selectStyled();
            });
        }
    });
}

var button_add_to_wishlist_initial_click_event;
var button_add_to_wishlist_initial_label;
function restoreWishlistButtonState() {
    $("#addToWishlistButton").html(button_add_to_wishlist_initial_label);
    $("#addToWishlistButton").removeClass("existToWishlistButton").addClass("addToWishlistButton");
    $("#addToWishlistButton").attr("onclick",button_add_to_wishlist_initial_click_event).attr("href", "#");
}

function closealertloginerror() {
    $("#alertboxlogin").slideUp(function() {
        $(this).remove();
        $('#shade').fadeOut(function() {
            $(this).remove();
        });
    });
}

function closealerterror() {
    $("#alertbox1").slideUp(function() {
        $(this).remove();
        $('#shade').fadeOut(function() {
            $(this).remove();
        });
    });
}

function wishlistRedirectLogin() {
    $.get(path_relative_root + "ajax_add_to_wishlist.php?login_required=1", function() {
        document.location.href=path_relative_root + create_link('connexion_login') + "?from=front";
    });
}

function addToWishlistShowLoader() {
    $("#bloc_btn_loader").show();
    $("#addToWishlistButtonContainer").hide();
}

function addToWishlistHideLoader() {
    $("#bloc_btn_loader").hide();
    $("#addToWishlistButtonContainer").show();
}

function addToWishlistUpdateHeader(nr) {
    $(".btn_top_wishlist .gris").html('(x' + nr + ')');

    if (nr == 0) {
        $("#basket1").show();
        $("#basket2").hide();
        $(".btn_top_wishlist").hide();
        $("#number_wishlist_top").html('(0)');
    } else {
        $("#basket2").show();
        $("#basket1").hide();
        $(".btn_top_wishlist").show();
        $("#number_wishlist_top").html('(x' + nr + ')');
    }
}

function loadProduct_Legacy( type_filter, id, type_page ) {

    var tmp_qte = $('#qteProd_' + id).val();

    if ( typeof(id) == 'undefined' ) {
        id_prod = "";
    }
    else {
        id_prod = "_"+id;
    }

    if ( typeof(type_page) == 'undefined' ) {
        type_page = 'product';
    }

    var $form = $( '#form_fiche_produit' );
    var $input = $( '#' + type_filter + 'Prod'+id_prod, $form );
    var type = type_filter;
    var $hidden = $( '#' + type_filter + 'Prod'+id_prod, $form );
    var $produit_id_new = $( '#' + type_filter + 'Prod'+id_prod).attr('data-produitid');

    var data = $form.serialize();
    data += '&produit_id_new=' + $produit_id_new;
    data += '&type=' + type_filter;
    data += '&type_page=' + type_page;

    if ( typeof(id) != 'undefined' ) {

        data += '&couleurProd=' + $( '#couleurProd'+id_prod).val();
        data += '&tailleProd=' + $( '#tailleProd'+id_prod).val();
        data += '&produit_principal=' + $( '#produit_principal'+id_prod).val();
        var produit_principal = $( '#produit_principal'+id_prod).val();

    }

    $.ajax({
        type: 'post',
        url: path_relative_root + 'ajax_get_product_regroup_ref.php',
        data: data,
        beforeSend: function() {
            $( '#bloc_btn_loader' ).show();
            $( '#btn_error' ).fadeOut( 300 );
        },
        success: function( response ) {
            try{
                response = $.parseJSON( response );
            }
            catch( e ) {
                console.error( 'parseJSON' );
                return;
            }

            if ( response.success == 'ok' ) {
                var datas = response.datas;

                if ( type_page == 'product' ) {

                    var $html_main = $( datas.html_main );
                    var $html_middle = $( datas.html_middle );
                    var $fiche = $( '#main_wrapper' );
                    var $form = $( '#wrapper_right_fiche' );
                    var replace_main = new Array(
                        '#product_name',
                        '#list_caract',
                        '#modbox .infos',
                        '.product_detail_tab_holder',
                        '.content_img_visu_wrapper',
                        '.wrapper_prix_product',
                        '#description_produit',
                        '#reference_fiche_produit',
                        '#visu_popup_ajout_panier',
                        '.restock_date',
                        '#script_zoom'
                    );
                    var replace_middle = new Array('#ligne_couleur', '#ligne_taille', '#ligne_qte', '#bloc_btn_active', '#bloc_add_alert','#addToWishlistButtonContainer','#bloc_add_basket');

                    replace_main.push( '#social_network' );
                    replace_main.push( '.fond_middle' );

                    for( var i = 0; i < replace_main.length; i++ ) {
                        var id = replace_main[i];
                        $( id, $fiche ).html( $( id, $html_main ).html() );
                    }

                    for( var i = 0; i < replace_middle.length; i++ ) {
                        var id = replace_middle[i];
                        $( id, $form ).html( $( id, $html_middle ).html() );
                    }

                    $( '#sendfriend_form_buttons', '#sendfriendbox' ).html( $( '#sendfriendbox #sendfriend_form_buttons', $html_main ).html() );

                    $( '#produit_id', '#form_fiche_produit' ).val( $( '#produit_id', $html_middle).val() );
                    $( '#produit_principal', '#form_fiche_produit' ).val( $( '#produit_principal', $html_middle).val() );
                    $( '#produit_lot_nb', '#form_fiche_produit' ).val( $( '#produit_lot_nb', $html_middle).val() );

                    $( '#hdn_product_id', '#form_fiche_produit' ).val( $( '#hdn_product_id', $html_middle).val() );
                    $( '#hdn_is_model', '#form_fiche_produit' ).val( $( '#hdn_is_model', $html_middle).val() );

                    var heads      = $("[data-tab-head]");
                    var containers = $("[data-tab-container]");

                    var activateTab = function(event) {
                        event.preventDefault();

                        var element        = $(this);
                        var tab_number     = element.attr("data-tab");
                        var head_container = element.parent();
                        var container_name, container, scrollpane;

                        head_container.addClass("current").siblings().removeClass("current");
                        container_name = head_container.parent().attr("data-tab-head");
                        container      = containers.filter("[data-tab-container='" + container_name + "']");
                        scrollpane     = container.find("[data-tab]").hide().filter("[data-tab='" + tab_number + "']").show().find('.scroll-pane');

                        if (scrollpane.size()) {
                            scrollpane.jScrollPane();
                        }
                    };

                    heads.each(function() {
                        var head = $(this);
                        var children = head.children();
                        children.removeClass("current").filter(":first").addClass("current");
                        children.find('[data-tab]').click(activateTab);
                    });

                    $('.img_gamme').each(function() {
                        $(this).recalImg();
                    });

                    var string_params = '';
                    if ( $("#produit_lot_nb").val() != undefined )
                        string_params += '&produit_lot_nb=' + $("#produit_lot_nb").val();

                    // RECHARGEMENT QTE
                    var qte = ajax_file(path_relative_root + 'ajax_liste_qte.php?idprod=' + datas.product.id + '&idcouleur=' + datas.product.color.id + '&idtaille=' + datas.product.size.id + string_params);
                    $('#liste_qte').html(qte);

                    // WISHLIST
                    checkProductInWishlist();
                    $("#addToWishlistButton").click(function() { addToWishlist(); });

                } else if (type_page == 'look') {

                    var $html_main = $(datas.main_content);

                    $('#ref_look_' + produit_principal).html($('#ref_look_' + produit_principal, $html_main).html());
                    $('#look_add_lightbox_' + produit_principal).html($('#look_add_lightbox_' + produit_principal, $html_main).html());

                    calculTotal('slide_look_detail');
                    $('#itm_thumb_list').empty();
                    var thumbnails = loadPrevisu();
                    parseThumbList(thumbnails);

                    var $id_current = id_prod.substring(id_prod.lastIndexOf("_") + 1);
                    var i = array_look.indexOf(parseInt($id_current));

                    if (i != -1) {

                        array_look.splice(i, 1);
                    }

                    // Une fois les informations chargés on vas mettre à jour le select des quantités avec le nouveau produit choisie
                    showQteListeGamme('liste_qte_' + datas.product.id,  datas.product.id, path_relative_root);

                }

                // RECHARGEMENT STYLE INPUT
                $('.selectStyled').each(function() {
                    $(this).selectStyled();
                });

                Custom.init();
            }else{
                $( '#btn_error' ).fadeIn();
            }
        },
        complete: function() {
            $( '#bloc_btn_loader' ).fadeOut( 300 );
        }
    });
}

var look_add = false;

function addLook(loader) {

    $(loader).fadeIn(200);

    look_add = true;

    calculTotal('slide_look_detail', true);
}

function calculTotal(id, process_look) {

    var $form = $(document.getElementById(id));
    var data = $form.serialize();

    $.ajax({
        type : 'post',
        url : path_relative_root + 'ajax_calcul_total.php',
        data : data,
        success : function (response) {

            try {

                response = JSON.parse(response);
            } catch (e) {

                console.error('parseJSON');

                return;
            }

            if (response.success == 'ok') {

                var datas = response.datas;
                var total = datas.total;

                $('#look_total', '#look_info').val(total);
                $('.price_total', '#look_info').html(datas.total_format);

                if (process_look) {

                    if (total > 0) {

                        if (!addToCartTmpl) {

                            addToCartTmpl = document.getElementById('modbox').innerHTML.trim();

                            Mustache.parse(addToCartTmpl);
                        }

                        buildLookAddedModbox(false);
                        processlook();
                    } else {
                        var $alertbox = $( '#alertbox1' );
                        res = ajax_file(path_relative_root+'ajax_sprintf.php?arg1=js_error_quantity');
                        html_err = '<div class="txt_alert"></div><div class="rosette"></div>';
                        html_err += '<div class="wrapper_btn"><a onclick="closeMultiShad();" href="#" class="btn_alert">OK</a></div>';

                        if ($alertbox.length == 0) {
                            $alertbox = $('<div id="alertbox1">'+ html_err +'</div>');
                            $('body').append($alertbox.hide());
                        }

                        $alertbox.find('.txt_alert').html(res);
                        openMultiShad('alertbox1', 'fixed');
                        $('#bloc_btn_look').show();
                        $('#bloc_btn_loader_look').hide();

                        if ($('.loader', '#bloc_add_basket').length > 0)  {
                            $('.loader', '#bloc_add_basket').hide();
                        }
                    }
                }
            }
        }
    });
}

function loadPrevisu() {

    var cpt = 0,
        $array_product_previsu = [];

    $('.array_produit_total').map(function () {

        var $current_product = $(this).val();
        var $current_product_qte = $('#qteProd_' + $current_product).val();

        if ($current_product_qte > 0) {

            $array_product_previsu[cpt] = [];

            $array_product_previsu[cpt]['id'] = $current_product;
            $array_product_previsu[cpt]['img'] = $('#img_produit_' + $current_product).attr('src');
            $array_product_previsu[cpt]['price'] = $('#price_' + $current_product).html();
            $array_product_previsu[cpt]['qte'] = $current_product_qte;

            cpt++;
        }
    }).get();

    return $array_product_previsu;
}

function processlook() {
    $('.submit_panier').each(function () {
        var $elem = $(this).attr('id');
        var $id = $elem.substring($elem.lastIndexOf("_") + 1);
        var $val_item = $("#qteProd_" + $id).val();

        if ($val_item > 0) {
            $("#look_add_lightbox_" + $id).show();
            $(this).click();
        } else {
            $("#look_add_lightbox_" + $id).hide();
        }
    });

    setTimeout(showsendfriendmodal, 1000, 0, path_relative_root, 'look_basket');
}

function addLookToWishList() {
    // On va checker si tous les champs ont été selectionnés
    var length_look = array_look.length;
    var error = false;
    var i = 0;
    var j = 0;
    var color_id= [];
    var size_id= [];
    var need_color_id = [];
    var need_size_id = [];$("#tailleProd").length != 0;
    var flagloader = true;
    while( i < length_look && !error ) {

        error = (
            (document.getElementById('tailleProd_'+array_look[i]) && document.getElementById('tailleProd_'+array_look[i]).value == '')
            || (document.getElementById('couleurProd_'+array_look[i]) && document.getElementById('couleurProd_'+array_look[i]).value == '')
        );

        // on ne prend pas en compte la quantite de produit pour la wish list document.getElementById('qteProd_'+array_look[i])
        i++;
    }

    if ( error ) {
        alert('Vous n\'avez pas sélectionné tous les champs');
    } else {
        while(j < length_look) {
            color_id[j] = ( $("#couleurProd_"+array_look[j]).val() != undefined )? $("#couleurProd_"+array_look[j]).val():0;
            size_id[j] = ( $("#tailleProd_"+array_look[j]).val() != undefined )? $("#tailleProd_"+array_look[j]).val():0;
            need_color_id[j] = $("#couleurProd_"+array_look[j]).length != 0;
            need_size_id[j] = $("#tailleProd_"+array_look[j]).length != 0;
            j++;
        }

        var $shade = $('#shade');
        var $alertbox = $( '#alertbox1' );
        var $alertboxLogin = $( '#alertboxlogin' );
        addToWishlistShowLoader();
        var data = {}
        data.product_id = array_look;
        data.size_id = size_id;
        data.color_id = color_id;
        data.need_size_id = need_size_id;
        data.need_color_id = need_color_id;

        has_errors = false;
        var html = '<div class="txt_alert"><span id="label_alert"></span></div><div class="rosette"></div>';
            html += '<div class="wrapper_btn"><a onclick="closealerterror();" href="javascript:;" class="btn_alert">Fermer</a></div>';
        if ($alertbox.length == 0) {
            $alertbox = $('<div id="alertbox1">' + html + '</div>');
        }
        var $alertBox;
        var expressBuy = $("#lightbox_achat_express").length != 0;
        if (document.getElementById('lightbox_achat_express'))
            from_range = true;
        else
            from_range = false;

            $.post(
                path_relative_root +'ajax_add_look_to_wishlist.php?time=' + new Date().getTime(),
                data,
                function(resp) {
                    if (resp.error) {
                        if ($alertboxLogin.length == 0) {
                            $alertboxLogin = $('<div id="alertboxlogin">' + html + '</div>');
                        }
                        if (resp.message == "login_required") {
                            $alertboxLogin.find(".txt_alert").html(resp.result.error_message);

                            $alertboxLogin.find(".wrapper_btn").html('<a class="btn_connect f_right" href="#" onclick="wishlistRedirectLogin();">' + resp.result.btn_connexion + '<span></span></a> <a class="btn_close f_left" href="#" onclick="closealertloginerror();">' + resp.result.btn_annuler + '</a><div class="c_both"></div>');
                            $(document).scrollTop(0);
                            $alertBox = $alertboxLogin;
                        } else if (resp.message == "error") {
                            $alertBox = $alertbox;
                            $alertBox.find("#label_alert").html(resp.result.error_message);
                            $(document).scrollTop(0);
                        } else {
                            flagloader = false;
                        }

                        $shade.appendTo( 'body' ).show();
                        $alertBox.appendTo( 'body' ).show();
                        var shade = document.getElementById('shade');
                        addEvent(shade,'click',closeMultiShad);
                    } else {
                        $("#addToWishlistButton").html(resp.result.button_label);
                        $("#addToWishlistButton").removeClass("addToWishlistButton").addClass("existToWishlistButton");
                        $("#addToWishlistButton").parent().addClass('sendToWishlist');
                        $("#addToWishlistButton").attr("onclick","").attr("href", path_relative_root + create_link('client_index') + '#wl');
                        //wishlist.push(resp.result.wishlist_row_array);
                        addToWishlistUpdateHeader(resp.result.wishlist_size);
                    }

                    if (flagloader) {
                        addToWishlistHideLoader();
                    }
                }
            );

    }

}

// popup PDV
function showpdv() {

    var shade, modbox, contents;

    $.ajax({
        type : 'post',
        url : path_relative_root + 'ajax-distributeurs.php',
        success : function (res) {

            if (res) {

                shade = document.getElementById('shade');
                modbox = document.getElementById('pdvBox');

                contents = $('<div />').html(res);

                // Make sure modbox will show on top of shade
                $(shade).after(modbox);

                // Inject store locator into modbox
                $('.store_locator', modbox).html($('.storeloc_form', contents).add($('.liste_recherche_distributeur', contents)));

                initialize();

                // Show modbox and make sure closing routine won't fire more than once
                $(shade).off('click', closepdv).fadeIn(200, function () {

                    $(modbox).slideDown(600);
                    $(shade).on('click', closepdv);

                    // Init locator
                    if (navigator.geolocation) {
                        var crd = navigator.geolocation.getCurrentPosition(rechercheProximite);
                    }
                });
            }
        }
    });
}

function closepdv() {

    var shade = document.getElementById('shade');
    var modbox = document.getElementById('pdvBox');

    // Hide modbox and shade and destroy shade's click event
    $(modbox).slideUp(600, function () {

        $(shade).off('click', closepdv).stop(true, true).fadeOut(200);
    });
}

function removeThumb(id) {

    var select = document.getElementById('qteProd_' + id);

    this.className += ' closing';

    window.setTimeout(function () {

        select.value = parseFloat(select.value) - 1;

        $(select).change();
    }, 160);
}

function createThumb(data) {

    var template = document.getElementById('thumb_tpe').innerHTML,
        rendered = Mustache.render(template, data),
        new_item = document.createElement('li');

    new_item.className = 'itm_thumb';
    new_item.innerHTML = rendered;

    document.getElementById('itm_thumb_list').appendChild(new_item);
}

function parseThumbList(list) {

    var thumb, qte, i;

    for (thumb in list) {

        if (list.hasOwnProperty(thumb)) {

            thumb = list[thumb];
            qte   = thumb.qte;

            delete thumb.qte;

            for (i = 0; i < qte; i += 1) {

                createThumb(thumb);
            }
        }
    }
}

function createCookie(name,value,days) {
    if (days) {
        var date = new Date();
        date.setTime(date.getTime()+(days*24*60*60*1000));
        var expires = "; expires="+date.toGMTString();
    } else {
        var expires = "";
    }

    document.cookie = name+"="+value+expires+"; path=/";
}

function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');

    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }

    return null;
}

function changeNbPassengers() {

    var nbPassagers = $('#reservation_nb_passagers').val(),
        nbPassagersTotal = $('#nb_passager').val();

    for( var i=1; i <= nbPassagersTotal; i++ ) {

        if (nbPassagers < i) {

            $('#passengerInfos_' + i).slideUp();
        } else{

            $('#passengerInfos_' + i).slideDown();
        }
    }
}

var reservationDate = true;

function giftAndReservation(exeperienceType) {
    if (exeperienceType == 'reservation') {
        reservationDate = true;
    } else {
        reservationDate = false;
    }
}

function checkreservation(special_reservation) {
    var error = false;
    var date_now = new Date();

    if (special_reservation == '1' && special_reservation != 'undefined') {
        return false;
    }

    // Check Reservation Date
    if ($('#reservation_date').val() == '' && reservationDate) {
        $('#reservation_date').addClass('error');
        error = true;
    } else {
        var reservation_date = $('#reservation_date').val().split('/');

        if ( (reservation_date[0] == '' || reservation_date[1] == '' || reservation_date[2] == '') && reservationDate ) {
            $('#reservation_date').addClass('error');
            error = true;
        } else {
            var date_selected = new Date(reservation_date[2] + '-' + reservation_date[1] + '-' + reservation_date[0]);

            if (date_selected < date_now && reservationDate) {
                $('#reservation_date').val('');
                $('#reservation_date').addClass('error');
                error = true;
            } else {
                $('#reservation_date').removeClass('error');
            }
        }
    }

    // Check Reservation Passagers
    var nb_passenger = $('#reservation_nb_passagers').val();

    for (var i = 1; i <= nb_passenger; i++) {
        $('#reservation_nom_' + i).removeClass('error');
        $('#reservation_prenom_' + i).removeClass('error');
        $('#reservation_email_' + i).removeClass('error');

        if ($('#reservation_nom_' + i).val() == '') {
            $('#reservation_nom_' + i).addClass('error');
            error = true;
        }

        if ($('#reservation_prenom_' + i).val() == '') {
            $('#reservation_prenom_' + i).addClass('error');
            error = true;
        }

        if ($('#reservation_email_' + i).val() == '') {
            $('#reservation_email_' + i).addClass('error');
            error = true;
        } else {
            var mail_rule = new RegExp("^[a-z0-9_-]+([.]?[a-z0-9_-]{1,})*@([a-z0-9_-]{2,}[.])+[a-z]{2,4}$","gi");
            if (mail_rule.exec($('#reservation_email_' + i).val()) == null) {
                $('#reservation_email_' + i).addClass('error');
                error = true;
            }
        }
    }
    return error;
}

function showsendfriendmodal( product_id, path_web, type ) {
    var elname = type === "look_basket" ? "modbox" : "sendfriendbox";
    var actualposition = document.documentElement.scrollTop;

    if ( type !== 'look_basket' ) {
        var psnok = document.getElementById( 'product_sendfriend_nok' );
        var psfok = document.getElementById( 'product_sendfriend_ok' );
        if ( psnok ) {
            psnok.innerHTML = '';
            psnok.style.display = 'none';
        }
        if ( psfok ) {
            psfok.innerHTML = '';
            psfok.style.display = 'none';
        }

        prodGetProductDisplay( product_id, path_web, type );

        openMultiShad( "sendfriendbox" );
    } else {
        show_lightbox( elname );
        reloadLookparts();
        $( ".loader", "#bloc_add_basket" ).hide();
    }
}