// creer l'instanciation de l'objet XHR qu'inporte le navigateur
function getXMLHttpRequest() {

    var xhr = null;

    if (window.XMLHttpRequest || window.ActiveXObject) {
        if (window.ActiveXObject) {
            var tableau_xhr_activeX = [
                'Msxml2.XMLHTTP.6.0',
                'Msxml2.XMLHTTP.3.0',
                'Msxml2.XMLHTTP',
                'Microsoft.XMLHTTP'
            ];

            for (var value in tableau_xhr_activeX) {
                try {
                    xhr = new ActiveXObject(tableau_xhr_activeX[value]);
                    break;
                } catch(error) {};
            };

        } else {
            xhr = new XMLHttpRequest();
        };

    } else {
        return null;
    };
    return xhr;
};

// Permet le non rafraichissement d'une page en exploitant l'objet XHR
function ajax_file(fichier) {
     if(window.XMLHttpRequest) // FIREFOX-
          xhr_object = new XMLHttpRequest();
     else if(window.ActiveXObject) // IE
          xhr_object = new ActiveXObject("Microsoft.XMLHTTP");
     else
          return(false);
     xhr_object.open("GET", fichier, false);
     xhr_object.send(null);
     if(xhr_object.readyState == 4) return(xhr_object.responseText);
     else return(false);
}

function ajax_file_post(url, params) {
    if(window.XMLHttpRequest) // FIREFOX-
        xhr_object = new XMLHttpRequest();
    else if(window.ActiveXObject) // IE
        xhr_object = new ActiveXObject("Microsoft.XMLHTTP");

    xhr_object.open("POST", url, false);
    xhr_object.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    xhr_object.send(params);

    if(xhr_object.readyState == 4 && xhr_object.status == 200) {
       return xhr_object.responseText;
    }
}

function ajax_file_XML(fichier) {
     if(window.XMLHttpRequest) // FIREFOX-
          xhr_object = new XMLHttpRequest();
     else if(window.ActiveXObject) // IE
          xhr_object = new ActiveXObject("Microsoft.XMLHTTP");
     else
          return(false);
     xhr_object.open("GET", fichier, false);
     xhr_object.send(null);
     if(xhr_object.readyState == 4) return(xhr_object.responseXML);
     else return(false);
}

// Affichage du bloc qui gère le numero de retour
function showNumRetourEtiquette(idOrder, multiTransp, parentPath, idOrderTransp, idTransporteur) {

    // ELEMENT DU DIV POPUP
    var numDIVObjet = document.getElementById('popup_numero_retour');

    //Test pour vérifier si il s'agit d'une commande multitransporteur
    if(multiTransp == 1){
        var numObjet = document.getElementById('num_retourDIV_' + idTransporteur);
        var divPrintPdfObjet = document.getElementById('printPdf_' + idTransporteur);
    }
    else{
        var divPrintPdfObjet = document.getElementById('printPdf');
        var numObjet = document.getElementById('num_retourDIV');
    }
    var numDiv_coli = document.getElementById('colissimo');
    var numDiv_mory = document.getElementById('mory');

    // ELEMENT DU BLOC DANS LE DETAIL
    if (multiTransp == 1){
        var divCreateNumRetourObjet = document.getElementById('createNumRetour_' + idTransporteur);
        var numRetourObjet          = document.getElementById('num_retour_' + idTransporteur);
    }else{
        var divCreateNumRetourObjet = document.getElementById('createNumRetour');
        var numRetourObjet          = document.getElementById('num_retour');
    }

    var numRetour = '';
    switch (idTransporteur){
        case 1:
            if ( numDiv_coli ) numDiv_coli.style.display = 'block';
            if ( numDiv_mory ) numDiv_mory.style.display = 'none';
            break;
        case 2:
        case 4:
        case 5:
        case 21:
        case 25:
            if ( numDiv_coli ) numDiv_coli.style.display = 'none';
            if ( numDiv_mory ) numDiv_mory.style.display = 'block';
            break;
    }

    // expression reguliere qui servira au decoupage de la reponse
    if ( typeof(idOrderTransp) != 'undefined' )
        var numRetour = ajax_file( parentPath + 'ajax_num_etiquette.php?idOrder=' + idOrder + '&multiTransp=' + multiTransp + '&idOrderTransp=' + idOrderTransp);
    else
        var numRetour = ajax_file( parentPath + 'ajax_num_etiquette.php?idOrder=' + idOrder + '&multiTransp=' + multiTransp);

    // Securité : la commande n'appartient pas au client connecté
    if (numRetour!='false'){

        numDIVObjet.style.display = 'block';
        numObjet.innerHTML = numRetour;

        numRetourObjet.innerHTML = numRetour;
        divCreateNumRetourObjet.style.display = 'none';
        divPrintPdfObjet.style.display = 'block';

        document.getElementById('shad').style.display = 'block';
    }
}

function createEtiquetteRetour(idOrder, parentPath, multiTransp){
    //Test pour vérifier si il s'agit d'une commande multitransporteur
    if(multiTransp == 1){
        var choixRetourDivObjet = document.getElementById('choixRetourDiv_');
        var choixRetourObjet = document.getElementById('choixRetour_');
    }else{
        var choixRetourDivObjet = document.getElementById('choixRetourDiv');
        var choixRetourObjet = document.getElementById('choixRetour');
    }
    var choix = 0;

    if (choixRetourObjet.value != '0')
        choix = choixRetourObjet.value;
    else
        choix = choixRetourDivObjet.value;

    switch (choix){
        case '1':
            var txtRaison = 'Droits de rétractation';
            break;
        case '2':
            var txtRaison = 'Article non conforme à la commande';
            break;
        case '3':
            var txtRaison = 'Article Cassé / Défectueux';
            break;
        case '4':
            var txtRaison = 'Probléme de Taille / Qualité';
            break;
        case '5':
            var txtRaison = 'Autres';
            break;
    }

    var response = ajax_file( parentPath + 'ajax_create_etiquette.php?idOrder=' + idOrder + '&choix=' + txtRaison + '&numChoix=' + choix +'&multiTransp=' + multiTransp);
    if ( response.substr(0,5) == 'false' ){
        alert( ajax_file( parentPath + 'ajax_sprintf.php?arg1=js_error_return_label ' ) );
    }else{

        var listeRaisonBlocObjet = document.getElementById('liste_motif_retour');
        var btnPrintPdfBlocObjet = document.getElementById('btnPrintPdf');


        if (txtRaison == "Autres"){
            listeRaisonBlocObjet.innerHTML = '<u>Raison du retour</u> : ' + txtRaison + '<input type="hidden" id="choixRetour" value="'+choix+'">';
        }
        else
        {
            listeRaisonBlocObjet.innerHTML = txtRaison + '<input type="hidden" id="choixRetour" value="'+choix+'">';
        }

        btnPrintPdfBlocObjet.style.display = 'block';

        document.getElementById('shad').style.display = 'none';
        document.getElementById('popup_numero_retour').style.display = 'none';

        window.open(response);
    }

}

// Affiche la liste des sous categories d'un produit
function showSousCategOptionListe(objCateg, objSousCategId, objetId, idSousCateg ) {

    var optionSousCateg = document.getElementById(objSousCategId);
    var optionObjet = document.getElementById(objetId);

    if ( objCateg.value != 'non_attribue' ){


        var response = ajax_file('../ajax/ajax_sousCateg_optionliste.php?idCateg=' + objCateg.value
                                 + '&index=' + objSousCategId.split('_')[1] + '&idSousCateg=' + idSousCateg);

        optionSousCateg.style.display = 'block';
        optionObjet.style.display = 'none';
        if (response == '') { optionSousCateg.innerHTML = ''; optionObjet.innerHTML = ''; }
        else { optionSousCateg.innerHTML = response; optionObjet.innerHTML = ''; }

    }else{
        optionSousCateg.innerHTML = '';
        optionObjet.innerHTML = '';
    }
}
// --------------------------------------------------------------------------------

// Affiche la liste des objets d'un produit
function showObjetOptionListe(objSousCateg,objetId, idObjet) {
    var optionObjet = document.getElementById(objetId);
    var response = ajax_file('../ajax/ajax_objet_optionliste.php?idSousCateg=' + objSousCateg.value
                             + '&index=' + objetId.split('_')[1] + '&idObjet=' + idObjet);
    optionObjet.style.display = 'block';
    if (response)
        optionObjet.innerHTML = response;
    else
        optionObjet.innerHTML = '';

}
// --------------------------------------------------------------------------------

// Transformation XML en tableau
function traiteXmlQte(xmlDoc) {
  var options = xmlDoc.getElementsByTagName('option');
  var optionsListe = new Array();
  for (var i=0; i < options.length; ++i) {
    var optionsListe2 = new Array();

    var id      = options.item(i).getElementsByTagName('id');
    var label   = options.item(i).getElementsByTagName('label');
    var color   = options.item(i).getElementsByTagName('color');
    optionsListe2.push(id.item(0).firstChild.data,label.item(0).firstChild.data,color.item(0).firstChild.data);
    optionsListe.push(optionsListe2);
  }
  return optionsListe;
}


// Affiche la liste des quantites d'un produit (HTML Tag select)
function showQteListe(elementID, idProduit, parentPath, suff){

    var couleurs_id = 'couleurProd';
    var tailles_id  = 'tailleProd';

    if (elementID != 'liste_qte')
    {
        couleurs_id += '_'+idProduit;
        tailles_id  += '_'+idProduit;
    }

    var listQte = $('#'+elementID);
    var alternative_listQte = $('#liste_qte_spe');

    if (listQte.length || alternative_listQte.length)
    {
        if (listQte.length)
        {
            listQte.html('<table border="0" cellpadding="0" cellspacing="0" style="padding-left:10px;margin-top:-4px;"><tr><td style="font-size:11px;color:#dedede" align="right"><img src="' + parentPath + 'img/loader.gif" width="20" height="20" />Chargement...</td></tr></table>');

            var couleurs = ( ($('#'+couleurs_id).length) ? $('#'+couleurs_id).val() : 'none' );
            var tailles = ( ($('#'+tailles_id).length) ? $('#'+tailles_id).val() : 'none' );
        }
        else
        {
            alternative_listQte.html(listQte.html());

            var couleurs = 'none';
            var tailles = 'none';
        }

        var response = ajax_file(parentPath + 'ajax_liste_qte.php?idprod=' + idProduit + '&idcouleur=' + couleurs + '&idtaille=' + tailles + '&produit_lot_nb=' + $("#produit_lot_nb").val() + '&suff=' + (suff || ''));

        if (response)
        {
            if (listQte.length)
            {
                listQte.html(response);
            }
            else
            {
                alternative_listQte.html(response);
            }

            $('.selectStyled').each(function(){
                $(this).selectStyled();
            });

            var select_stock = $('#qteProd');
            var regexp_epuise = "/Stock /";
            var txt_stock = $('#qteProd>option:selected').text();

            if ( select_stock.length > 0 && txt_stock.match(regexp_epuise) != null ) {

                $("#add_alert").show();
                $("#add_basket").hide();

            }
            else {

                $("#add_alert").hide();
                $("#add_basket").show();

            }

        }

    }
}

function showQteListeGamme(elementID, idProduit,parentPath){

    var listQte = document.getElementById(elementID);
    var alternative_listQte = document.getElementById('liste_qte_spe_'+idProduit);

    if (listQte || alternative_listQte)
    {
        if (listQte)
        {
            var tailles = document.getElementById('sizeProd_'+idProduit) == undefined ? 0 : document.getElementById('sizeProd_'+idProduit).value;
            var couleurs = document.getElementById('couleurProd_'+idProduit) == undefined ? 0 : document.getElementById('couleurProd_'+idProduit).value;
            
            listQte.innerHTML = '<table border="0" cellpadding="0" cellspacing="0" style="padding-left:10px;"><tr><td style="font-size:11px;"><img src="' + parentPath + 'img/sablier.gif" />Chargement...</td></tr></table>';
        }
        else
        {
            var couleurs = 'none';
            var tailles = 'none';
            alternative_listQte.innerHTML = '<table border="0" cellpadding="0" cellspacing="0" style="padding-left:10px;"><tr><td style="font-size:11px;"><img src="' + parentPath + 'img/sablier.gif" />Chargement...</td></tr></table>';
        }

        var string_params = '';
        if ( $("#produit_lot_nb_"+idProduit).val() != undefined )
            string_params += '&produit_lot_nb=' + $("#produit_lot_nb_"+idProduit).val();

        if ( $("#qte_default_"+idProduit).val() != undefined )
            string_params += '&qte_default=' + $("#qte_default_"+idProduit).val();
        
        var response = ajax_file(parentPath + 'ajax_liste_qte_alerte.php?idprod=' + idProduit + '&idcouleur=' + couleurs + '&idtaille=' + tailles + string_params);

        if (response)
        {
            if (listQte) listQte.innerHTML = response;
            else alternative_listQte.innerHTML = response;

            $('.selectStyled').each(function(){
                $(this).selectStyled();
            });
        }
    }
}

function showQteListeAssoc(elementID, idProduit, parentPath) {

    var listQte = document.getElementById(elementID);
    var alternative_listQte = document.getElementById('liste_qte_spe_'+idProduit);
    listQte.innerHTML = '<table border="0" cellpadding="0" cellspacing="0" style="padding-left:10px;"><tr><td style="font-size:11px;"><img src="' + parentPath + 'img/sablier.gif" />Chargement...</td></tr></table>';
    var couleurs = document.getElementById('couleurProdAssoc_'+idProduit);
    var tailles = document.getElementById('tailleProdAssoc_'+idProduit);
    if (listQte || alternative_listQte) {

        if (listQte){
            var couleurs = document.getElementById('couleurProd_'+idProduit).value;
            var tailles = document.getElementById('tailleProd_'+idProduit).value;
            listQte.innerHTML = '<table border="0" cellpadding="0" cellspacing="0" style="padding-left:10px;"><tr><td style="font-size:11px;"><img src="' + parentPath + 'img/sablier.gif" />Chargement...</td></tr></table>';
        }
        else{
            var couleurs = 'none';
            var tailles = 'none';
            alternative_listQte.innerHTML = '<table border="0" cellpadding="0" cellspacing="0" style="padding-left:10px;"><tr><td style="font-size:11px;"><img src="' + parentPath + 'img/sablier.gif" />Chargement...</td></tr></table>';
        }

        //if (couleurs.value != "" && tailles.value != "") {
            var response = ajax_file(parentPath + 'ajax_liste_qte_alerte.php?idprod=' + idProduit + '&idcouleur=' + couleurs.value + '&idtaille=' + tailles.value);
            if (response) {
                // On exécute deux fois car replace ne remplace qu'une seule fois alors que nous avons deux occurences
                response = response.replace( 'qteProd_', 'qteProdAssoc_' );
                response = response.replace( 'qteProd_', 'qteProdAssoc_' );
                if (listQte) listQte.innerHTML = response;
                else alternative_listQte.innerHTML = response;

            }
        //}
        //else
    }
}

// Verifie si le produit ou la commande existe
function verif_base(elementID, val, parentPath) {
    var mess = document.getElementById(elementID);
    if (elementID=='mess_num_commande') var variable = 'numCommande';
    else var variable = 'refProduit';

    if (mess) {
        var response = ajax_file(parentPath + 'ajax_verif_contact.php?'+variable+'='+ val );
        responce = $.parseJSON(response);
        var result = responce[0];
        var response = responce[1];
        if (response) {
            mess.innerHTML = response;
            var nom_label = document.getElementById('contactNomLabel');
            var td_img = document.getElementById('preview_tof');

            if (variable=='refProduit') {
                if (!document.getElementById('erreur')) {
                    var img_name = document.getElementById("img_name").value;
                    var img_rep = document.getElementById("dir").value;
                    var img_type = document.getElementById("image_type").value;
                    var td = document.createElement('td');
                    td.setAttribute('id','preview_tof');
                    if (document.all){
                        td.style.setAttribute('cssText','padding-left:45px;');
                        if (img_type=='vert') td.style.setAttribute('cssText','padding-left:45px;vertical-align:top;padding-top:7px;');
                    }
                    else{
                        td.setAttribute('style','padding-left:45px;');
                        if (img_type=='vert') td.setAttribute('style','padding-left:45px;vertical-align:top;padding-top:7px;');
                    }
                    td.rowSpan=5;

                    var img = document.createElement('img');
                    var dest_img = img_rep+img_name+'.jpg';
                    img.setAttribute('src', dest_img);
                    img.setAttribute('id',img_name);

                    if (img_type=='hor') img.setAttribute('class','cadre_photo_hor');
                    else img.setAttribute('class','cadre_photo_vert');

                    td.appendChild(img);
                    if (!td_img) nom_label.parentNode.parentNode.appendChild(td);
                }
                else
                {
                    var nom_label = document.getElementById('contactNomLabel');
                    if (td_img) nom_label.parentNode.parentNode.removeChild(td_img);
                }
            }
        }
        else mess.innerHTML = "";
    }
}

function showTailleListeGamme(elementID, idProduit,parentPath, tailleProd){
    var eltaille = document.getElementById( elementID );

    if (eltaille)
    {
        var response = ajax_file(parentPath + 'ajax_liste_taille.php?idprod=' + idProduit + '&tailleprod = ' + tailleProd);

        if (response)
        {
            eltaille.innerHTML = response;
        }
    }
}

function showTailleListeAssoc(elementID, idProduit, parentPath, tailleProd) {

    var eltaille = document.getElementById( elementID );

    if (eltaille) {
        var response = ajax_file(parentPath + 'ajax_liste_taille.php?idprod=' + idProduit + '&tailleprod = ' + tailleProd);

        if ( response ) {

            response = response.replace('tailleProd_', 'tailleProdAssoc_');
            response = response.replace('tailleProd_', 'tailleProdAssoc_');
            response = response.replace('liste_qte_', 'liste_qte_assoc_');
            response = response.replace('showQteListeGamme', 'showQteListeAssoc');
            eltaille.innerHTML = response;
        }
    }
}


function checkStocksProduct() {
    var id_product_element = document.getElementById('produit_id');

    if (id_product_element) {
        var id_product = id_product_element.value;

        var response = ajax_file(parentPath + 'ajax/ajax_check_stock.php?id_product='+id_product);

        if (response && response < 1) {

            alert(ajax_file(parentPath+'ajax_sprintf.php?arg1=js_error_no_stock'));
            return false;

        }

        return true;
    }
}

// Classe les categories
function ajax_classer(){
    var sortable1 = Sortable.serialize('list1');
    var sortable2 = Sortable.serialize('list2');
    var response = ajax_file('ajax/ajax_classer_categ.php?' + sortable1 + '&' + sortable2);

    if (response){
        var message = document.getElementById('messFinish');
        message.style.display = "block";
        Effect.Pulsate(message);
    }

}

// Affiche la liste des auantites d'un produit (HTML Tag select)
function showQteListePanier(elementID, idProduit, i, paniervirtuelId) {

    var listQtePanier = document.getElementById(elementID);

    var couleurs = document.getElementById('couleurProd'+i);
    var tailles = document.getElementById('tailleProd'+i);

    var idCouleur = 0;
    var idTaille = 0;

    if (couleurs) idCouleur = couleurs.value;
    if (tailles) idTaille = tailles.value;

    if (listQtePanier) {
            var response = ajax_file('ajax/ajax_liste_qte_panier.php?idprod=' + idProduit + '&idcouleur=' + idCouleur + '&idtaille=' + idTaille + '&i=' + i + '&panierId=' + paniervirtuelId );
            if (response)
                listQtePanier.innerHTML = response;
    }
}

function updatePanier(idProduit, i, paniervirtuelId){
    var couleurs = document.getElementById('couleurProd'+i);
    var tailles = document.getElementById('tailleProd'+i);
    var qte = document.getElementById('ProdQte'+i);


    var idCouleur = 0;
    var idTaille = 0;

    if (couleurs) idCouleur = couleurs.value;
    if (tailles) idTaille = tailles.value;


    ajax_file('ajax/ajax_upd_panier.php?idprod=' + idProduit + '&idcouleur=' + idCouleur + '&idtaille=' + idTaille + '&qte=' + qte.value + '&panierId=' + paniervirtuelId);
}

function bodyScrollUnlock(){
    "use strict";

    $('body').css({
        'overflow' : 'inherit',
        'padding-right' : '0'
    });
    $('body:not(.zoom_img_on) #site_head_wrap').css({
        'padding-right' : '0'
    });
    /*
    $('.mainMenu').css({
        'width' : '100%',
        'padding-right' : '0'
    });*/
}


function bodyScrollLock(){
    "use strict";
     
    $('body:not(.body_fixed)').css({
        'overflow' : 'hidden',
        'padding-right' : '17px'
    });

    $('#site_head_wrap').css({
        'padding-right' : '17px'
    });
}

function newUpdatePanier(idProduit, i, paniervirtuelId, prixU, path_web, titreObjet, idElTaille, lastTaille, idElCouleur, lastCouleur, configurator_product, configurator_option, is_reservation)
{
    if (i != '') {
        i = '_' + i;

        prixU = $('#prixU' + i).val();
    }

    var couleurs = $('#couleurProd' + i);
    var tailles = $('#tailleProd' + i);

    var res = '';
    var html_err = '';
    var flagError = false;

    if ($('#lightbox_achat_express').length > 0) {
        from_range = true;
    } else {
        from_range = false;
    }

    html_err = '<div class="txt_alert"></div><div class="rosette"></div>';
    html_err += '<div class="wrapper_btn"><a onclick="closeMultiShad();" href="#" class="btn_alert">OK</a></div>';

    if (tailles.length && tailles.val() == '') {
        res = ajax_file(path_web + 'ajax_sprintf.php?arg1=js_error_size');
        flagError = true;
    } else if (couleurs && couleurs.val() == '') {
        res = ajax_file(path_web+'ajax_sprintf.php?arg1=js_error_couleur');
        flagError = true;
    } else if (document.getElementById('qteProd' + i) && document.getElementById('qteProd' + i).value == '') {
        res = ajax_file(path_web + 'ajax_sprintf.php?arg1=js_error_quantity');
        flagError = true;
    }

    if (flagError) {
        alert(res);

        return false;
    } else {
        var qte = 0;

        if ($('#qteProd' + i)) {
            qte = $('#qteProd' + i).val();
        }

        var idCouleur = 0;
        var idTaille = 0;

        if (couleurs && couleurs.val() != 'none') {
            idCouleur = couleurs.val();
        }

        if (tailles && tailles.val() != 'none') {
            if (tailles.attr('class') !== undefined) {
                idTaille = tailles.attr('class');
            }else{
                idTaille = tailles.val();
            }
        }

        var mode = '';
        if (configurator_product == true) {
            mode = '&configurator_product=true';
        }

        // Add Reservation
        if (is_reservation === true) {
            var param_reservation = $('#reservationForm').serializeArray();
            var reservation_ok = false;
            var commande_reservation_id = false;

            if (!reservationDate) {
                param_reservation.forEach((data) => {
                    if (data.name == 'reservation_date') {
                        if (data.value == '') {
                            data.value = ('00/00/0000');
                        }
                    }
                });
            }

            $.ajax({
                url : path_relative_root + 'ajax_add_reservation.php',
                type : 'post',
                data : {
                    array_params : param_reservation
                },
                success : function (res) {
                    // Modification de l'image du produit dans la lightbox du basket
                    // RAZ au cas où
                    $('#valid_reservation').each(function() {
                        options = JSON.parse($(this).attr('data-options'));
                        $('#qteProd' + options.product_id).val(0);
                    });
                    // Recupération du panier virtuel id pour definir que les ecusson sont des enfant du produit principal
                    var panier_virtuel_id = res.panier_virtuel_id;

                    var produit_img = res.produit_img;
                    var produit_title = res.produit_title;
                    var produit_price = res.produit_price;
                    var array_produit_caract = res.array_produit_caract;
                    var reservation_date = res.reservation_date;
                    var reservation_heure = res.reservation_heure;
                    var reservation_video = res.reservation_video;
                    var reservation_nb_passagers = res.reservation_nb_passagers;
                    var duration_wish = res.tailleProd;
                    var array_passenger = res.array_passenger;
                    var special_reservation = res.special_reservation;
                    var reservation_amount = res.reservation_amount;
                    commande_reservation_id = res.commande_reservation_id;

                    var str_caract = '';
                    var str_passenger = '<label>' + translate('passengers') + ' : </label>';
                    var str_email = '<label>' + translate('lightbox_adwords_email') + ' : </label>';
                    var str_video = '';

                    $.each(array_produit_caract, function(i, val) {
                        if (val.caracteristique_lib == 'Type de vol') {
                            str_caract += "<span>" + translate('type') + " : " + val.caracteristique_val + "</span>";
                        }
                    });

                    $.each(array_passenger, function(i, val) {
                        if (i == 2) {
                            str_passenger += ", ";
                            str_email += ", "
                        }

                        str_passenger += "<span class='passagerGondole'>";
                        str_passenger += "<span>" + val.reservation_prenom + " " + val.reservation_nom + "</span>";
                        str_passenger +="</span>";
                        str_email += "<span class='emailGondole'>";
                        str_email += "<span>" + val.reservation_email + "</span>";
                        str_email +="</span>";
                    });

                    if (reservation_video == 0) {
                        str_video = translate('no_choose_option_video');
                    } else {
                        produit_price = '<span class="unit">' + reservation_amount + '</span><sup class="devise">&euro;</sup>';
                        var option_video = $('#product_video');
                        addToBasketClick(option_video);
                        str_video = translate('choose_option_video');
                    }
                    
                    //On gère l'affichage de la durée dans le panier
                    duration_wish_front = parseInt(tailleProd.value) - 5;
                    duration_wish = duration_wish_front + "/" + parseInt(tailleProd.value);
                    unite_duration = "min";

                    if (tailleProd.value == 60) {
                        duration_wish = "1 ";
                        unite_duration = "heure";
                    }

                    duration_wish = duration_wish + unite_duration;
                    
                    // On cache le loading et on affiche les bouton pour acceder au panier
                    $('.reservationFormWrapper').css('display', 'none');
                    $('.configDate').css('display', 'none');
                    $('#config_img').attr('src',produit_img); // Product Image
                    $('#config_title').html(produit_title); // Product Titre
                    $('#config_price').html(produit_price); // Product Prix

                    if (reservation_date != '00/00/0000' && special_reservation == 0) {
                        $('.configDate').css('display', 'unset');
                        $('#config_date').html(reservation_date); // Reservation Date
                        $('#config_hour').html(reservation_heure); // Reservation Heure
                    }

                    $('#config_qte').html(reservation_nb_passagers); // Reservation Nb passager
                    if (special_reservation == 0) {
                        $('#config_video').html(str_video); // Product Prix
                        $('#duration_wish').html(duration_wish); // Durée du vol
                        $('#bloc_config_caract').html(str_caract); // Product Caract
                        $('#bloc_config_passenger').html(str_passenger); // Passenger data
                        $('#configEmail').html(str_email); // Passenger email
                    }
                    $('#reservationConfirm').css('display', 'block');

                    $("#shade").css('opacity', '0.6').fadeIn().click(function(evt){
                        close_lightbox('reservationConfirm');
                        closeReservation(res.prod_id);
                    });
                    bodyScrollLock();
                },
                async: false
            });
        }

        if (commande_reservation_id && is_reservation === true) {
            mode += '&reservation=' + commande_reservation_id;
        }

        var array_response = [];

        //On vérifie si c'est une vidéo
        if (idProduit == 12){
            //On fixe la taille à 1
            idTaille = 1;
        }
        var response = ajax_file(path_web + 'ajax_upd_panier.php?prixu=' + prixU + '&idprod=' + idProduit + '&idcouleur=' + idCouleur + '&idtaille=' + idTaille + '&qte=' + qte + '&panierId=' + paniervirtuelId + mode);

        if (response) {
            array_response = response.split("//");

            var designation = array_response[0];

            if (titreObjet.match('^[0-9]')) {
                if (designation == 1) {
                    designation += ' lot de';
                } else {
                    designation += ' lots de';
                }
            }

            designation += ' ' + titreObjet;

            if (document.getElementById(idElTaille)) {
                if (document.getElementById(idElTaille).selectedIndex) {
                    designation += ' - Taille ' + document.getElementById(idElTaille).options[document.getElementById(idElTaille).selectedIndex].text;
                } else if (lastTaille != '') {
                    designation += ' - Taille ' + lastTaille;
                }
            }

            if ($('[name="' + idElCouleur + '"]').length > 0) {
                if ($('[name="' + idElCouleur + '"]:checked').length > 0) {
                    designation += ' - ' + $('[name="' + idElCouleur + '"]:checked').get(0).nextElementSibling.title.trim();
                } else if (lastCouleur != '') {
                    designation += ' - ' + lastCouleur;
                }
            }

            array_response[0] = designation;

            // SI il n'y a plus de stock
            if (array_response[1] == 'false') {
                $('#size_' + idTaille).addClass('disabled');
                alert(translate('product_no_stock_2'));
                return false;
            }

            if (is_reservation === true) {
                return true;
            }
        }
        return array_response;
    }
}
function showMontanTotalProduit(elementID, i){
    var montantProduit = document.getElementById(elementID);
    qte = document.getElementById("ProdQte"+i);
    prix = document.getElementById("prixProd"+i);

    var response = ajax_file('ajax/ajax_montant_total_produit.php?qte=' + qte.value + '&prix=' + prix.value );

    if (response)
                montantProduit.innerHTML = response;
}

function showMontanTotalPanier(elementID){
        var montantTotalPanier = document.getElementById(elementID);
        var response = ajax_file('ajax/ajax_montant_total_panier.php');

        if (response)
            montantTotalPanier.innerHTML = response;
}

//Affiche la liste des questions appropriées au service demandé
function show_question(elementID, fieldID, idService, parentPath) {

    var list_question = document.getElementById(elementID),
        field = document.getElementById(fieldID);

    clearTimeout(time_out);

    if (list_question) {

        $.ajax({
            url : path_relative_root + 'ajax_list_question.php',
            type : 'post',
            data : { idService : idService },
            success : function (res) {

                list_question.innerHTML = res;
                field.innerHTML = '';

                $('.selectStyled', list_question).selectStyled();
            }
        });
    }
}

// Affiche les champs appropriées au service et à la question demandé
function show_fields(elementID, idService, idQuestion, parentPath) {

    var list_field = document.getElementById(elementID);

    clearTimeout(time_out);

    if (list_field) {

        var response = ajax_file(parentPath + 'ajax_field_contact.php?idService=' + idService + '&idQuestion=' + idQuestion);

        $('.contact-beware a').attr('href',parentPath + create_link('connexion_login') + '?from=contact&id_service=' + idService + '&id_question=' + idQuestion);

        if (response) {

            list_field.innerHTML = response;

            $( "#produit_ref_input" ).bind( "keyup", function() {
                // Recuperation de tous les produit
                getAllProduct('produit_ref');
            });

            $( "#produit_nom_input" ).bind( "keyup", function() {
                // Recuperation de tous les produit
                getAllProduct('produit_nom');
            });

            $('.selectStyled', list_field).each(function () {

                $(this).selectStyled();
            });

            tabError = [];

            tabError[tabError.length] = ['alpha', 'contactNom', 'contact_field_nom'];
            tabError[tabError.length] = ['alpha', 'contactPrenom', 'contact_field_prenom'];
            tabError[tabError.length] = ['mail', 'contactMail', 'contact_field_mail'];

            if ( idService == 7 ) {

                tabError[tabError.length] = ['alphanum','contactAdresse','contact_field_adresse'];
                tabError[tabError.length] = ['alphanum','contactCP','contact_field_cp'];
                tabError[tabError.length] = ['alphanum','contactVille','contact_field_ville'];
                tabError[tabError.length] = ['alphanum','contactPays','contact_field_pays'];

            }
            else {

                if (idService == 2 && idQuestion == 5) {

                    tabError[tabError.length] = ['alphanum','contactRefProduit','contact_field_ref_produit'];
                }

                if (idService == 14) {

                    if (idQuestion == 3) {

                        tabError[tabError.length] = ['alphanumadresse', 'contactAdresse', 'contact_field_adresse'];
                        tabError[tabError.length] = ['phone', 'contactNumPortable', 'contact_field_num_portable'];
                        tabError[tabError.length] = ['alphanum', 'contactCP', 'contact_field_cp'];
                        tabError[tabError.length] = ['alphanumadresse', 'contactVille', 'contact_field_ville'];
                        tabError[tabError.length] = ['select_date', 'garantieDateAchat', 'date_achat'];
                        tabError[tabError.length] = ['prix', 'contactPrix', 'prix'];
                        tabError[tabError.length] = ['alphanum', 'id_produit_ref', 'date_achat'];

                        $("#telephoneFake").intlTelInput({
                            utilsScript: parentPath+'js/intlTelInputUtils.js'
                        });

                        // Date Achat validation
                        $('#btn_contact').click(function (e) {

                            e.preventDefault();

                            if ((document.getElementById('garantieDateAchatM').value === '') || (document.getElementById('garantieDateAchatA').value === '') || (document.getElementById('garantieDateAchatJ').value === '')) {
                                $('#garantieDateAchatLabel').addClass('labelErr');
                            } else {
                                $('#garantieDateAchatLabel').removeClass('labelErr');
                                $('.img_error').remove();
                            }

                            if ($('#id_produit_ref').val() == ''){
                                $('#produit_ref_input').attr('class', 'inputErr');
                                $('#produit_nom_input').attr('class', 'inputErr');
                                $('#id_produit_refLabel').attr('class', 'labelErr');
                                $('#produit_nom_inputLabel').attr('class', 'labelErr');



                                $('#id_produit_ref').after('<img style="margin: 2px 0 0 10px" src="/wshop-front/hesperide.com/htdocs/img/picto_panneau.png" alt="" class="img_error">');
                                $('#produit_nom_input').after('<img style="margin: 2px 0 0 10px" src="/wshop-front/hesperide.com/htdocs/img/picto_panneau.png" alt="" class="img_error">');

                            }
                            else {
                                $('#produit_ref_input').attr('class', 'inputForm');
                                $('#produit_nom_input').attr('class', 'inputForm');
                                $('#id_produit_refLabel').attr('class', '');
                                $('#produit_nom_inputLabel').attr('class', '');
                            }

                            if ($('#contactMagasin').val() == ''){
                                $('#contactMagasin').after('<img style="margin: 2px 0 0 10px" src="/wshop-front/hesperide.com/htdocs/img/picto_panneau.png" alt="" class="img_error">');
                                $('#contactMagasinLabel').addClass('labelErr');
                            }
                        });
                    } else if (idQuestion == 2) {

                        var content = ajax_file('ajax_field_active_garantie.php' );

                        if (content) {

                            $('#select_garantieNomProduit').append( content);
                            $('#field_garantieNomProduit').removeClass('cache');
                            $('#field_error_garantieProduit').addClass('cache');
                            $('#field_photo').addClass('cache');
                            $('#field_error_garantieProduit td').empty();
                        } else {

                            $('#field_garantieNomProduit').addClass('cache');
                        }
                    }
                }
            }

            if (!(idService == 3 && idQuestion == 2) && idService != 7) {

                tabError[tabError.length] = ['no_empty', 'contactMessage', 'contact_field_message'];
            }

            if ($('#g-recaptcha').length > 0) {
                grecaptcha.render('g-recaptcha');
            }
        } else {

            list_field.innerHTML = '';
        }
    }
}

function show_field_form(parentPath){
    var list_field = document.getElementById('field');
    clearTimeout(time_out);
    if (list_field) {
        var response = ajax_file(parentPath + 'ajax_field_contact.php?idService=7&idQuestion=1');
        if (response) {
            list_field.innerHTML = response;

            tabError = new Array();
            tabError[0] = new Array('alpha','contactNom','contact_field_nom');
            tabError[1] = new Array('alpha','contactPrenom','contact_field_prenom');
            tabError[2] = new Array('mail','contactMail','contact_field_mail');
            tabError[3] = new Array('alphanum','contactAdresse','contact_field_adresse');
            tabError[4] = new Array('alphanum','contactCP','contact_field_cp');
            tabError[5] = new Array('alphanum','contactVille','contact_field_ville');
            tabError[6] = new Array('alphanum','contactPays','contact_field_pays');

            $('.selectStyledFaq, .selectStyled, .selectStyledProduct').each(function(){
                $(this).selectStyled();
            });
        }
        else
            list_field.innerHTML = '';
    }
}

// Affiche les produits de la commande appropriées au service litige // Contact
function show_product_commande(idCommande, parentPath){

    var list_field =  document.getElementById('field_produit_commande');
    if (idCommande) {
        var response = ajax_file(parentPath + 'ajax_field_product_commande.php?idCommande=' + idCommande);
        if (response){
            list_field.innerHTML = response;
        }
    }else{
        list_field.innerHTML = "";
        $('#field_message').addClass('cache');
        $('#field_photo').addClass('cache');
        $('#field_send').addClass('cache');
    }
}

// Affiche si produit est encore sous garantie
function show_product_garantie(idCommande, idProduit, parentPath){

    if (idCommande && idProduit) {
        var response = ajax_file(parentPath + 'ajax_field_product_garantie.php?idCommande=' + idCommande + '&idProduit=' + idProduit);
        if (response){

            // ERREUR SI PRODUIT RETOURNE
            if (response == "Produit Retourne"){

                document.getElementById('field_error_produit_garantie').innerHTML = ajax_file(parentPath + 'ajax_sprintf.php?arg1=contact_field_error_produit_retourne');
                $('#field_message').addClass('cache');
                $('#field_photo').addClass('cache');
            }else{
                // GESTION DES ERREURS
                tab = new Array();
                tab[0] = new Array('alpha','contactNom','contact_field_nom');
                tab[1] = new Array('alpha','contactPrenom','contact_field_prenom');
                tab[2] = new Array('mail','contactMail','contact_field_mail');
                tab[3] = new Array('no_empty','contactMessage','contact_field_message');

                $('#field_message').removeClass('cache');
                $('#field_photo').removeClass('cache');
                document.getElementById('field_error_produit_garantie').innerHTML = "";
            }
        }else{
            document.getElementById('field_error_produit_garantie').innerHTML = ajax_file(parentPath + 'ajax_sprintf.php?arg1=contact_field_error_garantie');
            $('#field_message').addClass('cache');
            $('#field_photo').addClass('cache');
        }
    }else{
        $('#field_message').addClass('cache');
        $('#field_photo').addClass('cache');
    }
}

function paginationGoToPage(page) {

    var page_bloc;

    page = page || 1;
    page_bloc = parseInt(page - 1);

    if ($('#search_results').length > 0) {

        generateNewBlocSearch(page_bloc);
    } else {

        generateNewBlocProd(page_bloc);
    }
}

function paginate(page) {

    var form = document.getElementById('filter_content');
    
    if (form != null){
        var total_element = form.getValue('totalElems', parseFloat),
        nb_prod = form.getValue('nb_prod', parseFloat),
        nb_total_page = Math.ceil(total_element / nb_prod),
        pagination = $('.pager_wrapper'),
        nb_prod_default = form.getValue('nb_prod_default', parseFloat),
        page = page || 1;
    }

    $.ajax({
        url : path_relative_root + 'ajax_reload_pagination.php',
        type : 'get',
        data : {
            page : page,
            link : 'paginationGoToPage()',
            nb_total_page : nb_total_page
        },
        success : function (res) {

            pagination.each(function () {

                this.innerHTML = res;
            });
        }
    });
}

/**
 * Pour charger tous les produits sur une page
 * @author
 * @since
 *
 * @param  String           type       [description]
 * @param  Number           idObj      [description]
 * @param  Number           page       [description]
 * @param  Number           nb_product [description]
 * @param  HTMLElement      btn        le bouton sur lesquel on a cliqué pour charger les element (si undefined pas de probleme)
 *
 * @return void
 */

var bloc_prod_xhr;

function generateNewBlocProd(page, type, idObj, nb_product, btn) {
    
    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('filter_content'),
        ctrl = Array.prototype.slice.call(form.elements, 0),
        page = (page !== undefined) ? page : form.getValue('page', parseFloat),
        type = type || form.getValue('type'),
        idObj = idObj || form.getValue('idObj'),
        nb_product = nb_product || form.getValue('nb_prod', parseFloat),
        output = {},
        is_from_page = false,
        key;

    // Build an object to store active filters
    ctrl.forEach(function (elm) {

        if ((elm.type === 'checkbox' || elm.type === 'radio')) {

            if (elm.checked && elm.name !== 'sscat' && elm.value !== '') {

                if (output[elm.name] === undefined) {

                    output[elm.name] = [];
                }

                output[elm.name].push(parseFloat(elm.value));
            }
        }
    });

    // Convert filters arrays to strings
    for (key in output) {

        if (output.hasOwnProperty(key)) {

            if (output[key].constructor === Array) {

                output[key] = output[key].join('~');
            }
        }
    }

    if (nb_product !== undefined) {

        form.nb_prod.value = nb_product;
    } else {

        nb_product = form.nb_prod.value;
    }

    if (page !== undefined && page !== 'all') {

        form.page.value = page * nb_product;

        is_from_page = true;
    }

    // Add misc values to output object
    output.page = form.page.value;
    output.type = type;
    output.parentPath = path_relative_root;
    output.idObj = idObj;

    if (idObj.split('~').length > 1) {

        output.multi_object = true;
    }

    output.nb_prod = nb_product;
    output.is_page = is_from_page;
    output.nb_tot_prod = form.totalElems.value;

    output.tri = '';

    /** LEGACY **/

        //Recuperation du trie par prix (descendant ou ascendant) qui passe, malheureusement, par l'url
        var url = document.location.href;
        var pattern = new RegExp('\/t([0-9]*)(\/m(0|1))*\.html$');
        var pattern2 = new RegExp('\/e([0-9]*)\.html$');
        var tri = '';
        // Si il y a un ordre dans les prix on charge l'id
        if (pattern.test(url)) {
            var res = url.match(pattern);
            var tri = res[1][0];
        }

    /** END LEGACY **/

    // Récupération du prix
    if ($('#price_min').length > 0) {
        // Récupération du prix
        output.prix = form.getValue('price_min', parseFloat) + '~' + form.getValue('price_max', parseFloat);
    }

    if (page === 'all') {

        /** LEGACY **/

            var hash;

            if ((hash = location.hash && location.hash.match(/i=[0-9]+/g)[0]) || page_value > 0) {

                var marginTop = $('.banner_wrapper').height() + 20,
                    $elem = hash ? $('#' + hash.replace('=', '')) : $list_item,
                    offset = $elem.offset(),
                    top = offset && offset.top - marginTop;

                window.setTimeout(function () {

                    $('#contents_wrapper').animate({ scrollTop : top }, 0.8);
                }, 0);
            }

        /** END LEGACY **/
    } else {

        if (bloc_prod_xhr) {

            bloc_prod_xhr.abort();
        }

        bloc_prod_xhr = $.ajax({
            url : path_relative_root + 'ajax_reload_products.php',
            type : 'get',
            data : output,
            success : function (res) {

                var new_elm = document.createElement('div'),
                    old_elm = document.getElementById('scroll_items'),
                    content, itm_length;

                if (btn && btn.nextElementSibling && btn.nextElementSibling.classList.contains('btn_tunnel')) {
                    btn.nextElementSibling.style.display = 'none';
                }

                res = res.split('><><');

                content = res[0].trim();
                itm_length = parseFloat(res[1]);

                if (content !== '') {
                    new_elm.id = 'list_item';
                    new_elm.className = 'items_wrapper';
                    new_elm.innerHTML = content;

                    if (!isNaN(page)) {
                        new_elm.setAttribute('data-p', (page * nb_product));
                    }

                    old_elm.innerHTML = '';

                    document.getElementById('totalElems').value = itm_length;

                    paginate(page + 1);

                    old_elm.appendChild(new_elm);

                    if (window.lazyload !== undefined) {
                        lazyload.init();
                    }

                    /** LEGACY **/
                    var js_compteur = $('.vf_countdown').length;
                    var js_encart = content.match(/\/\/vf_begin([\s]+[\w$(){}\[\]=;\0,\'\".:\/<&!+% ]*)+\/\/vf_end/g);

                    if (js_compteur != null) {
                        try{
                            var cnt_compteur = js_compteur.length;

                            for( var i = 0; i < cnt_compteur; i++ ){
                                eval( js_compteur[i].replace( /(vf_compteur_begin|vf_compteur_end)/g, "" ) );
                            }
                        }
                        catch( e ){}
                    }

                    if (js_encart != null) {
                        try{
                            js_encart = js_encart[0].replace( /(\/\/vf_begin|\/\/vf_end)/g, "" );
                            eval( js_encart );
                        }
                        catch( e ){}
                    }

                    /** END LEGACY **/
                }
            },
            complete : function () {

                setTimeout(function () {

                    $('html, body').animate({ scrollTop : 0 }, 200);
                }, 250);
            }
        });
    }
}

function updateFilter(tab_response, page, parentPath, type, idObj, no_price_range_update) {
    if ( tab_response[0] != '' ) {
        var bloc_photo = tab_response[0];
        document.getElementById('list_item').innerHTML = bloc_photo;

        $(".slider_product_wrapper").each(function(){
            $(this).hover(function(){
                $(this).children('.visu_product_slider').children('.visu_product_slider_hover').show();
            }, function(){
                $(this).children('.visu_product_slider').children('.visu_product_slider_hover').hide();
            });
        });

        var nav = tab_response[2];
        var nav_bas = tab_response[3];

        var nb_prod = tab_response[4];
        // document.getElementById('nb_prod_choix').style.display = 'block';

        if (document.getElementById('nb_prod_numeric')) document.getElementById('nb_prod_numeric').innerHTML = nb_prod;
        if (document.getElementById('label_nb_prod_numeric')) {
            if ( nb_prod == '' )
                document.getElementById('label_nb_prod_numeric').style.display = 'none';
            else
                document.getElementById('label_nb_prod_numeric').style.display = 'block';
        }

        if (typeof(page) == 'undefined') {

            var prix_min = tab_response[5];
            var prix_max = tab_response[6];

            if (!no_price_range_update)
            {
                $("#amount1").val(prix_min);
                $("#amount2").val(prix_max);
                $("#slider-range").slider( "option" , { min:parseInt(prix_min),max:parseInt(prix_max),values:[parseInt(prix_min),parseInt(prix_max)]} );
                min_price = prix_min;
                max_price = prix_max;

                $(".ui-slider-range").width("100%");
                $(".ui-slider-range").css('left','0%');
                $(".ui-slider-range").next().css('left','0%');
                $(".ui-slider-range").next().next().css('left','100%');
            }

            // couleur
            var color = tab_response[7];
            var tab_color_tmp = color.split('~');
            var cnt_color = tab_color_tmp.length;

            if ( tab_color_tmp[0] != '' ) {

                txt_color = ajax_file(parentPath + 'ajax_sprintf.php?arg1=recherche_couleurs');
                var bloc_color = '<div class="select_recherche">'+txt_color+'</div><div class="selected_options select_colors"></div>';

                var str_color = '';

                for( var j=0 ; j<cnt_color ; j++ ) {

                    var detail_color = tab_color_tmp[j].split('|');
                    var class_color = 'btn_select_off';

                    if ( tab_color.length > 0 ) {
                        if ( in_array_js(detail_color[0],tab_color) ) {
                            class_color = 'btn_select';
                        }
                    }
                    str_color += '<div id="couleur_' + detail_color[0] + '" class="left_menu_obj '+class_color+' couleur_' + detail_color[0] + '"><div class="f_left couleur_check"></div><div class="f_left txt_menu">' + detail_color[1] + '</div></div>';
                }

                $("#bloc_couleur").show().find(".selected_options").html(str_color);
            } else {
                $("#bloc_couleur").hide().find(".selected_options").html('');
            }
            // size
            var size = tab_response[8];

            var tab_size_tmp = size.split('~');
            var cnt_size = tab_size_tmp.length;

            if ( tab_size_tmp[0] != '' ) {

                txt_size = ajax_file(parentPath + 'ajax_sprintf.php?arg1=recherche_taille');

                var bloc_size = '<div class="select_recherche">'+txt_size+'</div><div class="selected_options"></div>';

                var str_size = '';

                for( var j=0 ; j<cnt_size ; j++ ) {

                    var detail_size = tab_size_tmp[j].split('|');
                    var class_size = 'btn_select_off';

                    if ( tab_taille.length > 0 ) {
                        if ( in_array_js(detail_size[0],tab_taille) )
                            class_size = 'btn_select';
                    }

                    str_size += '<div id="taille_' + detail_size[0] + '" class="left_menu_obj '+class_size+'"><div class="f_left"></div><div class="f_left txt_menu">' + detail_size[1] + '</div></div>';

                }

                $("#bloc_taille").show().find(".selected_options").html(str_size);
                var windowW = $(window).height();
                if (windowW >= 900) {
                    $("#choix_recherche").sideBarEase("refresh");
                }

            } else {
                $("#bloc_taille").hide().find(".selected_options").html('');
            }
        }
    }
    else {
        $('#list_item').html(ajax_file(parentPath + 'ajax_sprintf.php?arg1=no_response_search'));
        $('#pagination_haut').html('');
        $('#pagination_bas').html('');
    }
    $(".left_menu_obj").on('click touchend', filterBoxesToggle);
    var windowW = $(window).height();
    if (windowW >= 900) {
        $("#choix_recherche").sideBarEase("refresh");
    }
    $('#page').val(0);
}


//On va regarde tout les bloc et on envoi l'ajax
//Pour les blocs de cases à cocher
//Pour le slider des prix
//Pour le bouton qui supprime un element
var bloc_search_xhr;

function generateNewBlocSearch(page, type, idObj, nb_product, btn) {

    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('filter_content'),
        output = {},
        is_from_page = false,
        key;

    Array.prototype.slice.call(form.elements).forEach(function (elm) {

        if ((elm.type === 'checkbox' || elm.type === 'radio')) {

            if (elm.checked && elm.value !== '') {

                if (output[elm.name] === undefined) {

                    output[elm.name] = [];
                }

                output[elm.name].push(elm.value);
            }
        } else if (elm.type === 'hidden') {

            if (elm.value !== '') {

                output[elm.name] = elm.value;
            }
        }
    });

    for (key in output) {

        if (output.hasOwnProperty(key)) {

            if (output[key].constructor === Array) {

                output[key] = output[key].join('~');
            }
        }
    }

    if (nb_product !== undefined) {

        form.nb_prod.value = nb_product;
    } else {

        nb_product = form.nb_prod.value;
    }

    if (page !== undefined && page !== 'all') {

        output.page = page;

        form.page.value = page * nb_product;

        is_from_page = true;
    }

    output.parentPath = path_relative_root;
    output.nbProducts = nb_product;
    output.is_page = is_from_page;

    // Récupération du prix
    output.prix = form.getValue('price_min', parseFloat) + '~' + form.getValue('price_max', parseFloat);

    if (bloc_search_xhr) {

        bloc_search_xhr.abort();
    }

    bloc_search_xhr = $.ajax({
        url : path_relative_root + 'ajax_reload_search.php',
        type : 'get',
        data : output,
        success : function (res) {

            var new_elm = document.createElement('div'),
                old_elm = document.getElementById('scroll_items'),
                content, itm_length;

            if (btn && btn.nextElementSibling && btn.nextElementSibling.classList.contains('btn_tunnel')) {

                btn.nextElementSibling.style.display = 'none';
            }

            res = res.split('><><');
            content = res[0].trim();

            size = res[8].trim();

            var array_size = size.split('~');

            itm_length = parseFloat(res[1]);

            if (content !== '') {

                new_elm.id = 'list_item';
                new_elm.className = 'items_wrapper';
                new_elm.innerHTML = content;

                if (!isNaN(page)) {

                    new_elm.setAttribute('data-p', (page * nb_product));
                }

                old_elm.innerHTML = '';

                document.getElementById('nb_articles').innerHTML = itm_length;
                document.getElementById('totalElems').value = itm_length;

                paginate(page + 1);

                old_elm.appendChild(new_elm);

                if (window.lazyload !== undefined) {

                    lazyload.init();
                }

                /** LEGACY **/

                    var js_compteur = $('.vf_countdown').length;
                    var js_encart = content.match(/\/\/vf_begin([\s]+[\w$(){}\[\]=;\0,\'\".:\/<&!+% ]*)+\/\/vf_end/g);

                    if ( js_compteur != null ){
                        try{
                            var cnt_compteur = js_compteur.length;

                            for( var i = 0; i < cnt_compteur; i++ ){
                                eval( js_compteur[i].replace( /(vf_compteur_begin|vf_compteur_end)/g, "" ) );
                            }
                        }
                        catch( e ){}
                    }
                    if ( js_encart != null ){
                        try{
                            js_encart = js_encart[0].replace( /(\/\/vf_begin|\/\/vf_end)/g, "" );
                            eval( js_encart );
                        }
                        catch( e ){}
                    }

                /** END LEGACY **/
            }
        },
        complete : function () {

            setTimeout(function () {

                $('html, body').animate({ scrollTop : 0 }, 200);
            }, 250);
        }
    });
}

//On va regarde tout les bloc et on envoi l'ajax
//Pour les blocs de cases à cocher
//Pour le slider des prix
//Pour le bouton qui supprime un element
function generateNewBlocGift( parameters, page, parentPath, nb_product ) {

    var tab_bloc = new Array('taille','couleur','promo');
    var tab_get_taille = new Array();
    var tab_get_couleur = new Array();
    var tab_get_promo = new Array();
    var tab_get_eclat = new Array();
    var tab_bloc_length = tab_bloc.length;

    // On parcourt tt les bloc de checkbox et on regarde s'il y en a de cocher pour chaque bloc( taille, couleur, promo )
    for( var k=0 ; k<tab_bloc_length ; k++ ) {

        elem_bloc = $("#select_"+tab_bloc[k]).children("div").children(".styled");
        elem_bloc_length = elem_bloc.length;

        for( var l=0 ; l<elem_bloc_length ; l++ ) {

            var id = elem_bloc[l].id;

            if ( tab_bloc[k] == 'taille' && elem_bloc[l].checked ) tab_get_taille.push(id.substring(id.indexOf('_')+1));
            else if ( tab_bloc[k] == 'couleur' && elem_bloc[l].checked ) tab_get_couleur.push(id.substring(id.indexOf('_')+1));
            else if ( tab_bloc[k] == 'promo' && elem_bloc[l].checked ){

                //on regarde si c'est une marque ou une promotion
                if ( id[0] == 'p' ) tab_get_promo.push(id.substring(id.indexOf('_')+1));
                else tab_get_eclat.push(id.substring(id.indexOf('_')+1));

            }

        }

    }

    var is_from_page = false;

    // On check si l'utilisateur a clique sur la pagination ou le nombre de produit par page
    if (typeof(page) != 'undefined') {
        document.getElementById('page').value = page;
        is_from_page = true;
    }
    if (typeof(nb_product) != 'undefined') document.getElementById('nb_prod').value = nb_product;

    var str_taille = '';
    var str_couleur = '';
    var str_promo = '';
    var str_eclat = '';
    var str_prix = '';
    var str_final = parameters;

    if ( tab_get_taille.length > 0 ) str_taille = tab_get_taille.join('~');
    if ( tab_get_couleur.length > 0 ) str_couleur = tab_get_couleur.join('~');
    if ( tab_get_promo.length > 0 ) str_promo = tab_get_promo.join('~');
    if ( tab_get_eclat.length > 0 ) str_eclat = tab_get_eclat.join('~');
    // Cas special pour les prix
    str_prix = document.getElementById('amount1').value + '~' + document.getElementById('amount2').value;

    str_final += '&taille=' + str_taille;
    str_final += '&couleur=' + str_couleur;
    str_final += '&promo=' + str_promo;
    str_final += '&eclat=' + str_eclat;
    str_final += '&prix=' + str_prix;
    document.getElementById('wrapper_visus_produit').innerHTML = '<p id="loading"><img style="margin-top:35px;margin-right:10px;" src="' + parentPath + 'img/ajaxLoader.gif" alt="Chargement en cours..." width="25" height="25" />Chargement...<span></span></p>';
    var response = ajax_file(parentPath + 'ajax_reload_gift.php' + str_final + '&is_page=' + is_from_page +  '&parentPath=' + parentPath + '&nbProducts='+nb_product + '&page=' + page );

    var tab_response = response.split('><><');
    if ( tab_response[0] != '' ) {

        var bloc_photo = tab_response[0];
        document.getElementById('wrapper_visus_produit').innerHTML = bloc_photo;

        var nb_articles = tab_response[1];

        document.getElementById('nb_articles').innerHTML = nb_articles;
        document.getElementById('nb_pages').innerHTML = Math.ceil(nb_articles/document.getElementById('nb_prod').value);

        var nav = tab_response[2];
        var nav_bas = tab_response[3];
        document.getElementById('pagination_haut').innerHTML = nav;
        document.getElementById('pagination_bas').innerHTML = nav_bas;

        var nb_prod = tab_response[4];
        if (document.getElementById('nb_prod_numeric')) document.getElementById('nb_prod_numeric').innerHTML = nb_prod;

    }
    else {

        document.getElementById('wrapper_visus_produit').innerHTML = ajax_file(parentPath + 'ajax_sprintf.php?arg1=no_response_search');

        document.getElementById('pagination_haut').innerHTML = '';
        document.getElementById('pagination_bas').innerHTML = '';

        document.getElementById('nb_articles').innerHTML = 0;
        document.getElementById('nb_pages').innerHTML = 0;

    }
}

// Affiche la liste des commandes selon le type
function show_orders_by_type(elementID, type, parentPath) {

    // Gestion de la surbrillance des onglets
    var i = 1;
    while( document.getElementById('onglet_'+i+'_mid') ) {
        if ( type == i ) {

            $("#onglet_"+i+"_left").attr('class','left-on');
            $("#onglet_"+i+"_mid").attr('class','mid-on');
            $("#onglet_"+i+"_right").attr('class','right-on');

        }
        else {

            $("#onglet_"+i+"_left").attr('class','left-off');
            $("#onglet_"+i+"_mid").attr('class','mid-off');
            $("#onglet_"+i+"_right").attr('class','right-off');

        }
        i++;
    }

    var container = document.getElementById(elementID);

    //clearTimeout(time_out);
    if (container) {
        var response = ajax_file(parentPath + 'ajax_account_orders_by_type.php?type=' + type);

        if (response) {
            container.innerHTML = response;
            return true;
        }
        else{
            container.innerHTML = '';
            return false;
        }
    }
    else
        return false;
}

function openCloseMessage(elem, msg_open, msg_close) {
    var id = elem.attr('id')
    mId = id.substring(id.indexOf('_')+1);

    if (!(elem).hasClass('actif')) {
        $('.message_detail').slideUp();
        $('.btn_ouvrir_message').not(elem).removeClass('actif');
        $('.btn_ouvrir_message').not(elem).text(msg_open);

        if ( $('#msg_'+mId).html() == '' ) {
            $.ajax({
                type: 'post',
                data: { mId: mId, type: 1 },
                url: path_relative_root+'message-detail.php',
                success: function( response ){
                    $('#msg_'+mId).html(response);
                    $('#msg_'+mId).slideDown();
                }
            });
        } else {
            $('#msg_'+mId).slideDown();
        }

        (elem).addClass('actif');
        (elem).text(msg_close);
    } else {
        $('.message_detail').slideUp();
        (elem).removeClass('actif');
        (elem).text(msg_open);
    }
}

function show_message(mId, hide) {

    if (hide == 0) {
        var ajax_content = ajax_file('ajax_show_messages.php?mId='+ mId);
        if (ajax_content) {

            document.getElementById("message_"+ mId).innerHTML = ajax_content;

            document.getElementById("message_"+ mId).onclick = '';
            document.getElementById("message_"+ mId).style.cursor = 'default';

        }
    } else {
    var ajax_content = ajax_file('ajax_show_messages.php?mId='+ mId + '&hide=1');
        if (ajax_content) {

            document.getElementById("message_" + mId).innerHTML = ajax_content;

            document.getElementById("message_" + mId).onclick = "show_message("+mId+",0);";

            document.getElementById("message_" + mId).style.cursor = 'pointer';

        }
    }
}

var timer;
// --------------------------------------------------------------------------------
function newNewsletterFromCmpid() {

    var flag = verif_mail( 'email_inscription_home','login_email' );

    if ( flag ) {

        flag = ajax_file(path_relative_root+'ajax_email_newsletter_exist.php?mail='+ document.getElementById('email_inscription_home').value);

        var elementID = 'email_inscription_home';

        var objElement = document.getElementById(elementID);
        var objLabel = document.getElementById(elementID + 'Label');
        var erreurText = document.getElementById('erreur_msg');

        if ( flag === '1' ) {
            flag = false;
        }
        else {
            flag = true;
        }

        if ( !flag ) {
            erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file(path+'ajax_sprintf.php?arg1=login_email') + ' :</p>' + 'Cet email est déjà inscrit à la newsletter';
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';

        }
        else {

            objElement.className = 'inputForm';
            objElement.style.border="1px solid #CCCCCC";
            objLabel.className = 'labelForm';

        }
    }

    if ( flag ) {

        clearTimeout(timer);

        document.getElementById("bloc_erreur").style.display = 'none';
        document.getElementById("img_error_popup").style.display = 'none';

    }else{

        var val = document.getElementById('erreur_msg').innerHTML;
        var elem_img = document.getElementById("img_error_popup");
        $("#img_error_popup").css('display','inline-block');

        elem_img.setAttribute('onmouseover',function(){OverPopup(this,val,'visible','cmpid_adwords');});
        elem_img.onmouseover=function(){
            OverPopup(this,val,'visible','cmpid_adwords');
        };
        elem_img.setAttribute('onmouseout',function(){OverPopup(this,val,'none','cmpid_adwords');});
        elem_img.onmouseout=function(){OverPopup(this,val,'none','cmpid_adwords');};

        elem = document.getElementById('email_inscription_home');

        elem.style.border="1px solid red";
        pos = getPos(elem_img);

        document.getElementById('bloc_erreur').style.position = 'absolute';

        var nav = navigator.appVersion;

        $("#bloc_erreur").fadeIn(400);

        var clone = $('#bloc_erreur').clone(),
        c_pos = {
            top : $('#img_error_popup').offset().top - $('#img_error_popup').offsetParent().offset().top - $('#bloc_erreur').height() + 4,
            left : $('#img_error_popup').offset().left + $('#bloc_erreur').width()*3
        };

        $('#bloc_erreur').remove();
        $('#img_error_popup').after(clone);

        $('#bloc_erreur').css({
            'top' : c_pos.top,
            'left' : c_pos.left,
            'z-index' : '99999',
            'opacity' : '1'
        });

        window.clearTimeout(timer);

        timer = setTimeout("closeInfobulle('bloc_erreur')",5000);

    }

    return flag;

}

function checkAndAddMail() {

    if ( newNewsletterFromCmpid() ) {

        $("#btn_conf_cmpid").css('display','none');
        $("#loader_popup").css('display','block');

        response = ajax_file(path_relative_root+'ajax_add_email_newsletter.php?mail='+ document.getElementById('email_inscription_home').value);

        if ( response == 1 ) {

            $("#formu_inscription_news").fadeOut(750,function() {
                $("#conf_inscription_news").fadeIn(750);
            });

            document.getElementById('shad').setAttribute('onclick',function(){ close_popup('popup_inscription_home'); });
            document.getElementById('shad').onclick=function(){ close_popup('popup_inscription_home');}

        }

    }

}

function ajaxDeconnect(path_relative_root){

    var provenance = window.location.pathname,
        cpt_client = new RegExp(/(?:adresses|mes_infos|newsletter|parrainage|mon-compte|facture-liste|avoir-liste|commande_detail|panier|livraison-kiala|livraison|paiement|banque|erreur_cmd|erreur_commande|banque_refus|remerciements|endereco|newsletter-pt|conta|conta-lista|haver-lista|encomenda_detalhes|cesta|remessa|pagamento|banco|erreur_cmd_pt|erros-encomenda|banco-recusa|agradecimento)/),
        response;

    // Get the current page's name
    provenance = provenance.substr(provenance.lastIndexOf('/'), provenance.lastIndexOf('.'));

    // This actually disconnects the client
    response = ajax_file(path_relative_root + 'ajax_deconnect.php');

    // If there was an error
    if (!response)
        alert('Erreur lors de la déconnexion.');

    // Else if the current page can't be displayed when not logged in: redirect
    else if (cpt_client.test(provenance))
        window.location.href = path_relative_root + create_link('connexion_login');

    // Else: refresh
    else window.location.reload();

    // If the page hasn't been reloaded: remove the loader and return false
    hideLoader();

    return false;

}

/** wishlist **/
function addToWishlistShoppingcart()
{
    $.post(path_relative_root + "ajax_cart_to_wishlist.php", function(resp) {
        $(".btn_top_wishlist .gris").html('(x' + resp.result.wishlist_size + ')');
        $("#addToWishlistShoppingcart").removeClass("addToWishlistShoppingcart").addClass("existsToWishlistShoppingcart");
        $("#addToWishlistShoppingcart").html(resp.result.button_label);
        $("#addToWishlistShoppingcart").attr("onclick","").attr("href", path_relative_root + create_link('client_index') +'#wl');
    });
}

function deletePromoCode() {
    $.ajax({
        type: 'get',
        url: path_relative_root+create_link('delete_promo_code'),
        success: function( response ){
            location.href = path_relative_root + create_link('order_kiala');
        }
    });
}

/********display_lightbox_specifique********/
function display_lightbox_specifique() {

    var html = ajax_file(path_relative_root + 'ajax_lightbox_produit_specifique.php' );

    if ( html ) {
        var $lighbox = $( '#lightbox_achat_express' );
        var $shad = $( '#shad' );
        $lighbox
        .html( '<div style="position: relative">'+ html +'</div>' )
        .appendTo( 'body' )
        .show()
        ;
        $shad
        .show()
        .unbind( 'click.achat_express' )
        .bind( 'click.achat_express', function(){
        hideLightboxGamme();
        $("#alertbox").remove();
        })
        ;
    }

}
/*********/

/********add_produit_specifique********/
function add_produit_specifique(product_id, color_bloc_id, size_bloc_id) {

    var color_id = 0;

    if ( document.getElementById(color_bloc_id ) )
        color_id = document.getElementById( color_bloc_id ).value;

    var size_id = 0;

    if ( document.getElementById(size_bloc_id ) )
        size_id = document.getElementById( size_bloc_id ).value;

    if (document.getElementById(size_bloc_id ) && size_id == ''){
        var taille_label = ajax_file(path_relative_root+'ajax_sprintf.php?arg1=product_size');
        $("#label_alert").html(taille_label);

        has_errors = true;

        var $alertbox = $( '#alertbox' );

        $alertbox
            .appendTo( 'body' )
            .show()
        ;

        if (document.getElementById('lightbox_achat_express')) {
            $alertbox.css({
                left:   '52%',
                top:    '490px',
                position:   'fixed'
            });
        }
        addEvent(shad,'click',closeMultiShad);
        $("#bloc_btn_loader").hide();
        document.getElementById("bloc_btn_active").style.display="block";

    }
    else if (document.getElementById(color_bloc_id ) && color_id == ''){
        var couleur_label = ajax_file(path_relative_root+'ajax_sprintf.php?arg1=product_color');
        $("#label_alert").html(couleur_label);

        has_errors = true;

        var $alertbox = $( '#alertbox' );

        $alertbox
            .appendTo( 'body' )
            .show()
        ;

        if (document.getElementById('lightbox_achat_express')) {
            $alertbox.css({
                left:   '52%',
                top:    '490px',
                position:   'fixed'
            });
        }
        addEvent(shad,'click',closeMultiShad);
        $("#bloc_btn_loader").hide();
        document.getElementById("bloc_btn_active").style.display="block";

    } else {

        var retour = ajax_file(path_relative_root + 'ajax_add_produit_specifique.php?product_id=' + product_id + '&color_id=' + color_id + '&size_id=' + size_id );

        if ( retour == 'continue' ) {
            var html = ajax_file(path_relative_root + 'ajax_lightbox_produit_specifique.php' );

            if ( html ) {

                $("#bloc_btn_loader").hide();
                document.getElementById("bloc_btn_active").style.display="block";
                $( '#lightbox_achat_express' ).html( '<div style="position: relative">'+ html +'</div>' );
                $("#bloc_btn_loader").hide();
                document.getElementById("bloc_btn_active").style.display="block";

            }

        } else {
            location.href = path_relative_root + create_link('order_basket');
        }

    }

}
/*********/

function checkActiveGarantie(reponse){
    if (reponse == 0){
        $('#field_error_garantieProduit').removeClass('cache');
        $('#field_error_garantieProduit td').empty();
        $('#field_error_garantieProduit td').append(ajax_file( 'ajax_sprintf.php?arg1=contact_field_active_garantie' ));
        $('#select_garantieNomProduit').empty();

        $('#field_garantieNomProduit').addClass('cache');
        $('#field_photo').addClass('cache');
        $('#field_message').addClass('cache');
        $('#field_send').addClass('cache');
    }else{

        var dateJ = $('#contactDateCommandeJ').val();
        var dateM = $('#contactDateCommandeM').val();
        var dateA = $('#contactDateCommandeA').val();

        var content = ajax_file('ajax_field_active_garantie.php?dateJ=' + dateJ + "&dateM=" + dateM + "&dateA=" + dateA );
        if (content){
            $('#select_garantieNomProduit').append( content);
            $('#field_garantieNomProduit').removeClass('cache');
            $('#field_error_garantieProduit').addClass('cache');
            $('#field_error_garantieProduit td').empty();
        }else{
            $('#field_error_garantieProduit td').empty();
            $('#field_error_garantieProduit td').append(ajax_file( 'ajax_sprintf.php?arg1=contact_error_garantieNomProduit' ));
        }
    }
}

function completeCity( elem, val ) {

    if ( val.length >= 4 ) {

        $.ajax({
            type: 'post',
            data: {cp : val},
            url: path_relative_root+'ajax_autocomplete_city.php',
            success: function( response ){

                globalString = $.parseJSON(response);

                if ( response != "" ) {

                    var lengthSting = globalString.length;
                    var value_return = '<select onchange="' + event + '" id="select_list_' + type + '" name="select_list_' + type + '"><option value="">Choisissez</option>';

                    for (var i = 0; i < lengthSting; i++) {

                        value_return += '<option value="' + globalString[i] + '">' + globalString[i] + '</option>';

                    }

                    value_return += '</select>';

                    $( "#" + elem ).html(value_return);

                    $('.selectStyled').each(function(){
                        $(this).selectStyled();
                    });

                }

            }
        });

    }

}

function ajax_RemoveBasketProd(basket_id, prod_id, qte){
    $.ajax({
        type: 'post',
        data: {
            basket_id: basket_id
            ,prod_id: prod_id
            ,qte: qte
        },
        url: path_relative_root+'ajax_removeBasketProd.php',
        success: function( response ){
            try{
                response = $.parseJSON( response );
            }
            catch( e ){
                console.error( 'parseJSON' );
                return;
            }

            var datas = response.datas;
            var $html = $( datas.html );
            $('#cart_top').html($html);
            CartShow();
            $("#show_top_cart").show();
            $(".cart_product_listing").lionbars();
            $("#shad").hide();
        }
    });
}

function openCloseBloc( elem, id_bloc, class_bloc, is_detail, url_ajax, txt_open, txt_close, class_btn ) {

    var data = {};

    if ( is_detail ) {
        var id = elem.attr('id');
        var type = 1;
        elemId = id.substring(id.indexOf('_')+1);

        if ( class_bloc == 'order_detail' ) {
            type = $('#order_type_'+elemId).val();
            data.cdeId = elemId;
        }
        else {
            data.mId = elemId;
        }

        data.type = type;

        id_bloc = id_bloc + '_' + elemId;
        id_wrapper = 'wrapper' + '_' + elemId;
    }
    else
    {
        id_wrapper = id_bloc.replace('bloc', 'wrapper');
    }

    if (!(elem).hasClass('actif')) {

        if ( is_detail ) {

            $('.'+class_bloc).not($('#'+id_bloc)).slideUp();
            $('.'+class_btn).not(elem).removeClass('actif');
            $('.'+class_btn).not(elem).text(txt_open);

        }

        if ( $('#'+id_bloc).html() == '' ) {

            if ( url_ajax != '' ) {

                $.ajax({
                    type: 'post',
                    url: path_relative_root+url_ajax,
                    data : data,
                    success: function( response ){
                        $('#'+id_bloc).html(response).slideDown();
                        $('#' + id_wrapper).addClass('bloc-opened');
                    }
                });

            }
            else {
                $('#' + id_bloc).slideDown();
            }

        }
        else {
            $('#' + id_bloc).slideDown();
        }

        (elem).addClass('actif');
        $('#' + id_wrapper).addClass('bloc-opened');
        (elem).text(txt_close);
    } else {
        $('.'+class_bloc).slideUp();
        (elem).removeClass('actif');
        $('#' + id_wrapper).removeClass('bloc-opened');
        (elem).text(txt_open);
    }

}
function sendMessage(id) {

    //disable the default form submission

    var mId = id;
    $('#loader_1').show();
    $('#envoyer_rep').addClass('active');

    if ( $('#msg_txt_'+id).val() != '' ) {
        var upload_fail = ajax_file( 'ajax_sprintf.php?arg1=contact_msg_pj_fail' );
        var formData = new FormData($('form#data')[0]);
        $.ajax({
            url: path_relative_root+create_link('message_detail'),
            type: 'POST',
            data: formData,
            async: false,
            cache: false,
            contentType: false,
            processData: false,
            success: function( response ) {
                if (response == '1') {
                    $('#msg_conf_' + id).html(ajax_file( 'ajax_sprintf.php?arg1=contact_msg_envoyer' ));
                    $('#msg_conf_' + id).show();
                    $('#msg_conf_' + id).addClass('active');
                    $("#msg_txt_" + id).val(' ');
                    clearInput('filepj1');
                    $('#loader_1').hide();
                    $('#envoyer_rep').addClass('active');
                    return false;
                }
                else if (response == '3'){
                    clearInput('filepj1');
                    $('#loader_1').hide();
                }
                else {
                    $('#loader_1').hide();
                    $('#msg_conf_' + mId).html(ajax_file( 'ajax_sprintf.php?arg1=contact_msg_fail' ));
                    $('#msg_conf_' + mId).show();
                }
            }
        });
    }
    else{
        $('.loader_reponse_contact').css("display", "none");
        $('#contact_msg_text_vide').css('color','red').fadeIn();
        setTimeout(function() {
            formMessageReset(id);
        }, 5000);
        $('#msg_conf_'+mId).hide();
    }
}

function formMessageReset(id){
    $('#contact_msg_text_vide').fadeOut();
    if (! $('#msg_conf_' + id).hasClass('active') ){
        $('#envoyer_rep').removeClass('active');
    }
}

function clearInput(ctrlId) {

    var old = document.getElementById(ctrlId);
    var newElm = document.createElement('input');
    newElm.type = "file";
    newElm.id = ctrlId;
    newElm.name = old.name;
    newElm.className = old.className;
    newElm.style.paddingLeft = '33px';
    newElm.style.paddingTop = '-2px';
    newElm.style.float = 'left';
    // Put code to copy other attributes as well
    old.parentNode.replaceChild(newElm, old);
}

function cancelMessage(id) {
    $('#msg_txt_'+id).val('');
}

function uploads(){

        var fd = new FormData;
        var file = document.getElementById('files').files[0];
        var mId = document.getElementById('mId').value;
        var xhr = new XMLHttpRequest();
        var url = path_relative_root + create_link('message_detail');

        xhr.file = file; // not necessary if you create scopes like this
        xhr.addEventListener('progress', function(e) {
            var done = e.position || e.loaded, total = e.totalSize || e.total;
            console.log('xhr progress: ' + (Math.floor(done/total*1000)/10) + '%');
        }, false);
        if ( xhr.upload ) {
            xhr.upload.onprogress = function(e) {
                var done = e.position || e.loaded, total = e.totalSize || e.total;
                console.log('xhr.upload progress: ' + done + ' / ' + total + ' = ' + (Math.floor(done/total*1000)/10) + '%');
            };
        }
        xhr.onreadystatechange = function(e) {
            if ( 4 == this.readyState ) {
                console.log(['xhr upload complete', e]);
            }
        };
        xhr.open('post', url , true);
        fd.append('file', file);
        fd.append('message_id', mId);
        fd.append('act','rep');
        xhr.send(fd);

}
function reloadDepartment(){

    var html_dpt = '';

    $.ajax({
        url: path_relative_root+'ajax_get_country_info.php',
        type: 'POST',
        data: 'country='+$('#search_pays').val(),
        success: function( response ) {

            html_dpt = '<option value="">'+response.text+'</option>';

            $.each(response.data, function(num, name){
                html_dpt += '<option value="'+num.substring(1)+'">'+name+'</option>';
            });

            $('#departement').html(html_dpt);
        }
    });
}

function getAllProduct( type ) {

    var value = $('#' + type + '_input').val();
    var min_length = $('#' + type + '_input').data('minlength');

    if (min_length == '')
        min_length = 0;

    // Construction des parametre à passer au script
    var values = { search : value, all_product : true };

    // On recherche à partir du nombre de caractere definie sur le champs
    if ( value.length >= min_length ) {

        $.ajax({
            'type': 'POST',
            'data': values,
            'url': path_relative_root + 'ajax_get_' + type + '.php',
            'async': false,
            'success': function(response) {
                autoCompleteAll(response, type, value);
            }
        });

    }

}

function ajax_achat_express(reference, is_alliance, code_couleur, idprod){

    var $lighbox    = $('#lightbox_achat_express'),
        $shad       = $('#shad');

    $.ajax({

        type: 'get',
        url: path_relative_root+'ajax_achat_express.php?ref='+reference+'&couleur='+code_couleur,
        success: function( response ){

            $lighbox.html('<div class="express_response">'+ response +'</div>');
            openMultiShad('lightbox_achat_express');
            showQteListe('liste_qte_range', idprod, path_relative_root);
        }
    });
}
function showLightBoxSelectionOfferte() {
    
    $.ajax({
        type: 'post',
        url: path_relative_root+'ajax_lightbox_selection_offerte.php',
        success: function( response ){
            var $shad = $('#shad');
            if ($('#selectionOffertePopup').length == 0){
                $("body").append(response);
            }

            var id_produit_offert_unique = $('.ligne_produit_offert').length == 1 ? $('.ligne_produit_offert').data('idproduit'):0;

            if (response.trim() != '') {
                // Si le nombre de selection égale 1 on ajoute le produit directement dans le panier, sinon on affiche la lightBox avec la selection
                if (typeof(nb_selection_offert)  != 'undefined' && nb_selection_offert == 1 && $('.ligne_produit_offert').length == 1 && $("#qteProd_" + id_produit_offert_unique).val() > 0) {
                    $('.ligne_produit_offert').addClass('seleted');
                    ajoutLightBoxSelectionOfferte();
                } else {
                    show_popup('selectionOffertePopup');
                }
                $shad.click(function(){
                    closeMultiShad();
                });
            }

            var windowH = $(window).height();
            var elemH = $('#selectionOffertePopup').height();
            var winTop = $(document).scrollTop();
        }
    });
}

function temporisationLightBoxSelectionOfferte() {
    $('.wrapper_txt', '.bloc_btn_select').hide();
    $('#loaderProdOffert', '.bloc_btn_select').show();
    window.setTimeout('ajoutLightBoxSelectionOfferte()',1000);
}

function ajoutLightBoxSelectionOfferte() {
    var tab_prod_offert = new Array();
    var total_prod_offert_selec = 0;
    var totalProdActiv = 0;
    $('.ligne_produit_offert.seleted').each(function(){
        totalProdActiv++;
        var id_produit = $(this).data('idproduit');
        var taille_prod = $("#sizeProd_"+id_produit).val();
        if ( typeof(taille_prod) == 'undefined' )
            taille_prod = 0;
        var couleur_prod = $("#couleurProd_"+id_produit).val();
        if ( typeof(couleur_prod) == 'undefined' )
            couleur_prod = 0;
        var qte_prod = $("#qteProd_"+id_produit).val();
        
        if ( qte_prod > 0 ) {
            tab_prod_offert.push(new Array( id_produit, taille_prod, couleur_prod, qte_prod ));
            total_prod_offert_selec += parseInt(qte_prod);
        }
        
    });
    
    if ( total_prod_offert_selec > 0 && total_prod_offert_selec <= nb_selection_offert_restant ) {
        var cnt_prod_offert = tab_prod_offert.length;
        $.ajax({
            type: 'post',
            data: { tab_prod_offert: tab_prod_offert },
            url: path_relative_root+'ajax_ajout_selection_offerte.php',
            success: function( response ){
                location.reload();
                $('.wrapper_txt', '.bloc_btn_select').show();
                $('#loaderProdOffert', '.bloc_btn_select').hide();
            }
        });
    }
    else {
        var $wp = $('.wrapper_product').css({position : 'relative'});
        var $alert = $('<div><span></span></div>');
        $wp.before($alert);
        $alert.addClass('alert').css({
            opacity : 0,
            display : 'block',
            position : 'absolute',
            padding : '20px 40px',
            backgroundColor : '#fff',
            border : '1px solid #ccc',
            maxWidth : 500,
            zIndex : 500});
        $('.close',$alert).css({position : 'absolute', top : 5, right : 5,cursor : 'pointer'});
        if (totalProdActiv == 0){
            if (Error1 != undefined && Error1 != ''){
                $('span',$alert).html(Error1);
            }else{
                $('span',$alert).html('Veuillez choisir au moins 1 produit avant d\'ajouter au panier');
            }
        }else{
            if (Error2 != undefined && Error2 != ''){
                $('span',$alert).html(Error2);
            }else{
                $('span',$alert).html('Veuillez choisir toutes les caractéristiques (taille ou couleur) correspondantes aux produits sélectionnés avant d\'ajouter au panier');
            }
        }
        $alert.css({
            margin : '-'+($alert.outerHeight(true) / 2)+'px 0 0 -'+($alert.outerWidth(true) / 2)+'px',
            left : '50%',
            top : '50%'
        });
        $alert.animate({opacity : 1}, 350,function(){
            $alert.css({opacity : ''});
        });
        $('body').click(function (e) {
            if ($alert){
                $alert.css({opacity : 1}).animate({opacity : 0}, 350,function(){
                    $alert.remove();
                });
            }
        });
        $('.wrapper_txt', '.bloc_btn_select').show();
        $('#loaderProdOffert', '.bloc_btn_select').hide();
    }
}

function activeFormProduitOffert( obj, id, event ) {
    var parent = $(obj);
    obj = $('.btn_radio',parent);
    var container = $(obj).parents('.content_product');
    var clickable;
    if ($(event.target).closest('.third_bloc_produit_offert').length == 0){
        clickable = true;
    }else{
        clickable = false;
    }
    if (clickable == true){
        if (!parent.hasClass('disable')){
            if ( obj.hasClass('actif') ){
                stop_prod_offert = false;
                $( '#selectionOffertePopup .ligne_produit_offert .third_bloc_produit_offert' ).each(function(){
                    $(this).parent().removeClass('inactif');
                });
                obj.removeClass('actif');
                parent.removeClass('seleted');
                $("#form_produit_offert_"+id).stop(true,true).css({display : 'block', opacity : 1}).animate({opacity : 0},function(){
                    $("#form_produit_offert_"+id).css({opacity : '',display : 'none'});
                    $("#liste_qte_"+id).html('');
                });
                if (typeof nbrGiftProd == 'number' ){
                    nbrGiftProd++;
                    $('#nb_prod_selection_offerte_lightbox').html(nbrGiftProd);
                }
                if (nbrGiftProd == 0) {
                    $('#selectionOffertePopup .btn_container').removeClass('disable_valider');
                }
            }
            else {
                if ( !stop_prod_offert ) {
                    showQteListeGamme('liste_qte_'+id, id, path_relative_root);
                    $("#form_produit_offert_"+id).stop(true,true).css({display : 'block', opacity : 0}).animate({opacity : 1},function(){
                        $("#form_produit_offert_"+id).css({opacity : ''});
                    });
                    obj.addClass('actif');
                    parent.addClass('seleted');
                    $('.selectStyled').each(function(){
                        $(this).selectStyled();
                    });
                    if (typeof nbrGiftProd == 'number' ){
                        nbrGiftProd--;
                        $('#nb_prod_selection_offerte_lightbox').html(nbrGiftProd);
                    }
                    if (nbrGiftProd == 0) {
                        $('#selectionOffertePopup .btn_container').removeClass('disable_valider');
                    }
                }
            }
            if (typeof nbrGiftProd == 'number' && nbrGiftProd == 0){
                $('.ligne_produit_offert').each(function(){
                    if (!$(this).hasClass('seleted')){
                        $(this).addClass('disable');
                        $(this).stop(true).css({display : 'block', opacity : 1}).animate({opacity : 0.4},300);
                    }
                });
            }else{
                $('.ligne_produit_offert').each(function(){
                    if ($(this).hasClass('disable')){
                        $(this).removeClass('disable');
                        $(this).stop(true).css({display : 'block'}).animate({opacity : 1},300,function(){
                            $(this).css({opacity : ''});
                        })
                    }else{
                        $(this).removeClass('disable');
                    }
                });
            }
        }
    }
}