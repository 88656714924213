if (!document.getElementById('shad')) {

    document.write('<div id="shad" style="display:none;">&nbsp;</div>');
}

function onLazyImgLoad(parent) {

    window.setTimeout(function() {

        $(parent).addClass('loaded');
    }, 300);

    this.width = this.naturalWidth;
    this.height = this.naturalHeight;

    parent.appendChild(this);
}

function loadLazyPic() {

    var new_img = new Image();

    new_img.addEventListener('load', onLazyImgLoad.bind(new_img, this));

    new_img.src = this.getAttribute('data-src');
}

$(document).ready(function() {

    // if (window.skrollr !== undefined) {

    // 	window.skrollr = skrollr.init();
    // }

    $('.produit_gondole').each(function() {

        child = $(this).children('.menu_tg_holder');

        if (child.length > 0) {

            child.rand(child.length - 1).remove();
            child.removeClass('cache');
        }
    });

    $("#connexion_top").hover(function() {

        $(this).children("#wrapper_infos_client").show();
    }, function() {

        if (document.activeElement != undefined) {

            if (document.activeElement.type != 'text' && document.activeElement.type != 'password') {

                $(this).children("#wrapper_infos_client").hide();
            }
        }
    });

    $('.passlost').click(function() {

        $('#connexionTopb .content').animate({ height: $('.forgotPwd').height() }, 600);

        $('.auth').fadeOut(600, function() {

            $('.forgotPwd').fadeIn(600);
        });
    });

    $('#lostpwdTopSucces2 a').click(function() {

        $('#connexionTopb .content').animate({ height: $('.auth').height() }, 600);

        $('.forgotPwd').fadeOut(600, function() {

            $('.auth').fadeIn(600);
        });
    });

    if ($('body').hasClass('home') == false) { // Do Not initialize go to top on homepage.
        var obj = $('.gotop');
        var totop = $('.backtotop', obj);

        var tTw = totop.width();
        var tTh = totop.height();

        obj.css({ width: tTw, height: tTh })
        obj.toTop({
            speed: 400,
            minWidth: 998 + tTw,
            elemBotAlign: '#copyright_content'
        });

        obj.css({ position: 'fixed', bottom: 0 });

        function leftPos() {
            var ctnpos = ($(window).width() - 1000) / 2;
            var ctnW = 998;
            obj.css({ right: 10 });
            /*
			var windowW = $(window).width();
			if(windowW < 1040) {
				obj.css({position : 'absolute', left : ctnpos + ctnW - 65, zIndex: 5000});
			}
            */
        }
        leftPos();
        $(window).resize(function() {
            leftPos();
        });

        totop.after('<div class="backtotop hover"></div>');
        var totopHov = $('.backtotop.hover');
        totopHov.css({ position: 'absolute', top: 0, right: 0, backgroundPosition: 'left top', display: 'none' });
        obj.bind({
            mouseenter: function() {
                totopHov.stop().css({ display: 'block', opacity: 0 });
                totopHov.animate({ opacity: 1 }, 200, function() {
                    totopHov.css({ display: '', opacity: '' });
                });
            },
            mouseleave: function() {
                totopHov.stop().css({ display: 'block', opacity: 1 });
                totopHov.animate({ opacity: 0 }, 200, function() {
                    totopHov.css({ display: 'none', opacity: '' });
                });
            }
        });
    }

    try {
        WPApi.prototype.setOption('arrowOffsetLeft', -22);
    } catch (e) {}

    $(".ui-datepicker").append("<div class='closeReservation' onclick='closeReservation('ui-datepicker-div');''><span></span></div>");
});