(function (path_relative_root) {

	"use strict";

	function onMyAccountLoad() {

		var label, endWidth;

		this.target.innerHTML = this.render;

		label = document.getElementById("entete_connexion");

		label.style.width = "auto";

		endWidth = getComputedStyle(label).width; 

		label.style.width = 0;
		label.style.transition = "width 0.4s ease-out, opacity 0.4s linear 0.3s";

		var cart_unit = new UnitCounter('cart_unit', 0, Number(this.view_data.cart_amount_u));
		var cart_cent = new UnitCounter('cart_cent', 0, Number(this.view_data.cart_amount_d), zeroPad);

		document.getElementById('cart_length').innerHTML = '( ' + this.view_data.cart_length + ' )';

		if (this.view_data.wishlist_length > 0) {

			document.getElementById('wishlist_length').innerHTML = this.view_data.wishlist_length;

			$('#btn_top_wishlist').slideDown(400);
		}

		window.setTimeout(function () {

			label.style.width = endWidth;
			label.style.opacity = 1;

			window.setTimeout(function () {

				label.style.transition = "all 0 ease 0";
				label.style.width = "auto";
			}, 700);
		}, 50);
	}

	function loadMyAccount() {
		var my_account = new Partial("topMenuConnexionButton", path_relative_root + "my_account_data.php", "my_account_tmpl", onMyAccountLoad);
	}

	function loadPartials() {

		if (window.load_partials === undefined || window.load_partials) {

			loadMyAccount();
		}

		window.removeEventListener("load", loadPartials);
	}

	window.addEventListener("load", loadPartials);

}(window.path_relative_root));