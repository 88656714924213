/**
 * New functions (they are supposed to be at least a little bit cleaner than legacy code)
 */

// Emulates placeholder behavior
// Also cleans trailing whitespaces in value
function checkPlaceholder(evt) {

    var dat = $(this).data('placeholder'),
        val = this.value.trim();

    if (evt.type === 'focusin' && val === dat) {

        this.value = '';
    } else if (evt.type = 'focusout') {

        if (val === '') {

            this.value = dat;
        } else {

            this.value = val;
        }
    }
}

// Below lies the legacy code (enter at your own risk)
function checkdate(d, m, y) {
    return m > 0 && m < 13 && y > 0 && y < 32768 && d > 0 && d <= (new Date(y, m, 0)).getDate();
}
var nav_UA = window.navigator.userAgent.split('/'),
    nav_ver = parseFloat(nav_UA.pop()),
    nav_name = nav_UA.pop();

if ((nav_name.indexOf('safari') !== -1 && nav_ver <= 525) || (nav_name.indexOf('firefox') !== -1 && nav_ver === 1.8)) {
    function _dom_trackActiveElement(evt) {
        if (evt && evt.target) {
            document.activeElement = evt.target == document ? null : evt.target;
        }
    }

    function _dom_trackActiveElementLost(evt) {
        document.activeElement = null;
    }
    if (!document.activeElement) {
        document.addEventListener("focus", _dom_trackActiveElement, true);
        document.addEventListener("blur", _dom_trackActiveElementLost, true);
    }
}
/**/
var id_select = '';

// Affiche un message d'erreur
function ErreurMsg(idObj, msg) {
    var message = document.getElementById(idObj);
    message.style.color = 'red';
    message.innerHTML = msg;
}
// -----------------------------------------------------------------

// D�finit les classes initiales pour les elements d'un formulaire
function setInitialClassName(elementIdArray) {
    for (i = 0; i < elementIdArray.length; i++) {
        var elementInput = document.getElementById(elementIdArray[i]);
        var elementLabel = document.getElementById(elementIdArray[i] + 'Label');
        if (elementInput && elementInput.type != 'hidden') {
            elementInput.className = 'inputForm';
            if (elementLabel)
                elementLabel.className = 'labelForm';
        }
    }
}

// D�finit les classes initiales pour les elements de date d'un formulaire
function setInitialDateClassName(elementIdArray) {
    for (i = 0; i < elementIdArray.length; i++) {
        var elementInputDay = document.getElementById(elementIdArray[i] + 'J');
        var elementInputMonth = document.getElementById(elementIdArray[i] + 'M');
        var elementInputYear = document.getElementById(elementIdArray[i] + 'A');
        var elementLabel = document.getElementById(elementIdArray[i] + 'Label');
        if (elementInputDay && elementInputDay.type != 'hidden' &&
            elementInputMonth && elementInputMonth.type != 'hidden' &&
            elementInputYear && elementInputYear.type != 'hidden') {
            elementInputDay.className = 'inputForm';
            elementInputMonth.className = 'inputForm';
            elementInputYear.className = 'inputForm';
            if (elementLabel)
                elementLabel.className = 'labelForm';
        }
    }
}

// V�rification d'un bouton radio
function verif_radio(elementID, fieldname) {
    if (elementID == 'clientCiviliteM' || elementID == 'clientCiviliteMme' || elementID == 'clientCiviliteMlle')
        elementID = 'clientCivilite';
    var objElement = document.getElementsByName(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurText = document.getElementById('erreur_msg');
    var trouve = false;
    $(objElement).removeData('data-error-text').removeAttr('data-error-text');
    if (elementID) {
        objElement.className = 'inputForm';
        objLabel.className = 'labelForm';
        $("input[name='" + elementID + "']").each(function(idx) {
            if ($(this).is(':checked') == true) {
                objElement.className = 'inputForm';
                objLabel.className = 'labelForm';
                trouve = true;
            }
        });
        if (!trouve) {

            if (elementID == 'clientCivilite') {
                t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_error_civilite&arg2=' + fieldname);
            } else {
                t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_error_thanks&arg2=' + fieldname);
            }

            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            return false;
        }
    }
    return true;
}

function verif_price(e, t) {
    var a = document.getElementById(e),
        r = document.getElementById(e + "Label"),
        l = (document.getElementById("mes_err_fieldset"), document.getElementById("erreur_msg"));
    if ($(a).removeData("data-error-text").removeAttr("data-error-text"), "" == a.value) return t_error = '<p class="title_toolltip">' + ajax_file("ajax_sprintf.php?arg1=" + t) + " :</p>" + ajax_file("ajax_sprintf.php?arg1=js_thanks_precise&arg2=" + t), $(a).data("data-error-text", t_error), l.innerHTML = t_error, a.className = "inputErr", r.className = "labelErr", !1;
    var n = validate_price(a.value);
    return 0 == n ? (t_error = '<p class="title_toolltip">' + ajax_file("ajax_sprintf.php?arg1=" + t) + " :</p>" + ajax_file("ajax_sprintf.php?arg1=js_thanks_precise&arg2=" + t), $(a).data("data-error-text", t_error), l.innerHTML = t_error, a.className = "inputErr", r.className = "labelErr", !1) : (a.className = "inputForm", r.className = "labelForm", !0)
}

function validate_price(e) {
    return /^[\d\.,]+$/.test(e)
}

// Verificartion d'une case coche
function verif_checkbox(elementName, fieldname) {
    var objElement = document.getElementsByName(elementName);
    var objLabel = document.getElementById(elementName.slice(0, -2) + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (elementName) {
        objElement.className = 'inputForm';
        objLabel.className = 'labelForm';
        for (i = 0; i < objElement.length; i++)
            if (objElement[i].checked == true) {
                objElement.className = 'inputForm';
                objLabel.className = 'labelForm';
                return true;
            }
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_thanks&arg2=' + fieldname);
        objElement.className = 'inputErr';
        objLabel.className = 'labelErr';
        return false;
    }
    return true;
}

function verif_checkbox_index(elementName, fieldname, pIndex) {
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (document.prodForm.visuStatutDetail1) {
        if (document.prodForm.visuStatutDetail1[0].checked == true) {
            return true;
        }
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_photo_validate');
        return false;
    } else {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_photo_validate');
        return false;
    }
}

function verif_zip(elementZipID, elementCountryID, fieldname, nb) {
    var objZipElement = document.getElementById(elementZipID);
    var objCountryElement = document.getElementById(elementCountryID);
    var objLabel = document.getElementById(elementZipID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');

    var idPays = objZipElement.value.substr(0, 2);

    if (objCountryElement.value == "DO" && idPays != '97') {

        erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_error_zip_special_1&arg2=' + fieldname + '&arg3=97');
        $(objZipElement).addClass('inputErr');
        $(objLabel).addClass('labelErr');
        return false;
    } else if (objCountryElement.value == "MO" && idPays != '98') {

        erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_error_zip_special_1&arg2=' + fieldname + '&arg3=98');
        $(objZipElement).addClass('inputErr');
        $(objLabel).addClass('labelErr');
        return false;
    } else if (objCountryElement.value == "TO" && idPays != '97' && idPays != '98') {

        erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_error_zip_special_1&arg2=' + fieldname + '&arg3=97ou98');
        $(objZipElement).addClass('inputErr');
        $(objLabel).addClass('labelErr');
        return false;
    } else if ((objCountryElement.value != "DO" && objCountryElement.value != "TO" && objCountryElement.value != "MO") && (idPays == '97' || (idPays == '98' && objCountryElement.value != "FR"))) {

        erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_error_zip_special_2&arg2=' + fieldname + '&arg3=' + idPays);
        $(objZipElement).addClass('inputErr');
        $(objLabel).addClass('labelErr');
        return false;
    }

    $(objZipElement).removeClass('inputForm');
    $(objLabel).removeClass('labelErr');
    return true;
}

function verif_num(elementID, fieldname, nb) {
    var flg = 0;
    var num = "0123456789.,";
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurText = document.getElementById('erreur_msg');
    var point = 0;
    var virgule = 0;

    if (objElement.value == "") {
        erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_thanks_precise&arg2=' + fieldname);
        $(objElement).addClass('inputErr');
        $(objLabel).addClass('labelErr');

        return false;
    } else {
        if (objElement.value.length < nb) {
            erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_at_least_x_digits&arg2=color:red;&arg3=' + fieldname + '&arg4=' + nb);
            $(objElement).addClass('inputErr');
            $(objLabel).addClass('labelErr');
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);
            if (num.indexOf(test) < 0)
                flg++;
        }
        if (flg > 0) {
            erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_wrong_input_num&arg2=' + fieldname);
            $(objElement).addClass('inputErr');
            $(objLabel).addClass('labelErr');
            flg = 0;
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);
            if (test == '.') {
                point = point + 1;
            }
            if (test == ',') {
                virgule = virgule + 1;
            }
        }
        var tot = point + virgule;
        if (point > 1 || virgule > 1 || tot > 1) {
            erreurText.innerHTML = erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_wrong_input_num&arg2=' + fieldname);
            $(objElement).addClass('inputErr');
            $(objLabel).addClass('labelErr');
            flg = 0;
            return false;
        }

    }
    $(objElement).removeClass('inputErr');
    $(objElement).removeClass('labelErr');
    return true;
}

function verif_portable(elementID, fieldname) {
    return verif_telephone(elementID, fieldname, true);
}

function verif_telephone(elementID, fieldname, is_mobile) {

    var objElementHidden = document.getElementById(elementID + 'Hidden');
    var objElement = document.getElementById(elementID);
    var erreurText = document.getElementById('erreur_msg');

    if (objElementHidden !== null) {

        if (elementID === 'telephone' && document.getElementById('mobile') === null) {

            var value = $(objElement).intlTelInput("getNumber");
            var type = $(objElement).intlTelInput("getNumberType");

            if ($(objElement).intlTelInput("isValidNumber") !== false && type === 0) {
                $(objElementHidden).val(value);
            } else if ($(objElement).intlTelInput("isValidNumber") !== false && type === 1) {
                $('#mobileHidden').val(value);
            }

            if ($(objElement).intlTelInput("isValidNumber") === false) {
                return false;
            }

            return true;
        }
    }

    if ($.trim($(objElement).val()).length > 0) {
        if ($(objElement).intlTelInput("isValidNumber") === false || ((is_mobile && $(objElement).intlTelInput("getNumberType") === 0)) || (is_mobile == false && $(objElement).intlTelInput("getNumberType") === 1)) {
            erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg2=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_error_phone&arg2=' + fieldname);
            return false;
        } else {
            $(objElementHidden).val($(objElement).intlTelInput("getNumber"));
        }

        return true;
    }

    return false;
}

function verif_ref_interne(elementID, fieldname) {
    var flg = 0;
    var alphanum = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (objElement.value == "") {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_thanks&arg2=' + fieldname);
        objElement.className = 'inputErr';
        objLabel.className = 'labelErr';
        objElement.focus();
        return false;
    } else {
        var space_after = objElement.value.lastIndexOf(" ");
        var space_before = objElement.value.indexOf(" ");
        if ((((space_after + 1) == objElement.value.length) || ((space_before + 1) == 1)) && (objElement.value.length <= 9)) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars_space&arg2=' + fieldname + '&arg3=9');
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            return false;
        }
        if (objElement.value.length < 9) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=9&arg3=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            return false;
        }
        if (objElement.value.length > 14) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_ref_14_chars&arg2=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);

            if (alphanum.indexOf(test) < 0)
                flg++;
        }
        if (flg > 0) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_wrong_input_alphanum&arg2=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            flg = 0;
            return false;
        }
    }
    objElement.className = 'inputForm';
    objLabel.className = 'labelForm';
    return true;
}

function verif_alphanum_codepromo(elementID, fieldname) {
    var flg = 0;
    var alphanum = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ\,\-_/\.<>()%:;";
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (objElement.value == "") {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_thanks&arg2=' + fieldname);
        objElement.className = 'inputErr';
        objLabel.className = 'labelErr';
        objElement.focus();
        return false;
    } else {
        var space_after = objElement.value.lastIndexOf(" ");
        var space_before = objElement.value.indexOf(" ");
        if ((((space_after + 1) == objElement.value.length) || ((space_before + 1) == 1)) && (objElement.value.length <= 4)) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars_space&arg2=' + fieldname + '&arg3=4');
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            return false;
        }
        if (objElement.value.length < 4) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=4&arg3=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);

            if (alphanum.indexOf(test) < 0)
                flg++;
        }
        if (flg > 0) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_wrong_input_alphanum&arg2=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            flg = 0;
            return false;
        }
    }
    objElement.className = 'inputForm';
    objLabel.className = 'labelForm';
    return true;
}

function verif_empty(elementID, fieldname) {
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurText = document.getElementById('erreur_msg');
    $(objElement).removeData('data-error-text').removeAttr('data-error-text');

    if (objElement.value == "") {
        t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_thanks_precise&arg2=' + fieldname);
        erreurText.innerHTML = t_error;
        $(objElement).data('data-error-text', t_error);
        $(objElement).addClass('inputErr');
        $(objLabel).addClass('labelErr');
        $(objElement).removeClass('inputForm');
        $(objLabel).removeClass('labelForm');
        return false;
    }
    $(objElement).addClass('inputForm');
    $(objLabel).addClass('labelForm');
    $(objElement).removeClass('inputErr');
    $(objLabel).removeClass('labelErr');
    return true;
}

function verif_alphanum(elementID, fieldname, isMin, length) {
    var flg = 0;
    var alphanum = decodeURIComponent("%C2%B0") + "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ\,\'\"\-_/\.<>\n\r()%:; " + decodeURIComponent("%C3%A2%C3%A0%C3%A4%C3%A9%C3%A8%C3%AB%C3%AA%C3%AC%C3%AF%C3%AE%C3%B6%C3%B4%C3%B2%C3%B9%C3%BC%C3%BB%C3%A7&%C3%84%C3%82%C3%80%C3%89%C3%88%C3%8B%C3%8A%C3%8C%C3%8F%C3%8E%C3%96%C3%94%C3%92%C3%99%C3%9C%C3%9B%C3%87");
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurText = document.getElementById('erreur_msg');
    $(objElement).removeData('data-error-text').removeAttr('data-error-text');
    if (objElement.value == "") {
        t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_thanks_precise&arg2=' + fieldname);
        $(objElement).data('data-error-text', t_error);
        erreurText.innerHTML = t_error;
        objElement.className = 'inputErr';
        if (objLabel)
            objLabel.className = 'labelErr';
        return false;
    } else {
        var space_after = objElement.value.lastIndexOf(" ");
        var space_before = objElement.value.indexOf(" ");
        if ((((space_after + 1) == objElement.value.length) || ((space_before + 1) == 1)) && (objElement.value.length <= 3)) {
            t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars_space&arg2=' + fieldname + '&arg3=3');
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            objElement.className = 'inputErr';
            if (objLabel)
                objLabel.className = 'labelErr';
            return false;
        }
        if (isMin == false && objElement.value.length < 3) {
            t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=3&arg3=' + fieldname);
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            objElement.className = 'inputErr';
            if (objLabel)
                objLabel.className = 'labelErr';
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);

            if (alphanum.indexOf(test) < 0) {
                flg++;
            }
        }
        if (flg > 0) {
            t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_wrong_input_alphanum&arg2=' + fieldname);
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            objElement.className = 'inputErr';
            if (objLabel)
                objLabel.className = 'labelErr';
            flg = 0;
            return false;
        }
        if (isMin == true && objElement.value.length > length) {
            t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_too_long_alphanum&arg2=' + fieldname + '&arg3=' + length);
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            objElement.className = 'inputErr';
            if (objLabel)
                objLabel.className = 'labelErr';
            return false;
        }
    }
    objElement.className = 'inputForm';
    if (objLabel)
        objLabel.className = 'labelForm';
    return true;
}

function verif_alphanumadresse(elementID, fieldname, isMin, length) {
    var flg = 0;
    var alphanum = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ\,\'\"_/\.<>\n\r()%:; " + decodeURIComponent("%C3%A2%C3%A0%C3%A9%C3%A8%C3%AB%C3%AAi%C3%AF%C3%AE%C3%B6%C3%B4%C3%B9%C3%BC%C3%BB%C3%A7%26%C3%84%C3%82%C3%80%C3%89%C3%88%C3%8B%C3%8AI%C3%8F%C3%8E%C3%96%C3%94%C3%99%C3%9C%C3%9B%C3%87%3F!%E2%82%AC-");
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurText = document.getElementById('erreur_msg');
    $(objElement).removeData('data-error-text').removeAttr('data-error-text');
    if (objElement.value == "") {
        t_error = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_thanks_precise&arg2=' + fieldname);
        $(objElement).data('data-error-text', t_error);
        erreurText.innerHTML = t_error;
        objElement.className = 'inputErr';
        if (objLabel)
            objLabel.className = 'labelErr';
        return false;
    } else {
        var space_after = objElement.value.lastIndexOf(" ");
        var space_before = objElement.value.indexOf(" ");
        if ((((space_after + 1) == objElement.value.length) || ((space_before + 1) == 1)) && (objElement.value.length <= 3)) {
            t_error = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_at_least_n_chars_space&arg2=' + fieldname + '&arg3=3');
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            objElement.className = 'inputErr';
            if (objLabel)
                objLabel.className = 'labelErr';
            return false;
        }
        if (isMin == false && objElement.value.length < 3) {
            t_error = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=3&arg3=' + fieldname);
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            objElement.className = 'inputErr';
            if (objLabel)
                objLabel.className = 'labelErr';
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);

            if (alphanum.indexOf(test) < 0)
                flg++;
        }
        if (flg > 0) {
            t_error = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_wrong_input_alphanum&arg2=' + fieldname);
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            objElement.className = 'inputErr';
            if (objLabel)
                objLabel.className = 'labelErr';
            flg = 0;
            return false;
        }
        if (isMin == true && objElement.value.length > length) {
            t_error = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_too_long_alphanum&arg2=' + fieldname + '&arg3=' + length);
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            return false;
        }
    }
    objElement.className = 'inputForm';
    if (objLabel)
        objLabel.className = 'labelForm';
    return true;
}

function verif_textarea(elementID, fieldname) {
    var flg = 0;
    var alphanum = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ,'\"\\n\r-_/.()%:; �����i�������";
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (objElement.value == "") {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_thanks&arg2=' + fieldname);
        objElement.className = 'textareaErr';
        objLabel.className = 'labelErr';
        objElement.focus();
        return false;
    } else {
        var space_after = objElement.value.lastIndexOf(" ");
        var space_before = objElement.value.indexOf(" ");
        if ((((space_after + 1) == objElement.value.length) || ((space_before + 1) == 1)) && (objElement.value.length <= 3)) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars_space&arg2=' + fieldname + '&arg3=3');
            objElement.className = 'textareaErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            return false;
        }
        if (objElement.value.length < 3) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=3&arg3=' + fieldname);
            objElement.className = 'textareaErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);
            if (alphanum.indexOf(test) < 0)
                flg++;
        }
        if (flg > 0) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_wrong_input_alphanum&arg2=' + fieldname);
            objElement.className = 'textareaErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            flg = 0;
            return false;
        }
    }
    objElement.className = 'textarea';
    objLabel.className = 'labelForm';
    return true;
}

function verif_textarea2(elementID, fieldname) {
    var flg = 0;
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (objElement.value == "") {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_thanks&arg2=' + fieldname);
        objElement.className = 'textareaErr';
        objLabel.className = 'labelErr';
        return false;
    }
    if (objElement.value.length < 3) {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=3&arg3=' + fieldname);
        objElement.className = 'textareaErr';
        objLabel.className = 'labelErr';
        return false;
    }
    objElement.className = 'textarea';
    objLabel.className = 'labelForm';
    return true;
}

function verif_mail(elementID, fieldname, is_spe) {

    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurText = document.getElementById('erreur_msg');
    var mail = new RegExp("^[a-z0-9_-]+([.]?[a-z0-9_-]{1,})*@([a-z0-9_-]{2,}[.])+[a-z]{2,4}$", "gi");

    path = '';
    if (typeof(path_relative_root) != 'undefined')
        path += path_relative_root;

    if (mail.exec(objElement.value) == null) {
        if (typeof(is_spe) == 'undefined') {
            var translations = ajax_file(path + 'ajax_sprintf.php?arg0=%s|%s' +
                '&arg1=' + fieldname +
                '&arg2=js_wrong_input_mail'
            );
            translations = translations.split("|");
            type_champ = translations[0];
            type_message = translations[1];

            erreurText.innerHTML = '<p class="title_toolltip">' + type_champ + ' :</p>' + type_message;
        }
        $(objElement).addClass('inputErr');
        $(objLabel).addClass('labelErr');
        return false;

    }
    $(objElement).removeClass('inputErr');
    $(objLabel).removeClass('labelErr');
    return true;
}

function verif_alpha(elementID, fieldname) {
    var flg = 0;
    var alphanum = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ,'\"-_/.ééÈèËëÊêÔôÎîÛûÂâÏïÄÖöäÜüÀàÙùÇç&n " + decodeURIComponent("%C3%A0%C3%A9%C3%A8%C3%AB%C3%AAi%C3%AF%C3%AE%C3%B6%C3%B4%C3%B9%C3%BC%C3%BB%C3%A7%C3%A4%C3%84%C3%82%C3%80%C3%89%C3%88%C3%8B%C3%8AI%C3%8F%C3%8E%C3%96%C3%94%C3%99%C3%9C%C3%9B%C3%87%C2%A0 ");
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurText = document.getElementById('erreur_msg');
    $(objElement).removeData('data-error-text').removeAttr('data-error-text');
    if (objElement.value == "") {
        t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_thanks_precise&arg2=' + fieldname);
        $(objElement).data('data-error-text', t_error);
        erreurText.innerHTML = t_error;
        objElement.className = 'inputErr';
        objLabel.className = 'labelErr';
        return false;
    } else {
        var space_after = objElement.value.lastIndexOf(" ");
        var space_before = objElement.value.indexOf(" ");
        if ((((space_after + 1) == objElement.value.length) || ((space_before + 1) == 1)) && (objElement.value.length <= 2)) {
            t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars_space&arg2=' + fieldname + '&arg3=2');
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            return false;
        }
        if (objElement.value.length < 2) {
            t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=2&arg3=' + fieldname);
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);
            if (alphanum.indexOf(test) < 0)
                flg++;
        }
        if (flg > 0) {
            t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_wrong_input_alpha&arg2=' + fieldname);
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            flg = 0;
            return false;
        }
    }
    objElement.className = 'inputForm';
    objLabel.className = 'labelForm';
    return true;
}

function verif_format_date(elementID, fieldname) {
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (objElement.value != '') {
        var regDateVente = new RegExp("^(\\d{2})/(\\d{2})/(\\d{2})$", "gi");
        if (regDateVente.exec(objElement.value) == null) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_date_1&arg2=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            return false;
        }
    } else {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_date_2&arg2=' + fieldname);
        objElement.className = 'inputErr';
        objLabel.className = 'labelErr';
        return false;
    }
    objElement.className = 'inputForm';
    objLabel.className = 'labelForm';
    return true;
}

function verif_format_date2(elementID, fieldname) {
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (objElement.value != "") {
        var regDateVente = new RegExp("^(\\d{2})/(\\d{2})/(\\d{4})$", "gi");
        if (regDateVente.exec(objElement.value) == null) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_date_1&arg2=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            return false;
        }
    } else {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_date_2&arg2=' + fieldname);
        objElement.className = 'inputErr';
        objLabel.className = 'labelErr';
        return false;
    }
    objElement.className = 'inputForm';
    objLabel.className = 'labelForm';
    return true;
}

function verif_format_date_select(elementID, fieldname) {
    var objElementJ = document.getElementById(elementID + 'J');
    var objElementM = document.getElementById(elementID + 'M');
    var objElementA = document.getElementById(elementID + 'A');
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (objElementJ.value == "" || objElementM.value == "" || objElementA.value == "") {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_thanks_precise&arg2=' + fieldname);
        objElementJ.className = 'inputErr';
        objElementM.className = 'inputErr';
        objElementA.className = 'inputErr';
        objLabel.className = 'labelErr';
        return false;
    }
    objElementJ.className = 'inputForm';
    objElementM.className = 'inputForm';
    objElementA.className = 'inputForm';
    objLabel.className = 'labelForm';
    return true;
}

function verif_format_majorite(elementID, fieldname) {
    var objElementJ = document.getElementById(elementID + 'J');
    var objElementM = document.getElementById(elementID + 'M');
    var objElementA = document.getElementById(elementID + 'A');
    var date = new Date();
    var annee = date.getFullYear();
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (objElementA.value > (annee - 18)) {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_majorite');
        objElementJ.className = 'inputErr';
        objElementM.className = 'inputErr';
        objElementA.className = 'inputErr';
        objLabel.className = 'labelErr';
        return false;
    }
    objElementJ.className = 'inputForm';
    objElementM.className = 'inputForm';
    objElementA.className = 'inputForm';
    objLabel.className = 'labelForm';
    return true;
}

function verif_selected(elementID, fieldname) {
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurText = document.getElementById('erreur_msg');
    if (objElement) {
        var objLabel = document.getElementById(elementID + 'Label');
        if (objElement.value == "") {
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_select&arg2=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            return false;
        } else {
            objElement.className = 'inputForm';
            objLabel.className = 'labelForm';
            return true;
        }
    }
    return true;
}

function verif_selected_date(elementID, fieldname) {
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurText = document.getElementById('erreur_msg');
    var objElementJ = document.getElementById(elementID + 'J');
    var objElementM = document.getElementById(elementID + 'M');
    var objElementA = document.getElementById(elementID + 'A');
    if (objElementJ && objElementM && objElementA) {
        if (objElementJ.value == "" || objElementM.value == "" || objElementA.value == "") {
            erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_thanks_precise&arg2=' + fieldname);
            objLabel.className = 'labelErr';
            return false;
        } else {
            objLabel.className = 'labelForm';
            return true;
        }
    }
    return true;
}

function verif_selected2(elementID, fieldname) {
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (objElement) {
        var objLabel = document.getElementById(elementID + 'Label');
        if (objElement.value == "") {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_select&arg2=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            return false;
        } else {
            objElement.className = 'inputForm';
            objLabel.className = 'labelForm';
            return true;
        }
    } else {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_select&arg2=' + fieldname);
        return false;
    }
    return true;
}

function verif_categ(formID) {
    var objForm = document.getElementById(formID);
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (objForm.prodCadeau) {
        if (objForm.prodCadeau.checked == false && !objForm.prodCateg_0.selectedIndex) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_categ_1');
            return false;
        }
        if (objForm.prodCadeau.checked == false &&
            objForm.prodCateg_0.selectedIndex &&
            !objForm.prodSousCateg_0.selectedIndex) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_categ_1');
            return false;
        }
        if (objForm.prodCadeau.checked == false &&
            objForm.prodCateg_0.selectedIndex &&
            objForm.prodSousCateg_0.selectedIndex &&
            !objForm.prodObjet_0.selectedIndex) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_categ_1');
            return false;
        }
    } else {
        if (!objForm.prodCateg_0.selectedIndex) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_categ_2');
            return false;
        }
        $errTMP = false;
        for (x = 0; x <= 4; x++) {
            if (eval("objForm.prodCateg_" + x + ".selectedIndex"))
                if (!eval('objForm.prodObjet_' + x))
                    $errTMP = true;
                else
            if (!eval('objForm.prodObjet_' + x + '.selectedIndex'))
                $errTMP = true;
        }
        if ($errTMP == true) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_categ_3');
            return false;
        }
    }
    return true;
}

var indexImg = 0;

function nextImage(elementID, imgPath) {
    if (imgPath.length > 0) {
        indexImg++;
        if (indexImg >= imgPath.length)
            indexImg = 0;
        MM_swapImage(elementID, '', imgPath[indexImg], 1);
    }
}

function prevImage(elementID, imgPath) {
    if (imgPath.length > 0) {
        indexImg--;
        if (indexImg < 0)
            indexImg = imgPath.length - 1;
        MM_swapImage(elementID, '', imgPath[indexImg], 1);
    }
}

function NewWindow(mypage, myname, w, h, scroll) {
    LeftPosition = (screen.width) ? (screen.width - w) / 2 : 0;
    TopPosition = (screen.height) ? (screen.height - h) / 2 : 0;
    settings =
        'height=' + h + ',width=' + w + ',top=' + TopPosition + ',left=' + LeftPosition + ',scrollbars=' + scroll + ',resizable,';
    win = window.open(mypage, myname, settings);
}

function preg_replace(array_pattern, array_pattern_replace, my_string) {
    var new_string = String(my_string);
    for (i = 0; i < array_pattern.length; i++) {
        var reg_exp = RegExp(array_pattern[i], "gi");
        var val_to_replace = array_pattern_replace[i];
        new_string = new_string.replace(reg_exp, val_to_replace);
    }
    return new_string;
}

function verif_num_bandeau(elementID, fieldname) {
    var flg = 0;
    var num = "0123456789.,";
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    var point = 0;
    var virgule = 0;
    if (objElement.value == "") {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_thanks_precise&arg2=' + fieldname);
        objElement.className = 'inputErr';
        objLabel.className = 'labelErr';
        objElement.focus();
        return false;
    } else {
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);
            if (num.indexOf(test) < 0)
                flg++;
        }
        if (flg > 0) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_wrong_input_num_bandeau&arg2=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            flg = 0;
            return false;
        }
    }
    objElement.className = 'inputForm';
    objLabel.className = 'labelForm';
    return true;
}

function verif_etage(elementID, fieldname, nb) {
    var flg = 0;
    var num = "0123456789rdcRDC.";
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    var point = 0;
    var virgule = 0;
    if (objElement.value == "") {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_thanks_precise&arg2=' + fieldname);
        objElement.className = 'inputErr';
        objLabel.className = 'labelErr';
        objElement.focus();
        return false;
    } else {
        if (objElement.value.length > nb) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=' + nb + '&arg3=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);
            if (num.indexOf(test) < 0)
                flg++;
        }
        if (flg > 0) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_wrong_input_num_rdc&arg2=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            flg = 0;
            return false;
        }
    }
    objElement.className = 'inputForm';
    objLabel.className = 'labelForm';
    return true;
}

function verifSame(field1, field2, fieldname) {
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurText = document.getElementById('erreur_msg');
    $(objElement).removeData('data-error-text').removeAttr('data-error-text');
    if (document.getElementById(field1).value != document.getElementById(field2).value) {
        var txt_lang = (fieldname == 'login_email_verif') ? 'js_same_email_confirm' : 'js_same_mdp_confirm';
        t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=' + txt_lang + '&arg2=' + fieldname);
        $(objElement).data('data-error-text', t_error);
        erreurText.innerHTML = t_error;
        objElement.className = 'inputErr';
        objLabel.className = 'labelErr';
        return false;
    }
    objElement.className = 'inputForm';
    objLabel.className = 'labelForm';
    return true;
}

function isClosedDay(day, month) {
    var array_closed_array = new Array('1/1', '25/4', '1/5', '8/5', '2/6', '13/6', '14/7', '15/8', '1/11', '11/11', '25/12', '26/12');
    return array_closed_array.in_array(day + '/' + month);
}

(function() {

    'use strict';

    function in_array(p_val) {

        for (var i = 0, l = this.length; i < l; i++) {

            if (this[i] == p_val) {

                return true;
            }
        }

        return false;
    }

    Object.defineProperty(Array.prototype, 'in_array', {
        configurable: true,
        enumerable: false,
        writable: false,
        value: in_array
    });
}());

function changeVisu(field, path) {

    var new_img;

    field = document.getElementById(field);

    if (field && field.parentElement) {

        new_img = new Image();

        new_img.id = field.id;
        new_img.className = field.className;

        new_img.addEventListener('load', function() {

            this.alt = field.alt;

            this.setAttribute('width', this.naturalWidth);
            this.setAttribute('height', this.naturalHeight);

            if (field.parentElement) {

                field.parentElement.appendChild(this);
                field.parentElement.removeChild(field);
            }
        });

        new_img.src = path;
    }

    // Simpler way doesn't seem to work well with lazyloader
    // return document.getElementById(field).src = path;
}

function getPos(obj) {
    var pos = { x: obj.offsetLeft || 0, y: obj.offsetTop || 0 };
    while (obj = obj.offsetParent) {
        pos.x += obj.offsetLeft || 0;
        pos.y += obj.offsetTop || 0;
    }
    return pos;
}

function closeInfobulle(id) {
    $("#" + id).fadeOut(400);
}

var one_time = false;

function openInfobulle(id, type, elem) {
    window.clearTimeout(timer);
    $("#bloc_erreur").css('display', 'none');
    $("#bloc_erreur").stop(false, true).fadeOut();
    $("#" + id).fadeIn(400);
    if (typeof(type) != 'undefined') {
        var margin_spe_left = 0;
        var margin_spe_top = 0;
        if (elem.nodeName == 'SELECT') {
            id = elem.id.substr(0, elem.id.length - 1);
            if (id == 'clientMailAuth' || id == 'clientPwdAuth' || id == 'mailLost') {
                var txt_spe = document.getElementById(id + 'ErrErr').innerHTML;
                margin_spe_left = 300;
                if (id == 'clientPwdAuth')
                    margin_spe_top = 10;
                infos_alias
            } else if (type == 'banque')
                var txt_spe = document.getElementById('infos_carte').innerHTML;
            else if (type == 'alias_banque')
                var txt_spe = document.getElementById('infos_alias').innerHTML;
            else
                var txt_spe = document.getElementById(id + 'Err').innerHTML;
        } else {
            if (elem.id == 'clientMailAuth' || elem.id == 'clientPwdAuth' || elem.id == 'mailLost') {
                if (document.getElementById(elem.id + 'ErrErr'))
                    var txt_spe = document.getElementById(elem.id + 'ErrErr').innerHTML;
                if (elem.id == 'mailLost')
                    margin_spe_left = -350;
                else
                    margin_spe_left = -340;
                if (elem.id == 'clientPwdAuth')
                    margin_spe_top = 41;
                else
                    margin_spe_top = 4;
            } else if (type == 'banque')
                var txt_spe = document.getElementById('infos_carte').innerHTML;
            else if (type == 'alias_banque')
                var txt_spe = document.getElementById('infos_alias').innerHTML;
            else
                var txt_spe = document.getElementById(elem.id + 'Err').innerHTML;
        }
        document.getElementById('erreur_msg').innerHTML = txt_spe;
        pos = getPos(elem);
        document.getElementById('bloc_erreur').style.position = 'absolute';
        if (type == 'banque') {
            margin_spe_left -= 42;
            margin_spe_top += 30;
        } else if (type == 'alias_banque') {
            margin_spe_left -= 42;
            margin_spe_top += 15;
        }
        /*
            document.getElementById('bloc_erreur').style.top = pos.y - (document.getElementById('bloc_erreur').offsetHeight + margin_spe_top - 2) + 'px';
            pos_left_margin = getPos(document.getElementById('content'));
            left_add = 0;
            if ( document.getElementById('wrapper_anniversaire_jour1' ) ) {
                left_add = document.getElementById('bloc_erreur').offsetWidth;
                left_add -= 35;
            }
            document.getElementById('bloc_erreur').style.left = (pos.x+(elem.offsetWidth-8) + margin_spe_left + 27 - left_add) + 'px';
            if ( type == 'banque' || type == 'alias_banque' ) {
                document.getElementById('bloc_erreur').style.width='280px';
            } else {
                document.getElementById('bloc_erreur').style.width='280px';
            }
        */
        document.getElementById('bloc_erreur').style.zIndex = '10000';
    }
}

function basculeAndAdd(elem, msg, type) {
    clearTimeout(timer);
    if (type == 'visible') {
        pos = getPos(elem);
        document.getElementById('erreur_msg').innerHTML = msg;
        $("#bloc_erreur").fadeIn(400);
        document.getElementById('bloc_erreur').style.position = 'absolute';
        var nav = navigator.appVersion;
        document.getElementById('bloc_erreur').style.top = pos.y - (document.getElementById('bloc_erreur').offsetHeight) + 'px';
        document.getElementById('bloc_erreur').style.left = (pos.x + 20) + 'px';
        document.getElementById('bloc_erreur').style.zIndex = '1';
    } else {
        document.getElementById(type_spe).style.display = "none";
    }
}

function OverPopup(elem, msg, type, is_vp) {
    clearTimeout(timer);
    if (type == 'visible') {
        if (typeof(is_vp) != 'undefined' && is_vp != 'cmpid_adwords') {
            $("#bloc_erreur_vp").css('display', 'none');
            $("#bloc_erreur_vp").stop(false, true).fadeOut();
            $("#bloc_erreur_vp").fadeIn(400);
        } else {
            if (!document.getElementById('formAnniversaire') || (document.getElementById('formAnniversaire') && elementID != 'newsletterMailv' && elementID != 'newsletterMailInscrit')) {
                pos = getPos(elem);
                document.getElementById('erreur_msg').innerHTML = msg;
                $("#bloc_erreur").stop(true, true).fadeIn(400);
                document.getElementById('bloc_erreur').style.position = 'absolute';
                var topHeight = $('.headerWrapper').css('top');
                var spltTopHeight = topHeight.split('px');
                var rsltTopHeight = Number(spltTopHeight[0]);
                if (rsltTopHeight == 0)
                    rsltTopHeight = 4;
                pos_left_margin = -13;
                var left_add = 17;
                document.getElementById('bloc_erreur').style.left = (pos.x + (elem.offsetWidth - 8) - pos_left_margin - left_add) + 1 + 'px';
                if (is_vp === 'cmpid_adwords') {
                    var clone = $('#bloc_erreur').clone(),
                        c_pos = {
                            top: $(elem).offset().top - $(elem).offsetParent().offset().top - $('#bloc_erreur').height() + 4,
                            left: $(elem).offset().left - $(elem).offsetParent().offset().left - $('#bloc_erreur').innerWidth() + ($(elem).width() * 2) + 6
                        };
                    $('#bloc_erreur').remove();
                    $(elem).after(clone);
                    $('#bloc_erreur').find('td:nth-child(2)').children('img').css({
                        'float': 'right',
                        'margin': '0 7px'
                    });
                    $('#bloc_erreur').css({
                        'position': 'absolute',
                        'top': c_pos.top,
                        'left': c_pos.left,
                        'z-index': '99999',
                        'opacity': '1'
                    });
                } else {
                    document.getElementById('bloc_erreur').style.top = (pos.y - (document.getElementById('bloc_erreur').offsetHeight + rsltTopHeight)) + 'px';
                }
                if (elem.id == "departement_naissance") {
                    $('#bloc_erreur').css({ "width": 180, "marginTop": 63, "marginLeft": -310 });
                }
                document.getElementById('bloc_erreur').style.zIndex = '99999';
            }
        }
    } else {
        if (typeof(is_vp) != 'undefined' && is_vp != 'cmpid_adwords') {
            $("#bloc_erreur_vp").fadeOut(400);
        } else {
            $("#bloc_erreur").stop(true, true).fadeOut(400);
        }
    }
}


function getXY(obj) {
    var curleft = 0;
    var curtop = obj.offsetHeight + 5;
    var border;
    if (obj.offsetParent) {
        do {
            if (getStyle(obj, 'position') == 'relative') {
                if (border = _pub.getStyle(obj, 'border-top-width')) curtop += parseInt(border);
                if (border = _pub.getStyle(obj, 'border-left-width')) curleft += parseInt(border);
            }
            curleft += obj.offsetLeft;
            curtop += obj.offsetTop;
        }
        while (obj = obj.offsetParent)
    } else if (obj.x) {
        curleft += obj.x;
        curtop += obj.y;
    }
    return { 'x': curleft, 'y': curtop };
}
/**
 * Returns the specified computed style on an object.
 * @param {HTMLObject} obj HTML Object
 * @param {String} styleProp Property name.
 * @return {Mixed} Computed style on object.
 */
function getStyle(obj, styleProp) {
    if (obj.currentStyle)
        return obj.currentStyle[styleProp];
    else if (window.getComputedStyle)
        return document.defaultView.getComputedStyle(obj, null).getPropertyValue(styleProp);
}

/* pour les questions du panier panier */
function fadePanier(id, nbelem) {
    for (var i = 1; i <= nbelem; i++) {
        if (id != i && $("#texte_question_" + i).css('display') == 'block') $("#texte_question_" + i).slideUp(400);
    }
    if ($("#texte_question_" + id).css('display') != 'block') $("#texte_question_" + id).slideDown(400);
    else $("#texte_question_" + id).slideUp(400);
}

function fadeHelp(id, nbelem) {
    for (var i = 1; i <= nbelem; i++) {
        if (id != i && $("#answer_" + i).css('display') == 'block') $("#answer_" + i).slideUp(400);
    }
    if ($("#answer_" + id).css('display') != 'block') $("#answer_" + id).slideDown(400);
    else $("#answer_" + id).slideUp(400);
}

/********showblocform********/
function showBlocForm(id, type) {

    clearTimeout(timer);

    if ($('#bloc_erreur').css('display') == 'table' || $('#bloc_erreur').css('display') == 'block') {

        $('#bloc_erreur').css('display', 'none');
    }

    if (id == 'is_not_client') {
        $(".wrapper_login.left").addClass('actif');
        $(".wrapper_login.right").removeClass('actif');
        $('#is_not_client').addClass('actif');
        $('#is_client').removeClass('actif');

        if ($("#bloc_lost_password").is(':visible')) {
            setTimeout(reloadBlocLogin, 100);
        }
        
    } else if (id == 'is_client') {
        $(".wrapper_login.left").removeClass('actif');
        $(".wrapper_login.right").addClass('actif');
        $('#is_not_client').removeClass('actif');
        $('#is_client').addClass('actif');
    }
}

/*********/
/********affichelostpassword********/
var afficheLostPassword = function (evt) {

    var signIn = $('#auth');
    var passWd = $('#resetPass');

    evt.preventDefault();

    signIn.animate({
        opacity: 0
    }, 600, function () {

        signIn.css('display', 'none');

        passWd.css('display', '').animate({
            opacity: 1
        }, 600);
    });
};

/*********/
/********reloadbloclogin********/
var reloadBlocLogin = function (evt) {

    var signIn = $('#auth');
    var passWd = $('#resetPass');

    evt.preventDefault();

    passWd.animate({
        opacity: 0
    }, 600, function () {

        passWd.css('display', 'none');

        signIn.css('display', '').animate({
            opacity: 1
        }, 600);
    });
};

/*********/

function afficheLostPasswordTop() {

    if ($('#bloc_erreur').is(':visible')) {

        $('#bloc_erreur').css('display', 'none');
    }

    $("#returning_customer_top").hide();
    $("#lost_password_top").fadeIn(600);
}

function reloadBlocLoginTop() {

    if ($('#bloc_erreur').is(':visible')) {

        $('#bloc_erreur').css('display', 'none');
    }

    $("#lost_password_top").hide();
    $("#returning_customer_top").fadeIn(600);
}

var cpt_over = 0;

function initBlocCheckBox(id) {
    if (cpt_over == 0) {
        $("#select_" + id).css('display', 'block');
        $("#recherche_" + id).css('backgroundPosition', 'left bottom');
        cpt_over++;
    }
}

function closeCheckBox(id) {
    cpt_over = 0;
    $("#select_" + id).css('display', 'none');
    $("#recherche_" + id).css('backgroundPosition', 'left top');
}

function display_choix_relais(action) {
    if (action == 'show') {
        $("#kiala").slideUp("slow");
        $("#kiala2").slideDown("slow");
        $("#choix_relais_kiala").slideDown("slow");
        $("#btn_another_relay").hide();
        $("#btn_close_another_relay").show();
    } else {
        $("#kiala").slideDown("slow");
        $("#kiala2").slideUp("slow");
        $("#choix_relais_kiala").slideUp("slow");
        $("#btn_another_relay").show();
        $("#btn_close_another_relay").hide();
    }
}

function display_parrainage_succes() {
    document.getElementsByName('amiPrenom1')[0].value = '';
    document.getElementsByName('amiPrenom2')[0].value = '';
    document.getElementsByName('amiPrenom3')[0].value = '';
    document.getElementsByName('amiPrenom4')[0].value = '';
    document.getElementsByName('amiPrenom5')[0].value = '';
    document.getElementsByName('amiMail1')[0].value = '';
    document.getElementsByName('amiMail2')[0].value = '';
    document.getElementsByName('amiMail3')[0].value = '';
    document.getElementsByName('amiMail4')[0].value = '';
    document.getElementsByName('amiMail5')[0].value = '';
    var middle_grandjeu = document.getElementById('middle_grandjeu');
    var grandjeu_03 = document.getElementById('grandjeu_03');
    var grandjeu_04 = document.getElementById('grandjeu_04');
    middle_grandjeu.style.display = 'none';
    grandjeu_03.style.display = 'none';
    grandjeu_04.style.display = 'none';
    $("#middle_felicitations").slideUp("slow");
    $("#middle_parrainage").slideDown("slow");
    $("#felicitations_01").slideUp("slow");
    $("#parrainage_01").slideDown("slow");
    $("#felicitations_02").slideUp("slow");
    $("#parrainage_02").slideDown("slow");
}

function show_popup(elem) {
    openMultiShad(elem);
}

function openMultiShad() {

    // Show modbox and make sure closing routine won't fire more than once
    $("#" + id).fadeIn(200, function () {

        $("#" + id).slideDown(600);
    });

    $("#shade").css("display", "block").fadeTo("slow", 0.8).on("click", function () {
        bodyScrollUnlock();
    });

    bodyScrollLock();
}

function closeMultiShad() {

    $('#shade').off('click');
    $(".lightbox").hide();

    // Hide modbox and shade and destroy shade's click event
    $('#' + id).slideUp("slow").queue(function () {

        if ($('#shade').is(":visible")) {
            $('#shade').fadeTo("slow", 0, function () {

                $('#shade').css('display', 'none');
            });
        }

        $(this).dequeue();
    });

    bodyScrollUnlock();
}


$("#shade").on("click", function () {
    bodyScrollUnlock();
    $(".lightbox").fadeOut();
    $(".reservationSlide").fadeOut();
    $('#shade').css('display', 'none');
});


function toggleFullScreen() {
    var video = document.getElementById("video_spa");
    if (video.requestFullScreen) {
        video.requestFullScreen();
    } else if (video.webkitRequestFullScreen) {
        video.webkitRequestFullScreen();
    } else if (video.mozRequestFullScreen) { video.mozRequestFullScreen(); }
}

function close_popup(elem) {
    var modbox = document.getElementById(elem);
    var shad = document.getElementById('shad');
    $("#shad").attr('onclick', '');
    shad.style.display = "none";
    var shade = document.getElementById('shade');
    $("#shade").attr('onclick', '');
    shade.style.display = "none";
    modbox.style.display = "none";
}

function flou(type, indice) {
    for (var i = 1; i <= 4; i++) {
        if (i != indice && type == 'over') {
            $("#img_look" + i).css('opacity', '0.2');
            $("#img_look" + i).css('margin', '40px 0 0 0');
            $("#img_look" + i).height(460);
        } else {
            $("#img_look" + i).css('opacity', '1');
            $("#img_look" + i).css('margin', '0');
            $("#img_look" + i).height(559);
        }
    }
}

function clearFieldParrain() {
    for (var i = 1; i <= 5; i++) {
        $("#amiPrenom" + i).val('');
        $("#amiMail" + i).val('');
    }
}

function hoverSscategory(id, subcategory_id) {
    fleche = document.getElementById('fleche_' + id);
    title = document.getElementById('title_' + id);
    ul = document.getElementById('ul_' + id);
    if (fleche) {
        fleche.style.backgroundPosition = 'left bottom';
    }
    if (id != subcategory_id && title) {
        title.style.color = '#ea6195';
        title.style.fontWeight = 'bold';
    }
    if (ul) {
        ul.style.display = 'block';
    }
}

function outSscategory(id, subcategory_id) {
    fleche = document.getElementById('fleche_' + id);
    title = document.getElementById('title_' + id);
    ul = document.getElementById('ul_' + id);
    if (fleche) {
        fleche.style.backgroundPosition = 'left top';
    }
    if (id != subcategory_id && title) {
        title.style.color = '#333333';
        title.style.fontWeight = 'normal';
    }
    if (ul) {
        ul.style.display = 'none';
    }
}

$(document).ready(function() {
    $(".bg_autres_rayons").each(function() {
        $(this).hover(function() {
            $(this).children("div").children(".wrapper_ul_autres_rayons").show();
            $(this).children("div").children("div.wrapper_title_autre_rayon").children("div.fleche_autres_rayons").addClass("selected");
            $(this).children("div").children("div.wrapper_title_autre_rayon").children("div.title_autre_rayon").addClass("gras_rose");
        }, function() {
            $(this).children("div").children(".wrapper_ul_autres_rayons").hide();
            $(this).children("div").children("div.wrapper_title_autre_rayon").children("div.fleche_autres_rayons").removeClass('selected noClass').addClass('');
            $(this).children("div").children("div.wrapper_title_autre_rayon").children("div.title_autre_rayon").removeClass('gras_rose noClass').addClass('');
        });
    })
});


$(document).ready(function() {
    $("#bg_liste_ariane").hover(function() {
        $(this).children(".wrapper_liste_ariane").show();
        $(this).children("div.wrapper_title_liste_ariane").children("div.fleche_autres_rayons").addClass("selected");
    }, function() {
        $(this).children(".wrapper_liste_ariane").hide();
        $(this).children("div.wrapper_title_liste_ariane").children("div.fleche_autres_rayons").removeClass('selected noClass').addClass('');
    });

});

function show_lightbox_inscrit(popup_confirm_inscription) {
    $('#shad_popup_confirm_inscription').fadeTo("slow", 0.8).queue(function() {
        $(this).css('display', 'block');
        $('#' + popup_confirm_inscription).slideDown("slow");
        $(this).dequeue();
    });
}

function close_lightbox_inscrit(popup_confirm_inscription) {
    $('#' + popup_confirm_inscription).slideUp("slow").queue(function() {
        $('#shad_popup_confirm_inscription').fadeTo("slow", 0, function() {
            $(this).css('display', 'none');
        });
        $(this).dequeue();
    });
}

var old_onclick_shad = 'popup_confirm_reinitialisation_mdp';

function show_lightbox(popup_confirm_reinitialisation_mdp) {
    $('#shad_popup_confirm_reinitialisation_mdp').fadeTo("slow", 0.8).queue(function() {
        $(this).css('display', 'block');
        $('#' + popup_confirm_reinitialisation_mdp).slideDown("slow");
        $(this).dequeue();
    });
    $("#shad_popup_confirm_reinitialisation_mdp").click(function() {

        close_lightbox(popup_confirm_reinitialisation_mdp);

    });
}

function close_lightbox(popup_confirm_reinitialisation_mdp) {
    $('#' + popup_confirm_reinitialisation_mdp).slideUp("slow").queue(function() {
        $('#shad_popup_confirm_reinitialisation_mdp').fadeTo("slow", 0, function() {
            document.getElementById(popup_confirm_reinitialisation_mdp).style.display = 'none';
        });
        document.getElementById('shad_popup_confirm_reinitialisation_mdp').style.display = 'none';
        $('#' + popup_confirm_reinitialisation_mdp).dequeue();
    });
    $("#shad_popup_confirm_reinitialisation_mdp").click(function() {
        close_lightbox(old_onclick_shad);
    });
}



function show_lightbox_3xcb() {
    $('#shad').fadeTo("slow", 0.8).queue(function() {
        $(this).css('display', 'block');
        $('#popup_3xcb').slideDown("slow");
        $(this).bind('click', close_lightbox_3xcb);
        $(this).dequeue();
    });
}

function close_lightbox_3xcb() {
    $('#popup_3xcb').hide();
    $('#shad').hide();
    $(this).unbind('click', close_lightbox_3xcb);
}

function in_array(needle, haystack, strict) {
    // http://kevin.vanzonneveld.net
    // +   original by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
    // *     example 1: in_array('van', ['Kevin', 'van', 'Zonneveld']);
    // *     returns 1: key
    var rKey = -1,
        strict = !!strict;
    if (haystack.length > 0) {
        for (key in haystack) {
            if ((strict && haystack[key] === needle) || (!strict && haystack[key] == needle)) {
                rKey = key;
                break;
            }
        }
    }
    return rKey;
}

function in_array_js(needle, haystack) {
    var rKey = -1;
    if (haystack.length > 0) {
        for (key in haystack) {
            if (haystack[key] == needle) {
                rKey = key;
                break;
            }
        }
    }
    return rKey > -1 ? true : false;
}

var elmt;
var loader;
var prev_display;
/*function waitingProcess(src,target_id,type,id_form,tab_erreur) {
    if (src) {
        elmt = $("#"+$(src).attr('id'));
    }
    if (target_id) {
        loader = $("#"+target_id);
    }
    if ( elmt.css('display')!='none') {
        prev_display = elmt.css('display');
        elmt.css('display', 'none');
        loader.css('display', 'block');
        switch(type) {
            case 0:window.setTimeout('ajax_checkAllForm(tabAuthTop, 1, path_relative_root)', 1000);break;
            case 1:window.setTimeout('ajax_checkAllForm(tabLostPwdTop, 1, path_relative_root)', 1000);break;
            case 2:window.setTimeout('ajaxDeconnect(path_relative_root);', 1000);break;
            case 3:window.setTimeout('checkAllForm(tabError,0,path_relative_root,\''+id_form+'\');', 1000);break;
            case 4:window.setTimeout('ajax_checkAllForm(tabAuth, 1, path_relative_root)', 1000);break;
            case 5:window.setTimeout('ajax_checkAllForm(tabLostPwd, 1, path_relative_root)', 1000);break;
            case 6:window.setTimeout('checkFormParrainage()', 1000);break;
            case 7:window.setTimeout('if (checkAllForm(tabErrorGrattez,0,path_relative_root,\''+id_form+'\')) processGrattezGagnez();', 1000);break;

        }
    }
}*/

function hideLoader() {
    if (loader) { loader.css('display', 'none'); }
    if (elmt) { elmt.css('display', prev_display); }
}

$.fn.recalImg = function(img) {
    var obj = $(this);
    var img;
    if (img == "" || img == null) {
        img = $("img", obj);
    } else {
        img = $(img, obj);
    }
    img.css({ position: "", height: "", width: "", left: "", top: "", marginTop: "", marginLeft: "" });
    var widthObj = obj.outerWidth(true);
    var heightObj = obj.outerHeight(true);
    var widthImg = img.width();
    var heightImg = img.width();
    obj.css({ position: "relative" });
    img.css({ position: "absolute" });
    if ((heightImg > heightObj) || (widthImg > widthObj)) {
        if (widthImg > heightImg) {
            img.css({ height: heightObj });
        } else if (widthImg <= heightImg) {
            img.css({ width: widthObj });
        }
        var widthImgNow = img.outerWidth(true);
        var heightImgNow = img.outerHeight(true);
        var difHeight = (heightObj - heightImgNow) / 2;
        var difWidth = (widthObj - widthImgNow) / 2;
        if (widthImg > heightImg) {
            img.css({ left: 0, top: 0 });
        } else if (widthImg <= heightImg) {
            img.css({ top: 0, left: 0 });
        }
    } else {
        img.css({ top: "0", left: "0" });
    }
    img.show();
};

function activeChange(p, c, s) {
    if ($(p).attr("value") == "") {
        $(c).css({ color: "#aaa", fontStyle: "italic" });
    } else {
        $(c).css({ color: "#000", fontStyle: "normal" });
    }
    c.text($('option:selected', s).text());
    if ($('option:selected', s).val() == "") {
        $(c).css({ color: "#aaa", fontStyle: "italic" });
    }
}

/********select_styled********/
$.fn.selectStyled = function() {
    var p = $(this);

    if (!$(".clone", p).length) {
        p.prepend("<div class='clone'></div>");
    }
    if ((p.hasClass('selectStyledProduct') && (!$(".arrow", p).length))) {
        p.prepend("<div class='arrow'></div>");
    }
    var c = $('.clone', p);
    var s = $('select', p);
    var ws = s.outerWidth();
    var hs = s.outerHeight();
    var ts = 2;
    var ls = 5;

    if (!p.is(':visible') && p.css('width') != undefined) {
        ws = Number(p.css('width').replace('px', ''));
    }

    s.ready(function() {
        s.css({ "display": "block", "opacity": "0", "position": "absolute", "top": "0", "left": "0" });
        c.css({ width: ws });
        c.text($('option:selected', s).text());
    });

    if ($(s).prop("value") == "" || $(s).prop("value") == undefined) {
        $(c).css({ color: "#aaa", fontStyle: "italic" });
    }
    $(s).change(function() {
        if ($(this).prop("value") == "" || $(this).prop("value") == undefined || $(this).prop("value") == null) {
            $(c).css({ color: "#aaa", fontStyle: "italic" });
        } else {
            $(c).css({ color: "", fontStyle: "" });
        }
    });

    $(s).keypress(function() {
        $(this).blur();
        $(this).focus();
    });

    s.change(function() {
        c.text($('option:selected', s).text());
    });
};

$(function() {
    $('.selectStyledFaq, .selectStyled, .selectStyledProduct').each(function() {
        $(this).selectStyled();
    });
});

$.fn.cbForm = function() {
    var t = $(this);
    var fs = $('.form_select', t);
    fs.css({ opacity: "1" });
    fs.hover(function() {
        if (!$(this).hasClass("activ") && t.hasClass('active_bloc')) {
            $(this).css({ opacity: "0.5" });
        }
    }, function() {
        if (!$(this).hasClass("activ") && t.hasClass('active_bloc')) {
            $(this).css({ opacity: "1" });
        }
    });
    fs.click(function() {
        if (t.hasClass('active_bloc')) {
            fs.removeClass("activ");
            fs.css({ opacity: "1" });
            $(this).addClass("activ");
            $(this).css({ opacity: "0.5" });
            $(".type_carte option", t).removeAttr("selected");
            var typeCarte = $(this).attr("id");
            $("#type_carte_" + typeCarte, t).attr('selected', 'selected');
            p = $('.type_carte option:selected', t);
            c = $('.clone', $('.type_carte', t).parent('.selectStyled'));
            c.text(p.text());
            if ($(p).attr("value") == "") {
                $(c).css({ color: "#aaa", fontStyle: "italic" });
            } else {
                $(c).css({ color: "#000", fontStyle: "normal" });
            }
        }
    });
    $('.type_carte', t).change(function() {
        tcv = $('option:selected', this);
        var ctcv = tcv.val();
        fs.removeClass("activ");
        fs.css({ opacity: "1" });
        if (ctcv != "") {
            $('.form_select#' + ctcv).addClass("activ");
            $('.form_select#' + ctcv).css({ opacity: "1" });
        }
    });
}

function showerrormessage() {
    var elname = 'modboxpromo';
    $('#shade').fadeTo("slow", 0.8).queue(function() {
        $('#' + elname).slideDown("slow");
        $(this).dequeue();
    });
    addEventPromo('click', closeerrormessage);
    if (typeof(actualposition) == 'undefined') {
        actualposition = document.documentElement.scrollTop;
    }
    window.scrollTo(0, actualposition);
}

function closeerrormessage() {
    $('#modboxpromo').slideUp("slow").queue(function() {
        $('#shade').fadeTo("slow", 0);
        $(this).dequeue();
        $('#shade').hide();
        $('#modboxpromo').hide();
    });
    delEventPromo('click', closeerrormessage);

}

function showrecuppanierlightbox() {
    var elname = 'recuppanier';
    var actualposition = document.documentElement.scrollTop;
    var modbox = document.getElementById(elname);
    var topconnexion = document.getElementById('topMenuConnexionButtonInside');
    var shad = document.getElementById('shad2');
    shad.style.display = "block";
    modbox.style.display = "block";
    if (navigator.userAgent.indexOf('MSIE') != -1 && navigator.userAgent.indexOf('7.') != -1) {
        topconnexion.style.zIndex = -1;
    }
    addEventPromo(shad, 'click', closerecuppanier);
    window.scrollTo(0, actualposition);
}

function closerecuppanier() {
    var modbox = document.getElementById('recuppanier');
    var shad = document.getElementById('shad2');
    modbox.style.marginLeft = '';
    shad.style.display = "none";
    modbox.style.display = "none";
    document.body.style.overflow = '';
    document.getElementsByTagName("html")[0].style.overflow = "";
    delEventPromo(shad, 'click', closerecuppanier);
}

function addEventPromo(event, name_function) {
    if (document.all) document.getElementById('shad').attachEvent("on" + event, name_function);
    else document.getElementById('shad').addEventListener(event, name_function, false);
}

function delEventPromo(event, name_function) {
    if (document.all) document.getElementById('shad').detachEvent("on" + event, name_function);
    else document.getElementById('shad').removeEventListener(event, name_function, false);
}

function addFileInput(id_input, string) {

    var field = (typeof(string) != 'undefined') ? string : 'contactProduitPhoto';
    id = parseInt(id_input);
    var next_file = id + 1;
    var file = $('#' + field + id_input)[0].files[0];
    var error = false;
    var input = field == 'contactProduitPhoto' ? $('#photoProduit_display') : $('#photoProduitEndommage_display');
    var nb_input = field == 'contactProduitPhoto' ? $(input).val() : $(input).val();
    nb_input = parseInt(nb_input);
    size = file.size;
    type = file.type;
    var allowedType = new Array('image/jpeg', 'image/png', 'application/pdf'); // Type de fichiers autoris�s
    if (size > 4000000) {
        alert('Poids Max : 4Mo');
        error = true;
    } else if (jQuery.inArray(type, allowedType) == -1) {
        alert('Format autoris&eacute; : jpg, png, pdf');
        error = true;
    }
    if (error) {
        if (id_input == 1) {
            $('#field_send').addClass('cache');
            $('#' + field + next_file).remove();
        }
        $('#' + field + id_input).val('');
    } else {
        if (id_input == 1) {
            $('#field_send').removeClass('cache');
        }
        if (field != 'filepj') {
            var params = (typeof(string) != 'undefined') ? ',"' + string + '"' : '';
            var style = (typeof(string) != 'undefined' && string != 'contactProduitPhotoEndommage') ? 'style="float: left; padding-left: 32px;padding-top:-2px;"' : '';
            $('#' + field + id_input).after("<input " + style + " class='product_file' id='" + field + next_file + "' value='' type='file' name='" + field + next_file + "' onchange='addFileInput(" + next_file + params + ")' /> ");
            $(input).attr('value', next_file);
        }
    }
}

function uploadfile() { //fonction pour valider le fichier en pj dans le formulaire message
    var error = false;
    var fname = $('#message_file').val();
    var fsize = $('#message_file')[0].files[0].size; //get file size
    var ftype = $('#message_file')[0].files[0].type; // get file type
    var allowedType = new Array('image/jpeg', 'image/png', 'application/pdf'); // Type de fichiers autoris�s
    if (fsize > 4000000) {
        alert('Poids Max : 4Mo');
        error = true;
    } else if (jQuery.inArray(ftype, allowedType) == -1) {
        alert('Format autorise : jpg, png, pdf');
        error = true;
    }
    if (error) {
        $('#message_file').val('');
    } else {

    }
}

function checkDateContact() {
    var jour = $('#contactDateCommandeJ').val();
    var mois = $('#contactDateCommandeM').val();
    var annee = $('#contactDateCommandeA').val();
    var field_error = $('#field_error_date_garantie td');
    field_error.empty();
    if (jour != "" && mois != "" && annee != "") {
        var date_selec = new Date(annee + '/' + mois + '/' + jour);
        date_selec = Math.round(date_selec.getTime() / 1000);
        var date = new Date();
        date = Math.round(date.getTime() / 1000);
        var deux_ans = 63072000; // 2 ans en timestamp
        var diff_deuxans = date - date_selec; // Duree date auj et date choisie
        if (diff_deuxans > deux_ans) {
            field_error.append(ajax_file('ajax_sprintf.php?arg1=contact_field_error_garantie'));
            $('input[name="contactGarantieProduit"]').removeAttr('checked')
            $('#field_error_garantieProduit td').empty();
            $('#field_garantieproduit').addClass('cache');
            $('#field_error_garantieProduit').addClass('cache');
            $('#field_garantieNomProduit').addClass('cache');
            $('#field_message').addClass('cache');
            $('#field_photo').addClass('cache');
            $('#field_send').addClass('cache');
            $('#field_error_date_garantie').removeClass('cache');
        } else {
            $('#field_garantieproduit').removeClass('cache');
            $('#field_error_garantieProduit').removeClass('cache');
            $('#field_error_date_garantie').addClass('cache');
        }
    } else {
        $('#field_garantieproduit').addClass('cache');
        $('#field_error_garantieProduit').addClass('cache');
        $('#field_preuveAchat').addClass('cache');
        $('#field_garantieNomProduit').addClass('cache');
        $('#field_message').addClass('cache');
        $('#field_photo').addClass('cache');
        $('#field_send').addClass('cache');
        $('#field_error_garantieProduit td').empty();
    }
}

function checkProduitContact(idProduit) {
    $('#contactMessage').empty();
    $('#contactProduitPhoto1').val("");
    $('#contactProduitPhoto2').remove();
    $('#contactProduitPhoto3').remove();
    $('#field_send').addClass('cache');
    if (idProduit != "") {
        tab = new Array();
        tab[0] = new Array('alpha', 'contactNom', 'contact_field_nom');
        tab[1] = new Array('alpha', 'contactPrenom', 'contact_field_prenom');
        tab[2] = new Array('mail', 'contactMail', 'contact_field_mail');
        tab[3] = new Array('no_empty', 'contactMessage', 'contact_field_message');
        $('#field_message').removeClass('cache');
        $('#field_photo').removeClass('cache');
    } else {
        $('#field_message').addClass('cache');
        $('#field_photo').addClass('cache');
    }
}

function checkField(id, type, value) {
    var current_val = $("#" + id).val();
    if (type == 'focus') {
        if (current_val == value)
            $("#" + id).val('');
    } else {
        if (current_val == '')
            $("#" + id).val(value);
    }
}

function autocomplete_nomgarantie() {
    var value = $('#garantieNomProduit').val();
    var values = { search: value };
    if (value.length > 2) {
        $.ajax({
            'type': 'POST',
            'data': values,
            'url': path_relative_root + 'ajax_get_produit.php',
            'async': false,
            'success': function(response) {
                autoCompleteAll(response, 'produit', value);
            }
        });
    }
}

function openPopup(popup_id, shad_id, opacity) {
    if (shad_id == undefined) {
        shad_id = 'shad';
    }
    if (opacity == undefined) {
        opacity = 0.8;
    }
    var $popup = $('#' + popup_id);
    var $shad = $('#' + shad_id);
    if ($shad.length == 0) {
        $popup.before('<div id="' + shad_id + '"></div>');
        $shad = $('#' + shad_id);
        $shad.hide();
    }
    $shad.bind('click.popup', function() {
        closePopup(popup_id, shad_id);
    });
    $shad.add($popup).appendTo('body');
    $popup.css({ opacity: 0, display: 'block' });
    $shad.fadeTo('slow', opacity, function() {
        $popup.animate({ opacity: 1 }, function() {
            $popup.css({ opacity: '', display: 'block' });
        });
    });
    $shad.css({ display: 'block', opacity: 0 }).animate({ opacity: 0.8 }, function() {
        $('#wrapper_sendwishlist').css({ opacity: '' });
        $popup.slideDown('slow');
    });
}

function closePopup(popup_id, shad_id) {
    if (shad_id == undefined) {
        shad_id = 'shad';
    }
    var $popup = $('#' + popup_id);
    var $shad = $('#' + shad_id);
    $shad.unbind('click.popup');
    $popup.fadeOut(function() {
        $shad.fadeOut('slow');
    });
}

function CartShow() {

    $('#cart_top').hover(function() {
        $("#show_top_cart").stop(true, true).fadeIn();
        $(".cart_product_listing").lionbars();
    }, function() {
        $("#show_top_cart").stop(true, true).fadeOut();
    });
}

function ToggleCollapsible() {
    var element = $(this);
    var content = element.siblings();
    if (element.hasClass("collapsible-closed")) {
        content.slideDown();
        element.removeClass("collapsible-closed").addClass("select_btn");
    } else {
        content.slideUp();
        element.addClass("collapsible-closed").removeClass("select_btn");
    }
}

$(document).ready(CartShow);

// function headerMinify() {

//  var headerTopLayout = $('.wrapper_header');

//  headerTopLayout.css('height', 0);

//  headerTopLayout.stop(true, false).animate({
//   height : "+=52"
//  }, 800, function() {});
// }

// function headerMaximize() {

//  var headerTopLayout = $('.wrapper_header');
//  headerTopLayout.css('height', 52);

//  headerTopLayout.stop(true,false).animate({
//   height: "+=33"
//  }, 600, function() {});

// }

// $(function() {

//  var menuSite   =  $('#menu_wrapper'),
//   startPos   =  menuSite.offset().top,
//   windowScrollTop =  $(window).scrollTop(),
//   onTop    =  true;

//  $(window).scroll(function () {

//   var windowScrollTop = $(window).scrollTop();

//   if (windowScrollTop > startPos) {
//    if (onTop === true) {
//     $('#wrapper_header').addClass('fixed-top-menu');
//     headerMinify();
//     onTop = false;
//    }
//   } else if (windowScrollTop < startPos - 84) {
//    if (onTop === false) {
//     headerMaximize();
//     $('#wrapper_header').removeClass('fixed-top-menu');
//     onTop = true;
//    }
//   }

//  });

// });

function deplieMsg(id) {
    $('.btn-deplier').removeClass('deplier-opened');
    $('.detail_message').slideUp();
    $('#conversation_msg_' + id).slideDown().parent().find('.btn-deplier').addClass('deplier-opened');
}

/*$(document).ready(fixedTopMenu);*/
$(document).ready(function() {
    $("#connexion_top").click(function(e) {
        if ($(e.target).attr('id') == 'connexion_top')
            window.location = window.ROOT_PATH + create_link('client_index');
    });
});

function showDetailOrder(order_id, order_type) {
    if (show_orders_by_type('orders_by_type', order_type, ROOT_PATH))
        $('#order_' + order_id).click();
    $('html, body').animate({
        scrollTop: ($('[data-btn-name="order_section"]').offset().top - 100)
    }, 500);
}

(function() {
    'use strict';

    function parseArgs(args) {
        var obj = {},
            inc = 0,
            len = args.length;
        for (inc; inc < len; inc += 1) {
            obj['arg' + inc] = args[inc];
        }
        return obj;
    }

    function translate() {
        var o_data = parseArgs(arguments),
            s_resp = arguments[0];
        $.ajax({
            url: path_relative_root + 'ajax_sprintf.php',
            type: 'get',
            data: o_data,
            async: false,
            success: function(res) { s_resp = res }
        });
        return s_resp;
    }
    window.translate = translate;
}());

function changeLocale(locale, uri) {

    $.ajax({
        url: path_relative_root + 'ajax_get_uri_by_lang.php',
        type: 'post',
        data: { lang: locale, url: uri },
        success: function(data) {
            if (data != '') {

                var force = '';
                if (uri == '/') {
                    force = '?force';
                }

                location.href = data + force;

            }
        }
    });
}

// Action BIND de la pop-up car Cl? present dans l'API Google Maps
$(document).ready(function() {
    $(".btn_compte").each(function() {
        var obj = $(this);
        if (!$(this).hasClass('actif')) {
            obj.mouseenter(function() {
                obj.addClass('actif');
            });
            obj.mouseleave(function() {
                obj.removeClass('actif');
            });
        }
    });
});

function actionKeyCacheCache() {
    $('.key_cache_cache').bind('click', function() {
        var $objId = $(this).attr('id');
        openPopupCacheCache($objId);
    });
}

$.fn.new_styling_form = function(option) {
    var $obj = $(this);
    var param = $.extend({
        target_type: 'multi',
        type_class_sufix: 'styled',
        localName_new_elem: 'div',
        new_elem_id: true,
        height: 'auto',
        use_class: 'false',
        select_noValue_color: '#aaa'
    }, option);

    function animeElem(action, styled, height) {
        var useClass = param.use_class == true;
        styled.removeClass('unselected selected btndown');
        if (action == 'inActive') {
            styled.css({ backgroundPosition: '0 0' }).addClass('unselected')
        }
        if (action == 'active') {
            styled.css({ backgroundPosition: '0 -' + (height * 2) + 'px' }).addClass('selected');
        }
        if (action == 'btnDownToActive' || action == 'btnDownToInActive') {
            styled.addClass('btndown');
            if (action == 'btnDownToActive') {
                styled.css({ backgroundPosition: '0 -' + (height * 1) + 'px' })
            }
            if (action == 'btnDownToInActive') {
                styled.css({ backgroundPosition: '0 -' + (height * 3) + 'px' })
            }
        }
    }

    $obj.each(function() {
        var $objEach = $(this);
        var eachLocalName = $objEach[0].tagName.toLowerCase();
        var localNameValid = new Array('input', 'select');
        var flag = true;
        var eachType = $objEach[0].type;
        var isInputForm = $.inArray(eachLocalName, localNameValid) >= 0

        var acceptedType = new Array('radio', 'checkbox');
        var isAccepted = $.inArray(eachType, acceptedType) >= 0;
        var idElem = $objEach.prop('id');



        if (!isInputForm || (isInputForm == 'input' && !isAccepted)) {
            flag = false;
        }

        var elemType = param.target_type;
        if (elemType = 'multi') {
            elemType = eachType;
        } else if (elemType != eachType) {
            flag = false;
        }
        if ($objEach.data('isStyled')) {
            flag = false;
        }

        if (flag) {
            var prefix = (eachLocalName == 'input') ? eachType : eachLocalName;
            var styledClass = prefix + '_' + param.type_class_sufix
            if (param.type_class_sufix == '') {
                styledClass = prefix;
            }

            if (!$objEach.prev().is(param.localName_new_elem + '.' + styledClass)) {
                var $styledElem = $(document.createElement(param.localName_new_elem));
                $objEach.before($styledElem);
                $styledElem.addClass(styledClass).css({ cursor: 'pointer' });
                if (eachLocalName == 'input') {
                    $objEach.css({ display: 'none' });
                }
                if (eachLocalName == 'select') {
                    $objEach.css({ position: 'relative', opacity: 0, zIndex: 5 });
                }
            } else {
                var $styledElem = $(param.localName_new_elem + '.' + styledClass);
            }

            if (param.new_elem_id) {
                $styledElem.prop('id', eachLocalName + $objEach.prop('id'))
            }

            var objHeight = param.height;
            if (objHeight == 'auto') {
                objHeight = $styledElem.height();
            }
            $objEach.data('isStyled', true);
            var objChecked = $objEach.is(':checked');

            if (eachLocalName == 'input') {
                if (objChecked) {
                    animeElem('active', $styledElem, objHeight);
                } else {
                    animeElem('inActive', $styledElem, objHeight);
                }
            }

            if (eachLocalName == 'input') {
                if (eachType == 'checkbox') {
                    $styledElem.on({
                        'mousedown': function(e) {
                            var objChecked = $objEach.is(':checked');
                            if (e.which == 1) {
                                if (objChecked) {
                                    animeElem('btnDownToInActive', $styledElem, objHeight);
                                } else {
                                    animeElem('btnDownToActive', $styledElem, objHeight);
                                }
                            }
                        },
                        'mouseup': function(e) {
                            var objChecked = $objEach.is(':checked');
                            if (e.which == 1) {
                                if (objChecked) {
                                    $objEach.prop('checked', null);
                                } else {
                                    $objEach.prop('checked', 'checked');
                                }
                                $objEach.trigger('change');
                            }
                        }
                    });
                    $objEach.on({
                        'change': function() {
                            var objChecked = $objEach.is(':checked');

                            if (objChecked) {
                                animeElem('active', $styledElem, objHeight);
                            } else {
                                animeElem('inActive', $styledElem, objHeight);
                            }
                        }
                    });

                }
                if (eachType == 'radio') {
                    var eachName = $objEach[0].name;
                    var $otherRadio = $obj.not($objEach).filter('[type=radio][name=' + eachName + ']');

                    $styledElem.on({
                        'mousedown': function(e) {
                            var objChecked = $objEach.is(':checked');
                            if (e.which == 1) {
                                if (!objChecked) {
                                    animeElem('btnDownToActive', $styledElem, objHeight);
                                }
                            }
                        },
                        'mouseup': function(e) {
                            var objChecked = $objEach.is(':checked');
                            if (e.which == 1) {
                                if (!objChecked) {
                                    $otherRadio.prop('checked', null);
                                    $objEach.prop('checked', 'checked');
                                    animeElem('active', $styledElem, objHeight);
                                    $otherRadio.each(function() {
                                        var otherEach = $(this);
                                        animeElem('inActive', otherEach.prev('.' + styledClass), objHeight);
                                    })
                                }
                                $objEach.trigger('change');
                            }
                        }
                    });
                    $objEach.on({
                        'change': function() {
                            var objChecked = $objEach.is(':checked');
                            if (objChecked) {
                                animeElem('active', $styledElem, objHeight);
                                $otherRadio.each(function() {
                                    var otherEach = $(this);
                                    animeElem('inActive', otherEach.prev('.' + styledClass), objHeight);
                                });
                            }
                        }
                    });
                }
            }
            if (eachLocalName == 'select') {
                if ($styledElem.text() == '') {
                    $styledElem.text($('option:selected', $objEach).text());
                }
                if ($('option:selected', $objEach).val() == '' || $('option:selected', $objEach).val() == undefined) {
                    $styledElem.css({ color: param.select_noValue_color, fontStyle: 'italic' });
                } else {
                    $styledElem.css({ color: '', fontStyle: '' });
                }
                $objEach.on({
                    'change': function() {
                        if ($('option:selected', $objEach).val() == '' || $('option:selected', $objEach).val() == undefined) {
                            $styledElem.css({ color: param.select_noValue_color, fontStyle: 'italic' });
                        } else {
                            $styledElem.css({ color: '', fontStyle: '' });
                        }
                        $styledElem.text($('option:selected', $objEach).text());
                    }
                })
            }
        }

    });
};

$(function() {
    $('.styled').new_styling_form({
        type_class_sufix: '',
        localName_new_elem: 'span'
    });
});

$.fn.openSlideTrans = function(target, action, callback) {
    var $obj = $(this);
    var $container = $obj.closest('.bloc_livraison');
    var dataAlt = $obj.data('alt');
    var currentTitle = $obj.data('current');
    var $zoneTxt = $('span', $obj)
    var $target = $(target, $container);
    var callbackInit = function() {
        if (callback && typeof(callback) === 'function') {
            return callback();
        }
    }

    if (action != 'hide') {
        reinitialise();
    }
    if (!$target.is('.open_slide') && action != 'hide') {
        $obj.addClass('alt');
        $target.addClass('open_slide').slideDown(600, function() {
            callbackInit();
        });
        $zoneTxt.html(dataAlt);
    } else {
        $zoneTxt.html(currentTitle);
        $obj.removeClass('alt');
        $target.removeClass('open_slide').slideUp(600, function() {
            reinitialise();
            callbackInit();
        });
    }

    function reinitialise() {
        var $otherTarget = $('.open_slide').not($target);
        if ($otherTarget.length > 0) {
            $otherTarget.removeClass('open_slide').slideUp(600, function() {
                callbackInit();
            });
        }
        var $otherAlt = $('.btn_tunnel.alt').not($obj)
        if ($otherAlt.length > 0) {
            var $otherZoneTxt = $('span', $otherAlt);
            var otherCurrentTitle = $otherAlt.data('current');
            $otherZoneTxt.html(otherCurrentTitle);
        }
    }
}

$.fn.selectChangeAdr = function() {
    var $obj = $(this);
    var obj_val = $obj.val();
    var id_transp = $obj.attr('id');
    var data = {};
    var $changeContainer = $('.wrapper_form_adresse', $obj.closest('.adresse_change'));
    data.act = 'ajout';
    data.id_transp = id_transp;
    data.no_encode_ajax = true;
    data.modif_type = 'ajax';

    if (obj_val != '') {

        if (obj_val != 0) {
            data.idAdresse = obj_val;
            data.act = 'modif';
        }
        if ($changeContainer.is('.show')) {
            $changeContainer.removeClass('show').slideUp().html('');
        }
        $.ajax({
            type: 'get',
            data: data,
            url: path_relative_root + 'livraison-ajax.php',
            success: function(response) {
                var $responce = $(response);
                var min_form = $('.min_form', $responce).html();
                var $form = $('#adresseForm', $responce);
                var all_form;

                $changeContainer.html($form.html(min_form)).css({ display: 'none' }).slideDown();
                $('.styled').new_styling_form({
                    type_class_sufix: '',
                    localName_new_elem: 'span'
                });
                if (!$changeContainer.is('.show')) {
                    $changeContainer.addClass('show').slideDown()
                }
                try {
                    new DeliveryPage().init();
                } catch (e) {
                    console.log(e);
                }
                initialize_google_place('delivery');
                $('.type_adresse', '.choix_type_adresse.delivery').on('click', function() {
                    $(this).show_adresse_title($('.type_adresse', '.choix_type_adresse.delivery'), '#adresseTitre');
                });

                var country_select = '';

                if (typeof $("#pays").val() != 'undefined') {

                    country_select = $("#pays").find(":selected").data('role').toLowerCase();

                    $("#pays").change(function() {

                        if (typeof $(this).find(":selected").data('role') != 'undefined') {

                            country_select = $(this).find(":selected").data('role').toLowerCase();

                        }

                        if (typeof $("#telephone").val() != 'undefined') {

                            $("#telephone").intlTelInput("selectCountry", country_select);

                        }

                        if (typeof $("#mobile").val() != 'undefined') {

                            $("#mobile").intlTelInput("selectCountry", country_select);

                        }

                    });

                } else {

                    country_select = "fr";

                }

                /*
                if (typeof $("#telephone").val() != 'undefined') {

                    $("#telephone").intlTelInput({

                        utilsScript: path_relative_root+'js/intlTelInputUtils.js?'+Math.floor(new Date().getTime() / 1000),
                        defaultCountry: country_select

                    });

                }
                if (typeof $("#mobile").val() != 'undefined') {

                    $("#mobile").intlTelInput({

                        utilsScript: path_relative_root+'js/intlTelInputUtils.js?'+Math.floor(new Date().getTime() / 1000),
                        numberType: "MOBILE",
                        defaultCountry: country_select

                    });
                }
                */
            }
        });

    }
};

$.fn.show_adresse_title = function(listObj, target) {
    var $obj = $(this);
    var $listObj = $(listObj);
    var $target = $(target);
    var $wrapperTarget = $target.closest('.address_title');
    var dataVal = $obj.data('value');

    $listObj.removeClass('actif');
    $obj.addClass('actif');

    if ($wrapperTarget.data('height') == null || $wrapperTarget.data('height') == 0) {
        if ($obj.is('.autre') && !$wrapperTarget.is('.show')) {
            $wrapperTarget.css({ display: 'block', opacity: 0, position: 'absolute' });
            $wrapperTarget.data('height', $wrapperTarget.height());
            $wrapperTarget.css({ display: 'none', opacity: '', position: '' });

        }

    }
    var adressTitleH = $wrapperTarget.data('height');

    if ($obj.is('.autre')) {
        if (!$wrapperTarget.is('.show'))
            $target.val('');
        if (!$wrapperTarget.is('.show')) {
            $wrapperTarget.addClass('show').css({ display: 'block', height: 0, overflow: 'hidden' })
                .animate({ height: adressTitleH }, function() {
                    $wrapperTarget.css({ opacity: '', overflow: '', height: '' });
                });
        }
    } else {
        if ($wrapperTarget.is('.show')) {
            $wrapperTarget.removeClass('show').css({ display: 'block', height: adressTitleH, overflow: 'hidden' })
                .animate({ height: 0 }, function() {
                    $wrapperTarget.css({ display: 'none', opacity: '', overflow: '', height: '' });
                    $target.val(dataVal);
                });
        } else {
            $target.val(dataVal);
        }
    }

};

var inAnime = false;

function billDisplay(that) {
    var $obj = $('#wrapper_bill');
    var $this = $(that);
    if (!$this.is(':checked')) {
        $obj.stop().slideDown();
    } else {
        $obj.stop().slideUp();
    }
}

function move(element, positionTop) {

    $(element).css('position', 'absolute');
    $(element).stop();
    $(element).css('top', positionTop);

}

$(function() {

    var windowHeight = $(window).height();

    if (windowHeight >= 900) {

        $(window).on('scroll', function() {

            var scrollingItem = $('.scrolling_item'),
                amountScrolled = $(window).scrollTop() + 30, // 30 l'espace entre le haut de la page et scrolling_element
                headerHeight = $('.headerWrapper').height(),
                headerOffsetTop = $('.headerWrapper').offset().top,
                sidebarHeight = scrollingItem.height(),
                leftMenuHeight = $('#left_menu_cat').height(),
                footerPositionTop = $('.copyrightWrapper').position().top,
                margin = 0,
                positionInBetween = headerOffsetTop;
            positionOnBottom = footerPositionTop - (sidebarHeight + headerHeight + 60); // 60 l'espace entre le bas de la page et le bas de scrolling_item

            if (typeof $('#left_menu_cat').offset() != 'undefined') {
                var leftMenuOffsetTop = $('#left_menu_cat').offset().top;
            }

            // Si on a une class scrolling alors on calcule la hauteur à partir de laquel on stick le scrolling_item
            $('.scrolling_margin').each(function() {
                margin += $(this).height();
            })

            // La valeur par defaut
            if (margin == 0) {
                margin = 60;
            }

            if (amountScrolled >= headerHeight + margin && amountScrolled <= positionOnBottom) {

                move(scrollingItem, positionInBetween);

            } else if (amountScrolled >= positionOnBottom) {

                move(scrollingItem, positionOnBottom);

            } else {

                $('.scrolling_item').css({ 'position': 'relative', 'top': 'auto' });

            }

        });

    }

    if($('#cookiesLightbox').length) {
        var submitBtnWrapper = $('#cookiesLightbox .w-form-line.w-submit.form_submit');
        var secondSection = $('#cookiesLightbox section + section');
        secondSection.append(submitBtnWrapper);
    }

});

function getOffTop(elm) {

    var out = 0;

    do {

        elm = elm.offsetParent;
        out += elm.offsetTop;
    } while (elm.offsetParent !== null);

    return out;
}

function onAltImgClick(evt) {

    var new_img = this.src.replace('_mini', ''),
        prv_img = document.getElementById('img_large').src.replace('.jpg', '_mini.jpg');

    this.src = prv_img;
    document.getElementById('img_large').src = new_img;
}

function onLookScrollClick() {

    var top = document.getElementById('slide_look_detail').offsetTop;

    $('html, body').animate({
        scrollTop: top
    }, Math.abs(top - $(document).scrollTop()) * 0.8);
}

$(function() {

    $('.main_categ > a', '#menu_wrapper').on('touchend', function(evt) {

        var $this = $(this),
            ssmenu = $this.siblings('.ssmenu'),
            obj = $this.parent();

        if (!ssmenu.is(':visible')) {

            evt.preventDefault();

            obj.siblings('.main_categ').each(function() {

                var sobj = $(this);

                if (sobj.hasClass('actif')) {

                    sobj.removeClass('actif');
                }

                sobj.children('.ssmenu').stop(true, true).css({ zIndex: 'auto' }).fadeOut(200);
            });

            if (!obj.hasClass('current')) {

                obj.addClass('actif');
            }

            if (!obj.hasClass('out')) {

                ssmenu.stop(true, true).css({ zIndex: 110 }).fadeIn(300);
            } else {

                ssmenu.stop(true, true).css({ zIndex: 110 }).show();
            }

            $('.content_ssmenu', obj).each(function() {

                if ($('div.lazypic', this).length > 0) {

                    if ($('div.lazypic', this).children('img').length <= 0) {

                        loadLazyPic.call($('div.lazypic', this).get(0));
                    }
                }

                $('.ss_cat', this).css({ minHeight: $(this).height() - 20 });
            });
        }
    });

});

function motifChange(motif) {
    var motifValue = motif.value;
    var divBtnPrintPdf = $("#btnPrintPdf");

    if (motifValue == '0') {
        divBtnPrintPdf.css('display', 'none');
    } else {
        divBtnPrintPdf.css('display', 'block');
    }
}

function motifChangeDiv(motif) {
    var motifValue = motif.value;
    var divBtnPrintPdf = $("#divBtnPrintPdf");

    if (motifValue == '0') {
        divBtnPrintPdf.css('display', 'none');
    } else {
        divBtnPrintPdf.css('display', 'block');
    }
}

function displayShade() {
    $('#shade').css('z-index', '50');
    $('#shade').fadeIn();
}

function hideShade() {
    $('#shade').fadeOut();
    $('#shade').css('z-index', '100');
}

if (document.querySelectorAll("#homeReservationDate").length > 0) {
    var divInputDate = document.querySelector("#homeReservationDate").parentElement
    var lastElem = divInputDate.lastChild
    if (lastElem.nodeType === 3) {
        divInputDate.removeChild(lastElem)
    }
    $('#homeReservationDate').change(function (){
        var tab = document.querySelectorAll("#reservation_hours_first")
        if (tab.length > 0){
            sundaySelect(tab[0])
        }
    });
}

function sundaySelect(selectobject) {
    var weekday = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    var hour_start_reservation = 9;
    var dateParts = document.getElementById("homeReservationDate").value.split("/");
    var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);

    if (dateObject.toLocaleDateString('en-US', {weekday: 'long'}) == 'Sunday') {
        hour_start_reservation = 14;
        disabledDay = false;
        // Condition spécial pour le Lundi pas d'horaires
    } else if (dateObject.toLocaleDateString('en-US', {weekday: 'long'}) == 'Monday') {
        disabledDay = true;
        // le reste de la semaine (9h-11h et du 14h-18h)
    } else {
        hour_start_reservation = 9;
        disabledDay = false;
    }
    if (disabledDay == true) {
        selectobject.disabled = true;
    } else {
        selectobject.disabled = false;
        selectobject.options[0].selected = true;

        for (var i = 0; i < selectobject.length; i++) {
            if (hour_start_reservation > 9) {
                selectobject.options[i].selected = false;
                if (hour_start_reservation == 14 && selectobject.options[i].value == '14:00') {
                    selectobject.options[i].selected = true;
                }
                if (parseInt(selectobject.options[i].value.substring(0, 2)) < hour_start_reservation) {
                    selectobject.options[i].disabled = true;
                }
            } else {
                selectobject.options[i].disabled = false;
            }
        }
    }
    document.getElementById("reservation_hours_first").innerHtml = selectobject;
}

function addReservation(product_id)
{
    var targetReservation = $('#reservationBox_' + product_id);
    
    var date = '';
    var hour = '';
    if (!product_id) {
        product_id = changeReservation();
        date = $('#homeReservationDate').val();
        hour = $('#reservation_hours_first').val();
        $('.homeReservationWrapper').css('z-index', '5020');
        $('#shade').fadeIn();
        $('#reservationLightbox').addClass('fromHome');
        $('.homeReservationWrapper').append($('#reservationLightbox'));
        $('#reservationLightbox').css("display", "block").slideDown();
    }

    $.ajax({
        url: path_relative_root+'ajax_show_reservation.php',
        type: 'POST',
        data: {
            produit_id: product_id,
            date: date + ' ' + hour
        },
        success: function(res) {

            $('#reservationLightbox').html(res);

            targetReservation.append($('#reservationLightbox'));
            $('#reservationLightbox').css("display", "block").slideDown();
            targetReservation.slideDown(600, function() {

                var tagetTopPos = targetReservation.offset().top;
                $('html, body').animate({ scrollTop: tagetTopPos - 500 }, 600);
            });
            date = $('#homeReservationDate').val();
            hour = $('#reservation_hours_first').val();
            $('#reservation_date').val(date);
            $('#reservation_hours_popup').val(hour);
        },
        complete: function() {
            buttonToggleForm();
        } 
    });
}

function buttonToggleForm() {
    $(".buttonBox button").each(function() {
        $(this).click(function() {
            var data = $(this).data("value");
            $("#reservation_type_1").val(data);

            if (data == 2) {
                $("#passengerInfos_1 .primaryInfos .select p").html(translate("its_a_gift"));
                $('#reservation_date').val('');
                $("#reservationForm .formDateWrapper .date").css("display","none");
                $(".formDateWrapper .select").first().css("display","none");
                $("#btnForMe").removeClass("redB").addClass("borderR");
                $("#btnOffer").removeClass("borderR").addClass("redB");
            } else {
                $("#passengerInfos_1 .primaryInfos .select p").html(translate("its_me"));
                $("#reservationForm .formDateWrapper .date").css("display","block");
                $(".formDateWrapper .select").first().css("display","block");
                $("#btnOffer").removeClass("redB").addClass("borderR");
                $("#btnForMe").removeClass("borderR" ).addClass("redB");
            }
        })
    });
}

function changeReservation() {
    var tabOption = document.querySelectorAll('#reservation option');
    for (option of tabOption) {
        if (option.selected) {
            return option.value;
        }
    }
    return 1;
}

function closeReservation(product_id) {
    $("#reservationBox_" + product_id).slideUp();
    $('#shade').fadeOut();
    bodyScrollUnlock();

    if ($(".fromHome").length) {

        $(".fromHome").slideUp(600, function () {

            $('.homeReservationWrapper').css('zIndex', '3');
            $('#reservationLightbox').removeClass('fromHome');
        });
    }
}


(function() {

    'use strict';

    function createSticky() {

        var sticky = this.document.getElementById('planesNavigation');

        this.removeEventListener('load', createSticky);

        if (sticky) {

            sticky = new ScrollLock('planesNavigation');
        }

        return (sticky || false);
    }

    window.addEventListener('load', createSticky);

}());

function updateCurrentNav() {

    var scrollAmount = $(document).scrollTop(),
        windowHeight = $(window).innerHeight();

    $('.sectionBloc').each(function() {

        var sectionBlocId = $(this).attr('id'),
            sectionBlocNum = $(this).attr('data-num'),
            sectionBlocHeight = $('#' + sectionBlocId).innerHeight(),
            sectionBlocPosTop = $('#' + sectionBlocId).offset().top;

        if (sectionBlocPosTop - scrollAmount < windowHeight / 2 && sectionBlocPosTop > scrollAmount - sectionBlocHeight / 2) {

            $('.current').attr('data-txt', sectionBlocNum);
            $('.' + sectionBlocId).addClass('actif');
            $('#' + sectionBlocId).addClass('actif');

            // Animations Vitesse / Cylindres / Puissance
            $('.dataUpdate', '#' + sectionBlocId).each(function() {

                if (!$(this).hasClass('started')) {

                    $(this).addClass('started');
                    $(this).prop('Counter', 0).stop(true, true).animate({

                        Counter: $(this).attr('data-update')
                    }, {
                        duration: 2000,
                        easing: 'swing',
                        step: function(now) {

                            $(this).text(Math.ceil(now));
                        }
                    });
                }
            });
            $('.jauge', '#' + sectionBlocId).each(function() {

                $(this).addClass('grow');
            });
        } else {

            $('.' + sectionBlocId).removeClass('actif');
            $('#' + sectionBlocId).removeClass('actif');

            $('.dataUpdate', '#' + sectionBlocId).each(function() {

                if ($(this).hasClass('started')) {

                    $(this).removeClass('started');
                    $(this).prop('Counter', $(this).attr('data-update')).stop(true, true).animate({

                        Counter: 0
                    }, {
                        duration: 2000,
                        easing: 'swing',
                        step: function(now) {

                            $(this).text(Math.ceil(now));
                        }
                    });
                }
            });
            $('.jauge', '#' + sectionBlocId).each(function() {

                $(this).removeClass('grow');
            });
        }
    });
}

$(function() {

    window.addEventListener("load", updateCurrentNav);
    window.addEventListener("scroll", updateCurrentNav);

    $('.section').not('.planeSections').on('click', function(e) {

        e.preventDefault();

        var planeWrapper = $(this).attr('href'),
            sectionHeight = $('' + planeWrapper).height(),
            sectionPos = $('' + planeWrapper).offset(),
            windowHeight = $(window).innerHeight(),
            tagetTopPos = sectionPos.top + ((sectionHeight / 2) - (windowHeight / 2)),
            speed = 750;

        $('html, body').animate({ scrollTop: tagetTopPos }, speed);
        return false;
    });

    $('.planeSections').on('click', function(e) {

        e.preventDefault();

        var planeWrapper = $(this).attr('href'),
            sectionPos = $('' + planeWrapper).offset(),
            tagetTopPos = sectionPos.top - 94,
            speed = 750;

        $('html, body').animate({ scrollTop: tagetTopPos }, speed);
        return false;
    });

    /* SLIDER PUBLICATION */
    var publicationSlider = new Swiper('.publicationSlider', {

        direction: 'vertical',
        slidesPerView: 'auto',
        centeredSlides: true,
        spaceBetween: 30,
        onSlideChangeEnd: function() {

            getCurrentDate();
            getPrevDate();
            getNextDate();
        },
    });

    getCurrentDate();
    getPrevDate();
    getNextDate();

    function getCurrentDate() {

        $('.currentDate .day').html($('.swiper-slide-active').attr('data-day'));
        $('.currentDate .monthYear').html($('.swiper-slide-active').attr('data-month'));
    }

    function getPrevDate() {

        $('.prevDate .day').html($('.swiper-slide-prev').attr('data-day'));
        $('.prevDate .monthYear').html($('.swiper-slide-prev').attr('data-month'));
    }

    function getNextDate() {

        $('.nextDate .day').html($('.swiper-slide-next').attr('data-day'));
        $('.nextDate .monthYear').html($('.swiper-slide-next').attr('data-month'));
    }

    $('.prevEvent, .prevDate').on('click', function() {

        publicationSlider.slidePrev();
    });

    $('.nextEvent, .nextDate').on('click', function() {

        publicationSlider.slideNext();
    });

    /* SLIDER GOODIES CART */
    var goodiesSlider = new Swiper('.goodiesSlider', {

        spaceBetween: 0,
        slidesPerView: 3,
    });

    /* GALERIE */
    $('.currentSlide').html('01');

    var slider_gallery = new Swiper('.slider_gallery', {

        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        spaceBetween: 0,
        effect: 'fade',
        runCallbacksOnInit: true,
        onSlideChangeEnd: function() {

            $('.currentSlide').html('0' + (slider_gallery.activeIndex + 1));
        }
    });

    var thumbs_gallery = new Swiper('.thumbs_gallery', {

        spaceBetween: 0,
        width: 149,
        slideToClickedSlide: true
    });

    slider_gallery.params.control = thumbs_gallery;
    thumbs_gallery.params.control = slider_gallery;

    var total_slides = $('.slider_gallery .swiper-slide').length;

    $('.totalSlides').html('0' + total_slides);
});


$('.basketBtn').on('click', function() {

    $(this).next('.loader').show();
});

$(function() {
    $('.misc_cookiesinfo').removeClass('footer_legalmentions');

    $('#popup_inscription_home .cmpidMentions').insertBefore('#form_lightbox_adwords .form_submit')

    $("#shad").click(function () {
        $(this).fadeOut(450);
        $("#popup_inscription_home").fadeOut(450);
    });

    var sPath = window.location.search;
    if (sPath === "?cmpid=adwords") {
        $('#popup_inscription_home').show().addClass('active');
        $("#shad_adwords").addClass('active');
    }
    else if (sPath != "?cmpid=adwords") {
        $('#popup_inscription_home').hide();
        $("#shad_adwords").removeClass('active');
    }

    if (sPath === "?cmpid=adwords" && $('#popup_inscription_home').hasClass('active')) {
        $('body').css('position','fixed');
    }
    $('#popup_inscription_home .closeLighbox').click(function() {
        $('#popup_inscription_home').removeClass('active');
        $("#shad_adwords").removeClass('active');
        $('body').removeAttr('style');
    });
});

$(document).ready(function() {
    var $tabs = $('#sub-cats ul.tabs');

    $tabs.on('click', 'li', function() {

		var tab_id = $(this).attr('data-tab');

		$tabs.find('li.current').removeClass('current');
		$('.tab-content.current').removeClass('current');

		$(this).addClass('current');
		$("#"+tab_id).addClass('current');
    });
});


/* lionbars */

(function($) {
    $.fn.hasScrollBar = function() {
        return this.get(0).scrollHeight > this.height();
    };
    $.fn.lionbars = function(options) {
        options = options || {};
        autohide = options.autohide;
        // Flags
        var timeout,HDragging=false,VDragging=false,activeScroll=0,activeWrap=0,eventX,eventY,mouseX,mouseY,
        currentRatio,initPos,scrollValue,hideTimeoutSet=false,vEventFired = false,hEventFired = false;
        // Initialization
        var elements = $(this),id = 0,vScrollWidth=0, hScrollWidth=0,addHScroll=false, addVScroll=false,paddingTop=0, paddingLeft=0, paddingBottom=0, paddingRight=0,
        borderTop=0, borderRight=0, borderBottom=0, borderLeft=0,scrollHeight=0, scrollWidth=0, offsetWidth=0, offsetHeight=0, clientWidth=0, clientHeight=0,vRatio=0, hRatio=0,
        vSliderHeight=0, hSliderHeight=0,vLbHeight=0, hLbHeight=0;
        // Main Loop
        this.mainLoop = function() {
            for(var i=0; elements[i] !== undefined; i++) {
                if (needScrollbars(elements[i]) && !$(elements[i]).hasClass('nolionbars')) {
                    // add the element to the main array
                    target = elements[i];
                    // get some values before the element is wrapped
                    getDimentions(target);
                    // wrap the element
                    wrap(target, addVScroll, addHScroll);
                    // hide the default scrollbar
                    hideScrollbars(target, addVScroll, addHScroll);
                    // Calculate the size of the scrollbars
                    reduceScrollbarsWidthHeight(target);
                    setSlidersHeight(target);
                    // Set variables needed to calculate scroll speed, etc.
                    setScrollRatios(target);
                    // Set events
                    setEvents(target);
                    // prepare for next element
                    resetVars();
                }
            }
        }
        this.mainLoop();

        this.Update = function() {
            for(var i=0; elements[i] !== undefined; i++) {
                if (needScrollbars(elements[i]) && !$(elements[i]).hasClass('nolionbars')) {
                    // add the element to the main array
                    target = elements[i];

                    // get some values before the element is wrapped
                    getDimentions(target, false, true);

                    // hide the default scrollbar
                    hideScrollbars(target, addVScroll, addHScroll);

                    // Calculate the size of the scrollbars
                    reduceScrollbarsWidthHeight(target);
                    setSlidersHeight(target);

                    // Set variables needed to calculate scroll speed, etc.
                    setScrollRatios(target);

                    // Set events
                    setEvents(target);

                    // prepare for next element
                    resetVars();
                }
            }
        }
        this.scrollToBottom = function() {
            for(var i=0; elements[i] !== undefined; i++) {
                if (needScrollbars(elements[i]) && !$(elements[i]).hasClass('nolionbars')) {
                    target = elements[i];
                    getDimentions(target, false, true);
                    var b = $(target).find('.lb-wrap');
                    var c = b.parent().attr('vratio');
                    var m = (scrollHeight - clientHeight) * Math.abs(c);
                    b.scrollTop(m);
                }
            }
        }

        // Set document events
        $(document).mousemove(function(e) {
            if (HDragging || VDragging) {
                activeWrap.parent().find('.lb-v-scrollbar, .lb-h-scrollbar').show();
                if (VDragging) {
                    mouseY = e.pageY;
                    activeWrap.scrollTop((initPos + mouseY - eventY) * Math.abs(currentRatio));
                }
                if (HDragging) {
                    mouseX = e.pageX;
                    activeWrap.scrollLeft((initPos + mouseX - eventX) * Math.abs(currentRatio));
                }
            }
        });
        $(document).mouseup(function(e) {
            if (HDragging || VDragging) {
                if (autohide) {
                    activeWrap.parent().find('.lb-v-scrollbar, .lb-h-scrollbar').fadeOut(150);
                }
                if (VDragging) {
                    VDragging = false;
                }
                if (HDragging) {
                    HDragging = false;
                }
            }
        });

        // Core functions
        function setEvents(elem) {
            var el = $(elem);
            if (addVScroll || addHScroll) {
                el.find('.lb-wrap').on('scroll', function(e) {
                    el.find('.lb-v-scrollbar-slider').css({"top" : -$(this).scrollTop()/el.attr('vratio') });
                    el.find('.lb-h-scrollbar-slider').css({"left" : -$(this).scrollLeft()/el.attr('hratio') });

                    if (el.find('.lb-v-scrollbar').height() == (parseInt(el.find('.lb-v-scrollbar-slider').css('top')) + el.find('.lb-v-scrollbar-slider').height())
                        && typeof(options.reachedBottom) == 'function'
                        && !vEventFired
                    ) {
                        vEventFired = true;
                        var self = $(this);

                        options.reachedBottom.apply($(this).children('.lb-content'), [function () {
                            getDimentions($(self).parent(), {
                                height: $(self).children('.lb-content').get(0).scrollHeight,
                                width: $(self).children('.lb-content').get(0).scrollWidth
                            });

                            // Calculate the size of the scrollbars
                            reduceScrollbarsWidthHeight($(self).parent());
                            setSlidersHeight($(self).parent());

                            // Set variables needed to calculate scroll speed, etc.
                            setScrollRatios($(self).parent());

                            // prepare for next element
                            resetVars();

                            vEventFired = false;
                        }]);
                    }

                    if (el.find('.lb-h-scrollbar').width() == (parseInt(el.find('.lb-h-scrollbar-slider').css('left')) + el.find('.lb-h-scrollbar-slider').width())
                        && typeof(options.reachedRight) == 'function'
                        && !hEventFired
                    ) {
                        hEventFired = true;
                        var self = $(this);

                        options.reachedRight.apply($(this).children('.lb-content'), [function () {
                            getDimentions($(self).parent(), {
                                height: $(self).children('.lb-content').get(0).scrollHeight,
                                width: $(self).children('.lb-content').get(0).scrollWidth
                            });

                            // Calculate the size of the scrollbars
                            reduceScrollbarsWidthHeight($(self).parent());
                            setSlidersHeight($(self).parent());

                            // Set variables needed to calculate scroll speed, etc.
                            setScrollRatios($(self).parent());

                            // prepare for next element
                            resetVars();

                            hEventFired = false;
                        }]);
                    }
                });
            }

            if (addVScroll) {
                el.find('.lb-v-scrollbar-slider').mousedown(function(e) {
                    eventY = e.pageY;
                    VDragging = true;
                    activeScroll = $(this);
                    activeWrap = el.find('.lb-wrap');
                    currentRatio = activeWrap.parent().attr('vratio');
                    initPos = activeScroll.position().top;
                    return false;
                });
                el.find('.lb-v-scrollbar').mousedown(function(e) {
                    if (!$(e.target).hasClass('lb-v-scrollbar-slider')) {
                        el.find('.lb-wrap').scrollTop((e.pageY - $(this).offset().top) * Math.abs(el.attr('vratio')) - $(this).find('.lb-v-scrollbar-slider').height()/2);
                    }
                    return false;
                });
            }

            if (addHScroll) {
                el.find('.lb-h-scrollbar-slider').mousedown(function(e) {
                    eventX = e.pageX;
                    HDragging = true;
                    activeScroll = $(this);
                    activeWrap = el.find('.lb-wrap');
                    currentRatio = activeWrap.parent().attr('hratio');
                    initPos = activeScroll.position().left;
                    return false;
                })
                el.find('.lb-h-scrollbar').mousedown(function(e) {
                    if (!$(e.target).hasClass('lb-h-scrollbar-slider')) {
                        el.find('.lb-wrap').scrollLeft((e.pageX - $(this).offset().left) * Math.abs(el.attr('hratio')) - $(this).find('.lb-h-scrollbar-slider').width()/2);
                    }
                    return false;
                });
            }

            if ((addVScroll || addHScroll) && autohide) {
                el.find('.lb-v-scrollbar, .lb-h-scrollbar').hide();

                el.hover(function() {
                    $(this).data('hover', true);
                    el.find('.lb-v-scrollbar, .lb-h-scrollbar').fadeIn(150);
                }, function() {
                    $(this).data('hover', false);
                    el.find('.lb-v-scrollbar, .lb-h-scrollbar').fadeOut(150);
                });
            }
        }
        function setScrollRatios(elem) {
            vRatio = (offsetHeight - $(elem).find('.lb-wrap').get(0).scrollHeight - borderTop - borderBottom)/(vLbHeight - vSliderHeight);
            hRatio = (offsetWidth - $(elem).find('.lb-wrap').get(0).scrollWidth - borderLeft - borderRight)/(hLbHeight - hSliderHeight);

            var el = $(elem);
            el.attr('vratio', vRatio);
            el.attr('hratio', hRatio);
        }
        function setSlidersHeight(elem) {
            var el = $(elem);
            var hmin, hmax, gap;

            if (el.find('.lb-v-scrollbar').length != 0) {
                hmin = 20;
                gap = offsetHeight - el.find('.lb-v-scrollbar').height();
                hmax = offsetHeight - gap - hmin;
                vSliderHeight = Math.round((offsetHeight*hmax)/scrollHeight);
                vSliderHeight = (vSliderHeight < hmin) ? hmin : vSliderHeight;
            }

            if (el.find('.lb-h-scrollbar').length != 0) {
                hmin = 20;
                gap = offsetWidth - el.find('.lb-h-scrollbar').width();
                hmax = offsetWidth - gap - hmin;
                hSliderHeight = Math.round((offsetWidth*hmax)/scrollWidth);
                hSliderHeight = (hSliderHeight < hmin) ? hmin : hSliderHeight;
            }
            el.find('.lb-v-scrollbar-slider').css({ "height" : vSliderHeight });
            el.find('.lb-h-scrollbar-slider').css({ "width" : hSliderHeight });
        }
        function resetVars() {
            vScrollWidth = 0;
            hScrollWidth = 0;
            addHScroll=false;
            addVScroll=false;
            paddingTop = 0;
            paddingLeft = 0;
            paddingBottom = 0;
            paddingRight = 0;
            borderTop = 0;
            borderLeft = 0;
            borderBottom = 0;
            borderRight = 0;
            scrollHeight = 0;
            scrollWidth = 0;
            offsetWidth = 0;
            offsetHeight = 0;
            clientWidth = 0;
            clientHeight = 0;
            // vRatio = 0;
            // hRatio = 0;
            vSliderHeight = 0;
            hSliderHeight = 0;
            vLbHeight = 0;
            hLbHeight = 0;
        }
        function reduceScrollbarsWidthHeight(elem) {
            var el = $(elem);

            if (addVScroll && addHScroll) {
                vLbHeight = el.height()-12;
                hLbHeight = el.width()-12;
                el.find('.lb-v-scrollbar').css({ "height" : vLbHeight });
                el.find('.lb-h-scrollbar').css({ "width" : hLbHeight });
            } else {
                vLbHeight = el.height()-4;
                hLbHeight = el.width()-4;
                el.find('.lb-v-scrollbar').css({ "height" : vLbHeight });
                el.find('.lb-h-scrollbar').css({ "width" : hLbHeight });
            }
        }
        function hideScrollbars(elem, vscroll, hscroll) {
            var el = $(elem);

            if (vscroll || hscroll) {
                el.css({ "overflow" : 'hidden' });
                movePadding(el, el.find('.lb-wrap'));
                resizeMainBox(el);
                resizeInnerWrap(el, el.find('.lb-wrap'));
            }
        }
        function resizeMainBox(elem) {
            var el = $(elem);
            el.css({ "width" : el.width() + paddingLeft + paddingRight, "height" : el.height() + paddingTop + paddingBottom });
        }
        function movePadding(from, to) {
            var fromEl = $(from);
            var toEl = $(to);

            fromEl.css({ "padding" : 0 });
            toEl.css({
                "padding-top" : paddingTop+'px',
                "padding-left" : paddingLeft+'px',
                "padding-bottom" : paddingBottom+'px',
                "padding-right" : paddingRight+'px'
            });
        }
        function resizeInnerWrap(main, child) {
            var mainEl = $(main);
            var childEl = $(child);
            mainEl.css({ "position" : 'relative' });
            childEl.css({
                "width" : mainEl.width()+vScrollWidth - paddingLeft - paddingRight,
                "height" : mainEl.height()+hScrollWidth - paddingTop - paddingBottom
            });
        }
        function setVScrollbarWidth(elem) {
            var el = $(elem);
            el.css({ "overflow" : 'auto' });
            vScrollWidth = offsetWidth - clientWidth - borderLeft - borderRight;
            el.css({ "overflow" : 'hidden' });
        }
        function setHScrollbarWidth(elem) {
            var el = $(elem);
            el.css({ "overflow" : 'auto' });
            hScrollWidth = offsetHeight - clientHeight - borderTop - borderBottom;
            el.css({ "overflow" : 'hidden' });
        }
        function wrap(elem, vscroll, hscroll) {
            var el = $(elem);
            var elemId = el.attr('id');
            var wrap = 0;

            if (elemId !== undefined) {
                el.wrapInner('<div class="lb-wrap" id="lb-wrap-'+id+'-'+elemId+'"></div>');
                wrap = $('#lb-wrap-'+id+'-'+elemId);
            } else {
                el.wrapInner('<div class="lb-wrap" id="lb-wrap-'+id+'"></div>');
                wrap = $('#lb-wrap-'+id);
            }
            wrap.wrapInner('<div class="lb-content"></div>');
            if (vscroll) {
                el.prepend('<div class="lb-v-scrollbar"></div>');
                el.find('.lb-v-scrollbar').append('<div class="lb-v-scrollbar-slider"></div>');
            }
            if (hscroll) {
                el.prepend('<div class="lb-h-scrollbar"></div>');
                el.find('.lb-h-scrollbar').append('<div class="lb-h-scrollbar-slider"></div>');
            }

            // preparation for the next element
            id = id + 1;
        }
        function needScrollbars(elem) {
            var el = $(elem);
            addVScroll = false;
            addHScroll = false;

            if (el.find('.lb-v-scrollbar-slider').length) return false;

            getPadding(el);
            getBorders(el);

            var overflowY = el.css('overflow-y');
            var overflowX = el.css('overflow-x');

            el.css({ "overflow" : 'hidden' });

            // check for vertical scrollbars
            if (overflowY != 'hidden' && el.get(0).scrollHeight > el.get(0).clientHeight) {
                addVScroll = true;
                // setVScrollbarWidth(el);
            }

            // check for horizontal scrollbars
            if (overflowX != 'hidden' && el.get(0).scrollWidth > el.get(0).clientWidth) {
                addHScroll = true;
                // setHScrollbarWidth(el);
            }

            el.css({ "overflow" : 'auto' });

            if (addVScroll || addHScroll) {
                return true;
            }
        }
        function getPadding(elem) {
            var el = $(elem);

            paddingTop = parseInt(el.css('padding-top').replace('px', ''));
            paddingLeft = parseInt(el.css('padding-left').replace('px', ''));
            paddingBottom = parseInt(el.css('padding-bottom').replace('px', ''));
            paddingRight = parseInt(el.css('padding-right').replace('px', ''));
        }
        function getBorders(elem) {
            var el = $(elem);

            borderTop = parseInt(el.css('border-top-width').replace('px', ''));
            borderRight = parseInt(el.css('border-right-width').replace('px', ''));
            borderBottom = parseInt(el.css('border-bottom-width').replace('px', ''));
            borderLeft = parseInt(el.css('border-left-width').replace('px', ''));
        }
        function getDimentions(elem, scroll, update) {
            var el = $(elem).get(0);
            if (update) {
             el = $(el).find('.lb-wrap').get(0);
            }
            scrollHeight = (typeof(scroll) != 'undefined' && scroll != false) ? scroll.height : el.scrollHeight;
            scrollWidth = (typeof(scroll) != 'undefined' && scroll != false) ? scroll.width : el.scrollWidth;
            clientHeight = el.clientHeight;
            clientWidth = el.clientWidth;
            offsetHeight = el.offsetHeight;
            offsetWidth = el.offsetWidth;
            setVScrollbarWidth($(elem));
            setHScrollbarWidth($(elem));
        }

        return this.each(function() {
            //var $this = $(this);
        });
    };

    /*atelier swiper*/
    var swiper_atelier = new Swiper('.slider_atelier', {
        slidesPerView: 1.22,
        spaceBetween: 5,
        loop: true,
        nextButton: '.atelier_wrapper .swiper-button-next',
        prevButton: '.atelier_wrapper .swiper-button-prev',
        breakpoints: {
             820: {
                slidesPerView: 1
             }
        }
    });

})(jQuery);